import * as React from 'react'

import {Box, Container, SxProps} from '@mui/material'

type FlexPosition =
  | 'flex-start'
  | 'center'
  | 'flex-end'
  | 'space-between'
  | 'space-around'

interface Props {
  children: React.ReactNode
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  fullHeight?: boolean
  justifyContent?: FlexPosition
  sx?: SxProps
}

export const ScreenLayout = ({
  children,
  maxWidth = 'sm',
  fullHeight = false,
  justifyContent = 'flex-start',
  sx,
}: Props): JSX.Element => {
  return (
    <Box
      height="100%"
      width="100%"
      sx={{overflow: 'scroll', scrollbarWidth: 'none', ...sx}}
    >
      <Container maxWidth={maxWidth}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent={justifyContent}
          textAlign="center"
          minHeight={fullHeight ? 'calc(100vh - 64px)' : undefined}
          py={{xs: 2, sm: 3}}
        >
          {children}
        </Box>
      </Container>
    </Box>
  )
}
