import {createSlice} from '@reduxjs/toolkit'

import {Industry} from 'src/types'

interface IndustryState {
  items: Industry[]
}

const initialState: IndustryState = {
  items: [],
}

export const industriesSlice = createSlice({
  name: 'industries',
  initialState,
  reducers: {
    updateIndustries: (state, action) => {
      const payload = action.payload
      state.items = payload
    },
  },
})

export const {updateIndustries} = industriesSlice.actions
export default industriesSlice.reducer
