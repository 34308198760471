import {entries} from 'lodash'

import {JsonApiResponse, JsonApiIndexResponse} from 'src/types'
import {
  Identified,
  JsonApiIncluded,
  JsonApiRelationship,
  JsonApiResponseData,
} from 'src/types/jsonApi'
import {responseToMeta} from 'src/util/request/responseToMeta'

export type ParsedResponse<A, I = Record<never, unknown>> = {
  entity: Identified<A> | null
  included: I | Record<string, never>
}

export type ParsedIndexResponse<A, I = Record<never, unknown>, M = unknown> = {
  results: Array<{
    entity: Identified<A>
    included: I
  }>
  entities: Array<Identified<A>>
  meta: M | null
}

function dataToEntity<A, I extends JsonApiIncluded = Record<string, never>>(
  data: JsonApiResponseData<A, I> | null | void,
  response: JsonApiResponse<A, I> | JsonApiIndexResponse<A, I> | null | void,
): ParsedResponse<A, I> {
  if (!data) return {entity: null, included: {}}

  const flattenedObject = flattenJsonApiObject(data)
  const includedEntities = response
    ? extractIncludedEntities(data, response)
    : {}
  return {entity: flattenedObject, included: includedEntities}
}

export function parseResponse<
  A,
  I extends JsonApiIncluded = Record<string, never>,
>(response: JsonApiResponse<A, I> | null | void): ParsedResponse<A, I> {
  return dataToEntity(response?.data ?? null, response)
}

export function parseIndexResponse<
  A,
  I extends JsonApiIncluded = Record<string, never>,
  M = unknown,
>(
  response: JsonApiIndexResponse<A, I, M> | null,
): ParsedIndexResponse<A, I, M> {
  const results =
    response?.data?.map(
      (data) =>
        dataToEntity(data, response) as {
          entity: Identified<A>
          included: I
        },
    ) ?? []
  const meta = responseToMeta<M, I, A>(response)
  return {
    results,
    entities: results.map((x) => x.entity),
    meta,
  }
}

function flattenJsonApiObject<A, I extends JsonApiIncluded>(
  jsonApiObject: JsonApiResponseData<A, I>,
) {
  return {...jsonApiObject.attributes, id: jsonApiObject.id}
}

function relationshipKey(obj: JsonApiRelationship) {
  return `${obj.type}::${obj.id}`
}

function extractIncludedEntities<
  A,
  I extends JsonApiIncluded = Record<string, unknown | Array<unknown>>,
>(
  data: JsonApiResponseData<A, I> | null,
  response: JsonApiResponse<A, I> | JsonApiIndexResponse<A, I> | null,
): I {
  if (!data) return {} as I

  const {relationships} = data

  if (!relationships) return {} as I

  const includedEntitiesMap =
    response?.included?.reduce(
      (result: Record<string, Identified<I[keyof I]>>, obj) => {
        const key = relationshipKey(obj)
        result[key] = flattenJsonApiObject(obj)
        return result
      },
      {},
    ) || {}
  const includedEntities = {} as Partial<I>
  entries(relationships).forEach(([k, rel]) => {
    const key = k as keyof typeof relationships
    const relationship = (
      rel as typeof relationships[keyof typeof relationships]
    )?.data
    if (Array.isArray(relationship)) {
      includedEntities[key] = relationship.map(
        (r) => includedEntitiesMap[relationshipKey(r)],
      ) as I[keyof I]
    } else if (relationship) {
      includedEntities[key] = includedEntitiesMap[relationshipKey(relationship)]
    }
  })
  return includedEntities as I
}
