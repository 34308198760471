import * as React from 'react'

import {Typography} from '@mui/material'

import {ScreenLayout} from 'src/framework/ScreenLayout'

export const NotFoundScreen = (): JSX.Element | null => {
  return (
    <ScreenLayout fullHeight justifyContent="center">
      <Typography variant="h1">404</Typography>
      <Typography variant="subtitle2" sx={{height: '50px'}}>
        {"Sorry! We couldn't find the page you're looking for"}
      </Typography>
    </ScreenLayout>
  )
}
