import * as React from 'react'

import {Paper, Typography} from '@mui/material'
import {Box, Stack} from '@mui/system'

import {RutterButton} from 'src/components/partners/RutterButton'
import {ScreenLayout} from 'src/framework/ScreenLayout'
import {PortalAppThemeProvider} from 'src/framework/theming/PortalAppThemeProvider'
import {apiPartnersUserCompanyPath} from 'src/generated/routes'
import {useCurrentUser} from 'src/hooks/request/useCurrentUser'
import {useShow} from 'src/hooks/request/useShow'
import {PartnerCompany} from 'src/types'
import {ReactComponent as RutterLogo} from 'svg/rutter_logo.svg'

export const PortalPointOfSaleScreen = (): JSX.Element | null => {
  const {entity: company, fetch: fetchCompany} = useShow<PartnerCompany>()

  const {user} = useCurrentUser()

  const refetchCompany = React.useCallback(async () => {
    if (user) {
      return fetchCompany({url: apiPartnersUserCompanyPath(user.id)})
    }
  }, [fetchCompany, user])

  React.useEffect(() => {
    refetchCompany()
  }, [refetchCompany])

  const onRutterSuccess = React.useCallback(() => {
    refetchCompany()
  }, [refetchCompany])

  return (
    <PortalAppThemeProvider>
      <ScreenLayout fullHeight justifyContent="center">
        <Stack spacing={4} width="100%" pb={6}>
          {!!company && company.rutterPosConnected ? (
            <PostConnectionHeader />
          ) : (
            <PreConnectionHeader />
          )}
          <Paper sx={{p: 4}}>
            <Stack spacing={4} alignItems="center">
              <RutterLogo />
              <Stack spacing={2} direction={{xs: 'column', sm: 'row'}}>
                {['SQUARE', 'STRIPE'].map((platform) => (
                  <RutterButton
                    key={platform}
                    defaultButtonText={`Connect ${platform}`}
                    onSuccess={onRutterSuccess}
                    platform={platform}
                    displayIcon={true}
                    buttonVariant="outlined"
                    buttonProps={{
                      disabled: !!company && company.rutterPosConnected,
                      variant: 'contained',
                      sx: {
                        width: {xs: '100%', sm: 'fit-content'},
                        minWidth: '240px',
                      },
                    }}
                    sx={{width: {xs: '100%', sm: 'fit-content'}}}
                    pageLocation="portal"
                  />
                ))}
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      </ScreenLayout>
    </PortalAppThemeProvider>
  )
}

const PreConnectionHeader = () => (
  <Box>
    <Typography variant="h2" mb={2}>
      {`We use Rutter to get instant, read-only access to point of sale information.`}
    </Typography>
    <Typography variant="body1">
      {'Requires point of sale software credentials.'}
    </Typography>
  </Box>
)

const PostConnectionHeader = () => (
  <Box>
    <Typography variant="h2" mb={2}>
      {"You're connected!"}
    </Typography>
  </Box>
)
