export const formatDatetime = (value: string | null | undefined): string => {
  if (!value) return '-'

  const secondsSinceEpoch = Date.parse(value)
  return new Intl.DateTimeFormat('en-US', {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    hour12: true,
    minute: 'numeric',
  }).format(new Date(secondsSinceEpoch))
}
