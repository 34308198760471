import * as React from 'react'

import {getCookie, unsetCookie} from 'src/util/cookie'

export type UseCookieResult = [value: string | null, unset: () => void]

export const useCookie = (name: string): UseCookieResult => {
  const [cookieValue, setCookieValue] = React.useState(getCookie(name))

  const unset = React.useCallback(() => {
    unsetCookie(name)
    setCookieValue(getCookie(name))
  }, [name])

  return [cookieValue, unset]
}
