import {createSlice} from '@reduxjs/toolkit'

import {User} from 'src/types'

interface UserState {
  items: User[]
}

const initialState: UserState = {
  items: [],
}

export const employeeUsersSlice = createSlice({
  name: 'employeeUsers',
  initialState,
  reducers: {
    updateEmployeeUsers: (state, action) => {
      const payload = action.payload
      state.items = payload
    },
  },
})

export const {updateEmployeeUsers} = employeeUsersSlice.actions
export default employeeUsersSlice.reducer
