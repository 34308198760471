import * as React from 'react'

import {useLDClient} from 'launchdarkly-react-client-sdk'

import {User} from 'src/types/user'

export const useLaunchDarklyUser = (user: User | null | undefined): void => {
  const client = useLDClient()

  React.useEffect(() => {
    if (!client) return

    if (user) {
      client.identify({
        kind: 'user',
        key: user.email,
        email: user.email,
      })
    } else {
      client.identify({
        kind: 'user',
        key: 'anonymous-user',
        anonymous: true,
      })
    }
  }, [user, client])
}
