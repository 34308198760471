import * as React from 'react'

import {
  AccountBalance,
  CheckCircle,
  FileUploadOutlined,
  LockOutlined,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Grid,
  Stack,
  SvgIcon,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {useSnackbar} from 'notistack'

import {MonochromeChip} from 'src/components/MonochromeChip'
import {PartnerDocumentUploads} from 'src/components/partners/PartnerDocumentUploads'
import {withcoBlue} from 'src/framework/theming/PortalAppThemeProvider'
import {ProgressBox} from 'src/framework/ui/ProgressBox'
import {
  apiPartnersAttachmentsPath,
  submitFinancialsApiPartnersDealPath,
} from 'src/generated/routes'
import {useIndex} from 'src/hooks/request/useIndex'
import {useRequest} from 'src/hooks/request/useRequest'
import {usePlaid} from 'src/hooks/usePlaid'
import {useRutter} from 'src/hooks/useRutter'
import {AttachmentTypeName, PartnerAttachment} from 'src/types/attachment'
import {ReactComponent as QuickBooksLogo} from 'svg/quickbooks.svg'

type ConnectFinancialsMode = 'integration' | 'upload'

interface Props {
  onSuccess: () => void
  onCancel: () => void
}
export const ConnectFinancials = ({
  onSuccess,
  onCancel,
}: Props): JSX.Element => {
  const [mode, setMode] = React.useState<ConnectFinancialsMode>('integration')

  const handleModeChange = React.useCallback((mode: ConnectFinancialsMode) => {
    setMode(mode)
  }, [])

  switch (mode) {
    case 'integration':
      return (
        <IntegrateFinancials
          onSuccess={onSuccess}
          onCancel={onCancel}
          onChangeMode={handleModeChange}
        />
      )
    case 'upload':
      return (
        <UploadBusinessFinancials
          onSuccess={onSuccess}
          onCancel={() => setMode('integration')}
        />
      )
  }
}

interface IntegrateFinancialsProps {
  onSuccess: () => void
  onCancel: () => void
  onChangeMode: (mode: ConnectFinancialsMode) => void
}
const IntegrateFinancials = ({
  onSuccess,
  onCancel,
  onChangeMode,
}: IntegrateFinancialsProps): JSX.Element => {
  const theme = useTheme()
  const {realEstateValuationsEnabled} = useFlags()

  const {open: openRutter, isRutterReady} = useRutter({
    platform: 'QUICKBOOKS',
    onSuccess: onSuccess,
    pageLocation: 'portal',
    showToast: !realEstateValuationsEnabled,
  })

  const {enqueueSnackbar} = useSnackbar()

  const {open: openPlaid, ready: isPlaidReady} = usePlaid({
    onSuccess,
    showToast: !realEstateValuationsEnabled,
  })

  const {entities: attachments} = useIndex<PartnerAttachment>(
    apiPartnersAttachmentsPath(),
    {
      autoRequest: true,
    },
  )

  const hasBusinessTaxReturns = React.useMemo(() => {
    return attachments.some(
      (attachment) =>
        attachment.attachmentType == AttachmentTypeName.BusinessTaxReturn,
    )
  }, [attachments])

  const {request: submitFinancials} = useRequest(
    'POST',
    submitFinancialsApiPartnersDealPath(),
  )

  const handleSave = React.useCallback(async () => {
    await submitFinancials()
    if (!realEstateValuationsEnabled) {
      enqueueSnackbar('We are calculating your purchasing power', {
        variant: 'success',
      })
    }
    onSuccess()
  }, [
    enqueueSnackbar,
    onSuccess,
    realEstateValuationsEnabled,
    submitFinancials,
  ])

  return (
    <Stack p={4} spacing={3} alignItems="center">
      <Box>
        <MonochromeChip
          label="Get your purchasing power"
          textColor={theme.palette.primary.main}
          size="small"
        />
      </Box>
      <Box textAlign="center" width={{xs: '100%', sm: '66%'}}>
        <Typography variant="h2" mb={0.5}>
          Connect your business financials
        </Typography>
        <Typography color="secondary.dark">
          In order to get fully approved for withco’s lease-to-own program, we
          need to understand the financial health of your business. We will use
          this data to underwrite your business, as well as determine your real
          estate purchasing power. Select one of the three options below.
        </Typography>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <OptionButton
              icon={
                <SvgIcon
                  component={QuickBooksLogo}
                  inheritViewBox
                  fontSize="large"
                />
              }
              title="Quickbooks"
              description="Share your financials by logging into your Quickbooks account."
              loading={!isRutterReady}
              onClick={openRutter}
              status="pending"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <OptionButton
              icon={
                <SvgIcon
                  component={AccountBalance}
                  fontSize="large"
                  color="primary"
                />
              }
              title="Business Bank Account"
              description="Share your financials by logging into your business' bank account via Plaid."
              onClick={openPlaid}
              loading={!isPlaidReady}
              status="pending"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <OptionButton
              icon={
                <SvgIcon
                  component={FileUploadOutlined}
                  fontSize="large"
                  color="primary"
                />
              }
              title="Business Tax Returns"
              description={'Upload 3 years of business tax returns.'}
              chipLabel={hasBusinessTaxReturns ? 'Received' : 'SBA Requirement'}
              onClick={() => onChangeMode('upload')}
              status={hasBusinessTaxReturns ? 'complete' : 'pending'}
            />
          </Grid>
        </Grid>
      </Box>
      {hasBusinessTaxReturns ? (
        <Button variant="contained" fullWidth onClick={handleSave}>
          Submit Financials
        </Button>
      ) : (
        <Button color="info" variant="contained" fullWidth onClick={onCancel}>
          Cancel
        </Button>
      )}
      <PrivacyNote />
    </Stack>
  )
}

interface UploadBusinessFinancialsProps {
  onSuccess: () => void
  onCancel: () => void
}
const UploadBusinessFinancials = ({
  onSuccess,
  onCancel,
}: UploadBusinessFinancialsProps): JSX.Element => {
  const {realEstateValuationsEnabled} = useFlags()

  const {request: submitFinancials} = useRequest(
    'POST',
    submitFinancialsApiPartnersDealPath(),
  )

  const handleSuccessfulFinancialDocSubmission = React.useCallback(async () => {
    await submitFinancials()
    onSuccess()
  }, [onSuccess, submitFinancials])

  const {entities: attachments} = useIndex<PartnerAttachment>(
    apiPartnersAttachmentsPath(),
    {
      autoRequest: true,
    },
  )

  const businessTaxReturns = React.useMemo(() => {
    return (
      attachments?.filter(
        (attachment) =>
          attachment.attachmentType === AttachmentTypeName.BusinessTaxReturn,
      ) || []
    )
  }, [attachments])

  const saveButtonAlwaysEnabled = React.useMemo(
    () => businessTaxReturns.length > 0,
    [businessTaxReturns.length],
  )

  return (
    <Stack p={4} spacing={3}>
      <Stack spacing={1}>
        <Typography variant="h5" color="secondary">
          Get your purchasing power
        </Typography>
        <Typography variant="h2">
          Upload financial documents manually
        </Typography>
      </Stack>
      <Typography>
        As an alternative to connecting to your business’ bank account or
        accounting software, you can upload PDF documents that help us verify
        your financials.
      </Typography>
      <PartnerDocumentUploads
        types={[AttachmentTypeName.BusinessTaxReturn]}
        cancelText="Back"
        onDone={handleSuccessfulFinancialDocSubmission}
        onCancel={onCancel}
        saveButtonAlwaysEnabled={saveButtonAlwaysEnabled}
        showToast={!realEstateValuationsEnabled}
      />
      <PrivacyNote />
    </Stack>
  )
}

interface OptionButtonProps {
  icon: React.ReactNode
  title: string
  description: string
  chipLabel?: string
  loading?: boolean
  onClick: () => void
  status: 'pending' | 'complete'
}

const OptionButton = ({
  icon,
  title,
  description,
  chipLabel,
  loading = false,
  onClick,
  status,
}: OptionButtonProps): JSX.Element => {
  const theme = useTheme()

  const boxStyles: SxProps = React.useMemo(() => {
    const sx: SxProps = {
      borderRadius: 1,
      borderWidth: 1,
      borderStyle: 'solid',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    }

    if (loading) {
      Object.assign(sx, {
        backgroundColor: theme.palette.background.default,
        borderColor: theme.palette.secondary.main,
        opacity: 0.5,
      })
    } else {
      Object.assign(sx, {
        backgroundColor: theme.palette.background.blue,
        borderColor: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: withcoBlue.backgroundHover,
        },
      })
    }

    return sx
  }, [loading, theme])

  const handleClick = React.useCallback(() => {
    if (!loading) {
      onClick()
    }
  }, [loading, onClick])

  return (
    <ProgressBox loading={loading} height="100%" width="100%">
      {chipLabel && status === 'pending' && (
        <MonochromeChip
          label={chipLabel}
          size="small"
          textColor={theme.palette.primary.main}
          sx={{
            position: 'absolute',
            top: theme.spacing(1.5),
            left: theme.spacing(1.5),
          }}
        />
      )}

      {chipLabel && status === 'complete' && (
        <MonochromeChip
          label={chipLabel}
          size="small"
          icon={<CheckCircle />}
          textColor={theme.palette.success.main}
          sx={{
            position: 'absolute',
            top: theme.spacing(1.5),
            left: theme.spacing(1.5),
            '& .MuiChip-icon': {
              color: theme.palette.success.main,
            },
          }}
        />
      )}

      <Box onClick={handleClick} p={5} sx={boxStyles}>
        <Box mb={2}>{icon}</Box>
        <Typography variant="h3" mb={0.5}>
          {title}
        </Typography>
        <Typography whiteSpace="pre-line" color="secondary.dark">
          {description}
        </Typography>
      </Box>
    </ProgressBox>
  )
}

const PrivacyNote = (): JSX.Element => {
  const theme = useTheme()

  return (
    <Stack
      direction="row"
      alignItems="center"
      p={2}
      spacing={2}
      sx={{
        backgroundColor: 'yellow.light',
        borderRadius: 0.5,
      }}
    >
      <LockOutlined sx={{fill: theme.palette.yellow.main}} />
      <Typography color="info" variant="caption">
        We take your data privacy and security seriously. Your financial data
        will only be used to underwrite your business. We do not share your data
        with any third parties.
      </Typography>
    </Stack>
  )
}
