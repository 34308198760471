import {createSlice} from '@reduxjs/toolkit'

import {Company, Deal, DiligenceSearch, PurchasingPowerLetter} from 'src/types'

interface CompaniesState {
  items: {
    [id: string]: {
      company: Company
      deals: Array<Deal>
      purchasingPowerLetter: PurchasingPowerLetter
      diligenceSearches: Array<DiligenceSearch>
    }
  }
}

const initialState: CompaniesState = {
  items: {},
}

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    updateCompany: (state, action) => {
      const {company, purchasingPowerLetter, deals, diligenceSearches} =
        action.payload
      const {id} = company

      state.items[id] = {
        ...state.items[id],
        company,
        purchasingPowerLetter,
        deals,
        diligenceSearches,
      }
    },
  },
})

export const {updateCompany} = companiesSlice.actions
export default companiesSlice.reducer
