import * as React from 'react'
import Carousel from 'react-material-ui-carousel'

import {Stack, Typography} from '@mui/material'
import {Box, useTheme} from '@mui/system'
import BigPeach from 'images/testimonials/big.peach.jpeg'
import HappyMedium from 'images/testimonials/happy.medium.jpeg'

const testimonials = [
  {
    businessName: 'Happy Medium',
    customerName: 'Rett & Tayler Carraway, withco partners',
    image: HappyMedium,
    quote:
      'Within 60 days, our lives were changed forever. From first meeting to close, the withco team actualized our dream of property ownership as masters of their craft.',
  },
  {
    businessName: 'Big Peach',
    customerName: 'Wes Cone, withco partners',
    image: BigPeach,
    quote:
      'withco lets us have our cake and eat it too. We can use our capital for the highest ROI to grow from a few locations to 40+ while maintaining ownership of our business.',
  },
]

export const PortalTestimonials = (): JSX.Element => {
  const theme = useTheme()

  return (
    <Stack direction="column" alignItems="center" spacing={3}>
      <Typography variant="h2">
        We&apos;re proud to say our customers trust us
      </Typography>
      <Carousel
        autoPlay={true}
        interval={6000}
        duration={1000}
        sx={{width: '100%'}}
        navButtonsProps={{
          style: {backgroundColor: theme.palette.primary.main},
        }}
        indicatorIconButtonProps={{
          style: {
            color: theme.palette.background.blue,
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {color: theme.palette.primary.main},
        }}
      >
        {testimonials.map((item, index) => (
          <Stack
            key={index}
            direction={{xs: 'column', sm: 'row'}}
            sx={{
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 1,
              overflow: 'hidden',
            }}
          >
            <Box width="100%" display={{xs: 'block', sm: 'none'}}>
              <img
                src={item.image}
                style={{
                  width: '100%',
                  height: '100%',
                  aspectRatio: '4/2',
                  objectFit: 'cover',
                  objectPosition: 'center 40%',
                }}
              ></img>
            </Box>
            <Box width="50%" display={{xs: 'none', sm: 'block'}}>
              <img
                src={item.image}
                style={{
                  width: '100%',
                  height: '100%',
                  aspectRatio: '5/2',
                  objectFit: 'cover',
                  objectPosition: 'center 40%',
                }}
              ></img>
            </Box>
            <Stack
              width={{xs: '100%', sm: '50%'}}
              p={4}
              spacing={2}
              textAlign="left"
            >
              <Typography variant="h6" color="text.primary">
                &quot;{item.quote}&quot;
              </Typography>
              <Stack>
                <Typography variant="body2">{item.customerName}</Typography>
                <Typography variant="body2">{item.businessName}</Typography>
              </Stack>
            </Stack>
          </Stack>
        ))}
      </Carousel>
    </Stack>
  )
}
