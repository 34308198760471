import * as React from 'react'

import {Button, ButtonProps} from '@mui/material'

import {SBAApplicationWizard} from 'src/components/portal/SBAMicroflow/SBAApplicationWizard'
import {CenterModal} from 'src/framework/ui/CenterModal'
import {useToggle} from 'src/hooks/util/useToggle'
import {track} from 'src/util/hotjar'

interface Props extends Omit<ButtonProps, 'onClick'> {
  label: string
  onWizardClose: () => void
}
export const SBAApplicationButton = ({
  onWizardClose,
  label,
  ...buttonProps
}: Props) => {
  const [isModalOpen, openModal, closeModal] = useToggle(false)

  const handleClick = React.useCallback(() => {
    track('Start SBA Application')
    openModal()
  }, [openModal])

  const handleClose = React.useCallback(() => {
    closeModal()
    onWizardClose()
  }, [closeModal, onWizardClose])

  return (
    <>
      <Button onClick={handleClick} {...buttonProps}>
        {label}
      </Button>
      <CenterModal
        open={isModalOpen}
        onClose={closeModal}
        size="lg"
        withPadding={false}
        disableCloseButton
      >
        <SBAApplicationWizard onClose={handleClose} />
      </CenterModal>
    </>
  )
}
