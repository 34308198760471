import React from 'react'

import {UploadFileOutlined} from '@mui/icons-material'
import {Button, ButtonProps, Input} from '@mui/material'

export interface FileInputProps {
  id: string
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  disabled?: boolean
  multiple?: boolean
  buttonText?: React.ReactNode | null
  fileExtensionRestrictions?: AcceptOptions[]
  size?: 'small' | 'medium'
  buttonProps?: ButtonProps
}

export type FileExtension =
  | '.csv'
  | '.xlsx'
  | '.docx'
  | '.pdf'
  | '.png'
  | '.jpg'

type AcceptedFileTypes = 'video/*' | 'image/*'

export type AcceptOptions = FileExtension | AcceptedFileTypes

export const FileInput = ({
  id,
  onChange,
  disabled = false,
  multiple = false,
  buttonText = 'Select file',
  fileExtensionRestrictions = [],
  size = 'medium',
  buttonProps,
}: FileInputProps): JSX.Element | null => {
  return (
    <label htmlFor={id}>
      <Input
        id={id}
        type="file"
        inputProps={{
          accept: fileExtensionRestrictions.join(','),
          multiple,
        }}
        sx={{display: 'none'}}
        onChange={onChange}
        disabled={disabled}
        value=""
      />
      {!!buttonText && (
        <Button
          startIcon={<UploadFileOutlined />}
          component="span"
          size={size}
          disabled={disabled}
          sx={{whiteSpace: 'nowrap'}}
          {...buttonProps}
        >
          {buttonText}
        </Button>
      )}
    </label>
  )
}
