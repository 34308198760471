import * as React from 'react'

import {Box, Fade, Skeleton} from '@mui/material'

interface Props {
  children: React.ReactNode
  loading: boolean
  width?: number | 'auto'
  lines?: number
}

export const SkeletonLoader = ({
  children,
  loading,
  width = 200,
  lines = 1,
}: Props): JSX.Element | null => {
  return loading ? (
    <>
      {[...Array(lines)].map((_i, index) => (
        <Skeleton key={index} width={width} />
      ))}
    </>
  ) : (
    <Fade in timeout={500}>
      <Box>{children}</Box>
    </Fade>
  )
}
