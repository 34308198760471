import * as React from 'react'

import {PartnerFinancials} from 'src/components/partners/PartnerFinancials'
import {ScreenLayout} from 'src/framework/ScreenLayout'
import {PortalAppThemeProvider} from 'src/framework/theming/PortalAppThemeProvider'

export const PartnerPlaidScreen = (): JSX.Element | null => {
  return (
    <PortalAppThemeProvider>
      <ScreenLayout fullHeight justifyContent="center" maxWidth="md">
        <PartnerFinancials />
      </ScreenLayout>
    </PortalAppThemeProvider>
  )
}
