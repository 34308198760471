import * as React from 'react'

export const useBeforeUnload = (shouldBlock: boolean) => {
  React.useEffect(() => {
    if (shouldBlock) {
      const onUnload: OnBeforeUnloadEventHandler = (e) => {
        e.preventDefault()
        e.returnValue = true
        return 'Changes you made may not be saved.'
      }
      window.addEventListener('beforeunload', onUnload)
      return () => {
        window.removeEventListener('beforeunload', onUnload)
      }
    }
  }, [shouldBlock])
}
