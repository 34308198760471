import * as React from 'react'

import {
  ArrowDownward,
  ArrowUpward,
  LocationCityOutlined,
} from '@mui/icons-material'
import {Box, Paper, Stack, Typography, alpha} from '@mui/material'
import {useFlags} from 'launchdarkly-react-client-sdk'

import {AppreciationChart} from 'src/components/portal/AppreciationChart'
import {GetPreapprovedButton} from 'src/components/portal/GetPreapprovedButton'
import {apiPartnersPriceAppreciationPath} from 'src/generated/routes'
import {useShow} from 'src/hooks/request/useShow'
import {PartnerDeal, PriceAppreciationResponse} from 'src/types'

type Props = {
  deal: PartnerDeal
  refetchDeal: () => void
  onRender: () => void
}

export const PriceAppreciationCard = ({
  deal,
  refetchDeal,
  onRender,
}: Props): JSX.Element | null => {
  const {realEstateValuationsEnabled} = useFlags()

  const {entity: priceAppreciation, loading} =
    useShow<PriceAppreciationResponse>(
      apiPartnersPriceAppreciationPath(deal.id),
      {
        autoRequest: true,
      },
    )

  const shouldRender = React.useMemo(
    () =>
      !loading && priceAppreciation && priceAppreciation.appreciation !== null,
    [loading, priceAppreciation],
  )

  React.useEffect(() => {
    if (shouldRender) {
      onRender()
    }
  }, [shouldRender, onRender])

  return shouldRender && priceAppreciation?.appreciation ? (
    <Paper sx={{p: 3, overflow: 'hidden'}}>
      <Stack direction="column" spacing={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Typography variant="h2">Property values in your area</Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <LocationCityOutlined sx={{fill: alpha('#222222', 0.8)}} />
            <Typography color={alpha('#222222', 0.8)} variant="body2">
              {priceAppreciation.streetAddress}
            </Typography>
          </Stack>
        </Stack>
        {priceAppreciation.quarterlyChanges &&
          priceAppreciation.startQuarter && (
            <AppreciationChart
              changes={priceAppreciation.quarterlyChanges}
              startQuarter={priceAppreciation.startQuarter}
            />
          )}
        <Stack direction="column" spacing={0.75}>
          <Stack direction="row" alignItems="center">
            {priceAppreciation.appreciation > 0 ? (
              <ArrowUpward />
            ) : priceAppreciation.appreciation < 0 ? (
              <ArrowDownward />
            ) : null}
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="h2">
                {priceAppreciation.appreciation !== 0
                  ? `${Math.abs(priceAppreciation.appreciation)}%`
                  : "Haven't changed much"}
              </Typography>
              <Typography variant="h2" lineHeight="1" fontSize="1rem">
                {`in the last 5 years`}
              </Typography>
            </Stack>
          </Stack>
          {priceAppreciation.appreciation > 0 && (
            <Typography>
              Your landlord is enjoying those rising prices. It’s time for you
              to enjoy them instead.
            </Typography>
          )}
          {priceAppreciation.assetTypeFallback && (
            <Typography fontSize="0.75rem" fontStyle="italic" pt={2}>
              Note: This is an estimate based on retail property market trends
              in your area.
            </Typography>
          )}
          {!realEstateValuationsEnabled && (
            <Box display="flex" justifyContent="left">
              <GetPreapprovedButton
                deal={deal}
                onSuccess={refetchDeal}
                buttonProps={{
                  color: 'secondary',
                  sx: {
                    color: 'text.primary',
                    backgroundColor: 'secondary.light',
                    '&:hover': {
                      backgroundColor: 'secondary.main',
                    },
                    marginTop: 3,
                  },
                }}
              />
            </Box>
          )}
        </Stack>
      </Stack>
    </Paper>
  ) : null
}
