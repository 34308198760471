import {formatDecimal} from 'src/util/format/formatDecimal'

type Options = {
  negate?: boolean
  precision?: number
}

export const formatMultiplier = (
  value: string | null | undefined,
  options?: Options,
): string => {
  const decimal = formatDecimal(value, options)
  if (decimal === '-') return '-'
  return decimal + 'x'
}
