import * as React from 'react'

import {Box, BoxProps, CircularProgress} from '@mui/material'

interface Props extends BoxProps {
  loading: boolean
  indicatorSize?: number
  children?: React.ReactNode
  spinnerTop?: string
}

export const ProgressBox = React.forwardRef(function ProgressBox(
  {
    loading,
    indicatorSize = 24,
    children,
    spinnerTop = '50%',
    ...boxProps
  }: Props,
  ref,
): JSX.Element {
  const centerAdjustment = `-${indicatorSize / 2}px`

  return (
    <Box
      position="relative"
      width="100%"
      minHeight={indicatorSize}
      ref={ref}
      {...boxProps}
    >
      {loading && (
        <Box
          component="span"
          position="absolute"
          top={spinnerTop}
          left="50%"
          marginTop={centerAdjustment}
          marginLeft={centerAdjustment}
          zIndex={2}
        >
          <CircularProgress size={indicatorSize} />
        </Box>
      )}
      {children}
    </Box>
  )
})
