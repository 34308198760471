import * as React from 'react'

import {CheckCircleOutlined, CircleOutlined} from '@mui/icons-material'
import {Grid, Paper, Stack, Typography} from '@mui/material'

import {PlaidButton} from 'src/components/deal/PlaidButton'
import {PartnerDocumentUploads} from 'src/components/partners/PartnerDocumentUploads'
import {RutterButton} from 'src/components/partners/RutterButton'
import {ScreenLayout} from 'src/framework/ScreenLayout'
import {
  apiPartnersAttachmentsPath,
  apiPartnersCompanyPath,
  apiPartnersPlaidItemsPath,
  apiPartnersUserCompanyPath,
} from 'src/generated/routes'
import {useCurrentUser} from 'src/hooks/request/useCurrentUser'
import {useIndex} from 'src/hooks/request/useIndex'
import {useShow} from 'src/hooks/request/useShow'
import {PartnerCompany, PlaidItem} from 'src/types'
import {AttachmentTypeName, PartnerAttachment} from 'src/types/attachment'

export const PartnerConnectScreen = (): JSX.Element | null => {
  const {user} = useCurrentUser()

  const {entity: company, fetch: fetchCompany} = useShow<PartnerCompany>(
    apiPartnersCompanyPath(),
  )

  const fetchUserCompany = React.useCallback(async () => {
    if (user) {
      return fetchCompany({url: apiPartnersUserCompanyPath(user.id)})
    }
  }, [fetchCompany, user])

  React.useEffect(() => {
    fetchUserCompany()
  }, [fetchUserCompany])

  if (!company) {
    return null
  }

  return (
    <ScreenLayout fullHeight maxWidth="sm">
      <DataCollectionActions
        company={company}
        refetchCompany={fetchUserCompany}
      />
    </ScreenLayout>
  )
}

interface DataCollectionActionsProps {
  company: PartnerCompany
  refetchCompany: () => void
}
export const DataCollectionActions = ({
  company,
  refetchCompany,
}: DataCollectionActionsProps) => {
  const {
    entities: attachments,
    fetch: refetchAttachments,
    loading: isAttachmentsLoading,
  } = useIndex<PartnerAttachment>(apiPartnersAttachmentsPath(), {
    autoRequest: true,
  })

  const isLoading = React.useMemo(() => {
    return isAttachmentsLoading
  }, [isAttachmentsLoading])

  const onRutterSuccess = React.useCallback(() => {
    refetchCompany()
  }, [refetchCompany])

  const isLeaseUploaded = React.useMemo(
    () =>
      !isLoading &&
      attachments.some(
        (attachment) =>
          attachment.attachmentType === AttachmentTypeName.LeaseAgreement,
      ),
    [attachments, isLoading],
  )
  return (
    <Stack spacing={4} justifyContent="center" direction="column" height="100%">
      <Stack spacing={2}>
        <Typography variant="h2">Connect your accounts</Typography>
        <Paper sx={{py: 3, px: 2}}>
          <FinancialConnectionActions
            company={company}
            onRutterSuccess={onRutterSuccess}
          />
        </Paper>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h2">Upload documents</Typography>
        <Paper sx={{py: 3, px: 2}}>
          <Grid container spacing={2}>
            <GridRow completed={isLeaseUploaded}>
              <PartnerDocumentUploads
                onDone={refetchAttachments}
                types={[AttachmentTypeName.LeaseAgreement]}
                autoSave
              />
            </GridRow>
          </Grid>
        </Paper>
      </Stack>
    </Stack>
  )
}

interface FinancialConnectionActionsProps {
  company: PartnerCompany | null
  onRutterSuccess: () => void
}

export const FinancialConnectionActions = ({
  company,
  onRutterSuccess,
}: FinancialConnectionActionsProps): JSX.Element => {
  const [plaidItems, setPlaidItems] = React.useState<PlaidItem[]>([])

  const {fetch: refetchPlaidItems, loading: isPlaidItemsLoading} =
    useIndex<PlaidItem>(apiPartnersPlaidItemsPath(), {
      autoRequest: true,
      onSuccess: ({entities: plaidItems}) => setPlaidItems(plaidItems),
    })

  const isLoading = React.useMemo(() => {
    return isPlaidItemsLoading
  }, [isPlaidItemsLoading])

  const isAccountingConnected = React.useMemo(() => {
    return (!isLoading && company?.rutterConnected) || false
  }, [company?.rutterConnected, isLoading])

  const isPosConnected = React.useMemo(() => {
    return (!isLoading && company?.rutterPosConnected) || false
  }, [company?.rutterPosConnected, isLoading])

  const isBankConnected = React.useMemo(() => {
    return !isLoading && plaidItems.length > 0
  }, [plaidItems, isLoading])

  return (
    <Grid container spacing={2}>
      <GridRow completed={isAccountingConnected}>
        <Stack direction="row" spacing={2}>
          {['QUICKBOOKS', 'XERO'].map((platform) => (
            <RutterButton
              key={`${platform}-accounting`}
              platform={platform}
              onSuccess={onRutterSuccess}
              pageLocation="portal"
              buttonProps={{
                variant: isAccountingConnected ? 'outlined' : 'contained',
                fullWidth: true,
              }}
              sx={{width: '100%'}}
            />
          ))}
        </Stack>
      </GridRow>
      <GridRow completed={isBankConnected}>
        <PlaidButton
          onSuccessCallback={refetchPlaidItems}
          buttonText="Bank account"
          buttonProps={{
            variant: isBankConnected ? 'outlined' : 'contained',
            fullWidth: true,
          }}
        />
      </GridRow>
      <GridRow completed={isPosConnected}>
        <Stack direction="row" spacing={2}>
          {['SQUARE', 'STRIPE'].map((platform) => (
            <RutterButton
              key={platform}
              sx={{width: '100%'}}
              defaultButtonText={`${platform}`}
              onSuccess={onRutterSuccess}
              platform={platform}
              displayIcon={true}
              buttonProps={{
                variant: isPosConnected ? 'outlined' : 'contained',
                fullWidth: true,
              }}
              pageLocation="portal"
            />
          ))}
        </Stack>
      </GridRow>
    </Grid>
  )
}

interface TickProps {
  done: boolean
}

const Tick = ({done}: TickProps) => {
  return done ? (
    <CheckCircleOutlined color="success" fontSize="large" />
  ) : (
    <CircleOutlined fontSize="large" color="secondary" />
  )
}

interface GridRowProps {
  children: React.ReactNode
  completed: boolean
}

const GridRow = ({children, completed}: GridRowProps) => {
  return (
    <>
      <Grid item xs={2} display={{xs: 'none', sm: 'inherit'}} />
      <Grid item xs={10} sm={8}>
        {children}
      </Grid>
      <Grid item xs={2}>
        <Tick done={completed} />
      </Grid>
    </>
  )
}
