import * as React from 'react'

import {apiPartnersUserDealPath} from 'src/generated/routes'
import {useCurrentUser} from 'src/hooks/request/useCurrentUser'
import {useShow} from 'src/hooks/request/useShow'
import {PartnerDeal} from 'src/types'
import * as Analytics from 'src/util/analytics'

export const useTrackPage = (
  properties: {[key: string]: unknown} = {},
): void => {
  const {user} = useCurrentUser()

  const {entity: deal, fetch: fetchDeal} = useShow<PartnerDeal>()

  React.useEffect(() => {
    if (user && !user.internal) {
      fetchDeal({url: apiPartnersUserDealPath(user.id)})
    }
  }, [fetchDeal, user])

  const [tracked, setTracked] = React.useState(false)

  React.useEffect(() => {
    if (user && !user.internal && !tracked && deal) {
      Analytics.page('View Portal Page', {
        ...properties,
        portalState: deal.portalState,
      })
      setTracked(true)
    }
  }, [deal, properties, tracked, user])
}
