import * as React from 'react'
import {Link as RouterLink} from 'react-router-dom'

import {CloudDownload} from '@mui/icons-material'
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Paper,
  Typography,
} from '@mui/material'
import {Box, Container, Stack} from '@mui/system'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {groupBy, sortBy} from 'lodash'

import {SMBLogin} from 'src/framework/SMBLogin'
import {ScreenLayout} from 'src/framework/ScreenLayout'
import {
  apiPartnersAttachmentDownloadPath,
  apiPartnersAttachmentsPath,
} from 'src/generated/routes'
import {useTrackPage} from 'src/hooks/analytics/useTrackPage'
import {useCurrentUser} from 'src/hooks/request/useCurrentUser'
import {useIndex} from 'src/hooks/request/useIndex'
import {Attachment} from 'src/types'
import {AttachmentTypeName} from 'src/types/attachment'
import * as Analytics from 'src/util/analytics'
import {formatDate} from 'src/util/format/formatDate'

export const PartnerDocumentsScreen = (): JSX.Element | null => {
  const {loginWithOtpEnabled} = useFlags()

  const {user} = useCurrentUser()
  const {entities: attachments, loading} = useIndex<Attachment>(
    apiPartnersAttachmentsPath(),
    {
      autoRequest: true,
    },
  )

  useTrackPage({pageName: 'documents'})

  const attachmentsGroupedByBroadLevel = React.useMemo(() => {
    return groupBy(attachments, (x) => x.broadLevelGrouping)
  }, [attachments])

  const sortedBroadLevelGroupKeys = React.useMemo(() => {
    return [
      'Closing documents',
      'Contracts',
      'Financial documents',
      'Miscellaneous',
    ].filter((broadLevelGroupName) => {
      return attachmentsGroupedByBroadLevel[broadLevelGroupName]
    })
  }, [attachmentsGroupedByBroadLevel])

  const emptyState = React.useMemo(() => {
    return (
      <ScreenLayout fullHeight>
        <Box display="flex" alignItems="center">
          <Paper sx={{py: {xs: 3, sm: 4}, px: {xs: 2, sm: 4}}}>
            <Stack spacing={3} alignItems="center">
              <Typography>{`Looks like you don’t have any documents on file yet. Proceed to "Next steps” to share any information relevant to your application.`}</Typography>
              <Button
                component={RouterLink}
                to="/partners"
                variant="contained"
                color="primary"
                size="small"
                sx={{width: 'fit-content'}}
              >
                Next steps
              </Button>
            </Stack>
          </Paper>
        </Box>
      </ScreenLayout>
    )
  }, [])

  React.useEffect(() => {
    if (!user?.verifiedAccountAt && loginWithOtpEnabled) {
      Analytics.page('View Account Verification', {
        pageName: 'accountVerification',
      })
    }
  }, [loginWithOtpEnabled, user])

  if (!user?.verifiedAccountAt) {
    return loginWithOtpEnabled ? (
      <>
        <Box
          width="100vw"
          height="100vh"
          flexDirection="column"
          marginTop="20vh"
          display={{xs: 'none', sm: 'flex'}}
        >
          <Container maxWidth="sm">
            <Paper>
              <SMBLogin
                verifyUserFlow={true}
                afterLoginPath="/partners/documents"
              />
            </Paper>
          </Container>
        </Box>
        <Box marginTop="10vh" display={{xs: 'block', sm: 'none'}}>
          <SMBLogin
            verifyUserFlow={true}
            afterLoginPath="/partners/documents"
          />
        </Box>
      </>
    ) : (
      <ScreenLayout fullHeight>
        <Box display="flex" alignItems="center">
          <Paper sx={{py: {xs: 3, sm: 4}, px: {xs: 2, sm: 4}}}>
            <Stack spacing={3} alignItems="center">
              <Typography>{`Your account needs to be verified before you can view and download your documents`}</Typography>
              <Button
                component={RouterLink}
                to="/partners"
                variant="contained"
                color="primary"
                size="small"
                sx={{width: 'fit-content'}}
              >
                Back
              </Button>
            </Stack>
          </Paper>
        </Box>
      </ScreenLayout>
    )
  }

  return (
    <Box mx={{sm: 5, xs: 2}} my={{sm: 5, xs: 4}} flexGrow={1}>
      <Stack spacing={5}>
        {attachments.length == 0 && !loading
          ? emptyState
          : sortedBroadLevelGroupKeys.map((broadLevelGroupName) => (
              <AttachmentGroupSection
                key={broadLevelGroupName}
                attachments={
                  attachmentsGroupedByBroadLevel[broadLevelGroupName]
                }
                attachmentGroupName={broadLevelGroupName}
              />
            ))}
      </Stack>
    </Box>
  )
}

interface AttachmentGroupSectionProps {
  attachments: Attachment[]
  attachmentGroupName: string
}

const AttachmentGroupSection = ({
  attachments,
  attachmentGroupName,
}: AttachmentGroupSectionProps): JSX.Element => {
  const attachmentsByType = React.useMemo(
    () => groupBy(attachments, (attachment) => attachment.attachmentType),
    [attachments],
  )

  const sortedAttachmentTypeAttachments = React.useMemo(
    () => sortBy(Object.keys(attachmentsByType), (type) => type),
    [attachmentsByType],
  )

  const columns = React.useMemo(() => {
    const attachmentTypesLeft: string[] = []
    const attachmentTypesRight: string[] = []

    const half = attachments.length / 2

    let leftCount = 0
    let rightCount = 0

    sortedAttachmentTypeAttachments.forEach((attachmentType) => {
      const attachmentTypeAttachmentCount =
        attachmentsByType[attachmentType].length

      if (
        leftCount >= half ||
        (rightCount < leftCount &&
          leftCount + attachmentTypeAttachmentCount > half)
      ) {
        attachmentTypesRight.push(attachmentType)
        rightCount += attachmentTypeAttachmentCount
      } else {
        attachmentTypesLeft.push(attachmentType)
        leftCount += attachmentTypeAttachmentCount
      }
    })

    return [attachmentTypesLeft, attachmentTypesRight]
  }, [attachments, attachmentsByType, sortedAttachmentTypeAttachments])

  return (
    <Box>
      <Typography variant="h3" textAlign="left" mb={2}>
        {attachmentGroupName}
      </Typography>
      <Grid container spacing={2} ml={-2}>
        {columns.map((column, index) => {
          return (
            <Grid item xs={12} sm={6} key={index}>
              <Stack spacing={2}>
                {column.map((attachmentsType) => {
                  const attachmentTypeAttachments =
                    attachmentsByType[attachmentsType]

                  const attachmentDisplayType =
                    attachmentTypeAttachments[0].displayType
                  const attachmentTypeName =
                    attachmentTypeAttachments[0].attachmentType

                  return (
                    <AttachmentTypeSection
                      attachmentTypeDisplayName={attachmentDisplayType}
                      attachmentTypeName={attachmentTypeName}
                      attachments={attachmentTypeAttachments}
                      key={attachmentTypeName}
                    />
                  )
                })}
              </Stack>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

interface AttachmentTypeSectionProps {
  attachmentTypeDisplayName: string
  attachmentTypeName: AttachmentTypeName
  attachments: Attachment[]
}

const AttachmentTypeSection = ({
  attachmentTypeDisplayName,
  attachmentTypeName,
  attachments,
}: AttachmentTypeSectionProps): JSX.Element => {
  const downloadAttachment = (attachment: Attachment) => {
    const fileDownloadUrl = apiPartnersAttachmentDownloadPath(attachment.id)
    if (attachment) {
      window.open(fileDownloadUrl)
    }
  }

  return (
    <Stack spacing={2} key={attachmentTypeName}>
      <Paper
        sx={{
          width: '100%',
          px: 2,
          py: 1,
        }}
        data-testid={`attachment_type_${attachmentTypeName}`}
      >
        <Typography
          variant="subtitle1"
          align="left"
          flexGrow={1}
          fontWeight="bold"
        >
          {attachmentTypeDisplayName}
        </Typography>
        <Stack mt={0.5}>
          {attachments.map((attachment) => {
            return (
              <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                key={attachment.id}
              >
                <Link
                  href={apiPartnersAttachmentDownloadPath(attachment.id)}
                  target="_blank"
                  variant="caption"
                  textAlign="left"
                  minWidth={0}
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  fontWeight={500}
                >
                  {attachment?.name}
                </Link>
                <Divider
                  sx={{flexGrow: 1, borderColor: 'grey.200'}}
                  variant="middle"
                />
                <Typography
                  variant="caption"
                  color="grey.600"
                  whiteSpace="nowrap"
                >
                  {formatDate(attachment.createdAt)}
                </Typography>
                <IconButton
                  size="small"
                  sx={{color: 'grey.600'}}
                  onClick={() => downloadAttachment(attachment)}
                >
                  <CloudDownload />
                </IconButton>
              </Stack>
            )
          })}
        </Stack>
      </Paper>
    </Stack>
  )
}
