import * as React from 'react'

import {Box, Fade, Stack} from '@mui/material'

import {PortalNavBar} from 'src/components/nav/PortalNavBar'
import {useCurrentUser} from 'src/hooks/request/useCurrentUser'
import {PartnerUnauthorizedScreen} from 'src/screens/PartnerUnauthorizedScreen'

interface Props {
  children: React.ReactNode
}

export const PortalNavFrame = ({children}: Props): JSX.Element => {
  const {user, hasBeenFetched} = useCurrentUser()

  return (
    <>
      <Stack width="100%" minHeight="100vh">
        <PortalNavBar />
        {!hasBeenFetched ? null : user ? (
          <>
            <Fade in>
              <Box
                width="100%"
                height="100%"
                display="flex"
                position="relative"
                flexDirection={{xs: 'column', sm: 'row'}}
              >
                {children}
              </Box>
            </Fade>
          </>
        ) : (
          <PartnerUnauthorizedScreen />
        )}
      </Stack>
    </>
  )
}
