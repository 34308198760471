import React from 'react'

import {Collapse, Grid} from '@mui/material'

type Props = {
  children: React.ReactNode
  xs?: number | undefined
  sm?: number | undefined
  show: boolean
}

export const CollapsingGridItem = ({
  children,
  xs,
  sm,
  show,
}: Props): JSX.Element => {
  return (
    <Grid item xs={xs} sm={sm} display={show ? 'block' : 'none'}>
      <Collapse in={show} timeout={500}>
        {children}
      </Collapse>
    </Grid>
  )
}
