import * as React from 'react'

import {
  Menu,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import {useFlags} from 'launchdarkly-react-client-sdk'

import {MaterialSymbolIcon} from 'src/framework/ui/MaterialSymbolIcon'
import {apiPartnersDemoPortalStatePath} from 'src/generated/routes'
import {useRequest} from 'src/hooks/request/useRequest'

export const DemoPortalState = (): JSX.Element | null => {
  const {changePortalStateForTestingEnabled} = useFlags()

  const {request: changePortalState} = useRequest<void, void>(
    'PUT',
    apiPartnersDemoPortalStatePath({portalState: 'next'}),
  )

  const {request: resetPortalState} = useRequest<void, void>(
    'PUT',
    apiPartnersDemoPortalStatePath({portalState: 'reset'}),
  )

  const environment = document.querySelector<HTMLMetaElement>(
    'meta[name="environment"]',
  )?.content

  const isDemoEnvironment =
    environment === 'development' || environment === 'staging'

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleNext = React.useCallback(async () => {
    await changePortalState()
    window.location.href = '/portal'
  }, [changePortalState])

  const handleReset = React.useCallback(async () => {
    await resetPortalState()
    window.location.href = '/portal'
  }, [resetPortalState])

  return isDemoEnvironment && changePortalStateForTestingEnabled ? (
    <>
      <MaterialSymbolIcon
        weight={300}
        sx={{
          color: 'white',
          transition: 'color 1s ease',
          '&:hover': {color: 'green'},
        }}
        onClick={handleClick}
      >
        Experiment
      </MaterialSymbolIcon>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList disablePadding>
          <MenuItem onClick={handleNext}>
            <ListItemIcon>
              <MaterialSymbolIcon weight={300} sx={{color: '#000000'}}>
                East
              </MaterialSymbolIcon>
            </ListItemIcon>
            <ListItemText>
              <Typography variant="h4">Next</Typography>
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={handleReset}>
            <ListItemIcon>
              <MaterialSymbolIcon weight={300} sx={{color: '#000000'}}>
                Undo
              </MaterialSymbolIcon>
            </ListItemIcon>
            <ListItemText>
              <Typography variant="h4">Reset</Typography>
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  ) : null
}
