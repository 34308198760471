type Options = {
  negate?: boolean
  absolute?: boolean
  precision?: number
}

export const formatPercentage = (
  value: string | null | undefined,
  options?: Options,
): string => {
  if (!value) return '-'

  const {negate = false, absolute = false, precision = 2} = options ?? {}

  const floatValue = absolute
    ? Math.abs(parseFloat(value))
    : parseFloat(value) * (negate ? -1 : 1)
  if (floatValue === 0) return '0%'
  if (isNaN(floatValue)) return '-'

  return floatValue.toLocaleString('en-US', {
    style: 'percent',
    minimumFractionDigits: precision,
  })
}
