import * as React from 'react'
import NumberFormat, {NumberFormatValues} from 'react-number-format'

import {InputBaseComponentProps} from '@mui/material'
import {isArray} from 'lodash'

export type NumberFormatCustomProps = InputBaseComponentProps & {
  thousandSeparator?: boolean
  defaultValue?: string | number | undefined
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  name?: string
}

interface CreateNumberFormatArgs {
  prefix?: '$'
  suffix?: '%' | ' sqft' | ' /sqft'
  decimalScale?: number
  getValue?: (values: NumberFormatValues) => string
  getDefaultValue?: (
    value: string | number | undefined,
  ) => string | number | undefined
  isAllowed?: (values: NumberFormatValues) => boolean
  format?: string
}

export const createNumberFormat = ({
  prefix,
  suffix,
  decimalScale,
  getValue = (values) => values.value,
  getDefaultValue = (x) => x,
  isAllowed,
  format,
}: CreateNumberFormatArgs) =>
  React.forwardRef<
    NumberFormat<InputBaseComponentProps>,
    NumberFormatCustomProps
  >(function NumberFormatComponent(
    props: NumberFormatCustomProps,
    ref,
  ): JSX.Element {
    const {
      defaultValue: defaultValueProp,
      onChange,
      thousandSeparator = true,
      ...other
    } = props

    const defaultValue = getDefaultValue(
      isArray(defaultValueProp) ? defaultValueProp[0] : defaultValueProp,
    )

    return (
      <NumberFormat
        {...other}
        thousandSeparator={thousandSeparator}
        isNumericString
        getInputRef={ref}
        prefix={prefix}
        suffix={suffix}
        decimalScale={decimalScale}
        defaultValue={defaultValue}
        isAllowed={isAllowed}
        onValueChange={(values) => {
          onChange?.({
            target: {
              name: props.name,
              value: getValue(values),
            },
          } as React.ChangeEvent<HTMLInputElement>)
        }}
        format={format}
      />
    )
  })

export const DollarFormat = createNumberFormat({
  decimalScale: 2,
  prefix: '$',
  getDefaultValue: (defaultValue) => {
    if (!defaultValue) {
      return ''
    }
    if (typeof defaultValue === 'number' || typeof defaultValue === 'string') {
      const defaultNumber = Number(defaultValue)
      if (defaultNumber % 1) return defaultNumber.toFixed(2)
      return defaultNumber.toFixed(0)
    }
    return defaultValue
  },
})
export const WholeDollarFormat = createNumberFormat({
  decimalScale: 0,
  prefix: '$',
})
export const DecimalFormat = createNumberFormat({})
export const IntegerFormat = createNumberFormat({
  decimalScale: 0,
})
export const AreaFormat = createNumberFormat({
  decimalScale: 0,
  suffix: ' sqft',
})
export const PricePerSqftFormat = createNumberFormat({
  decimalScale: 0,
  prefix: '$',
  suffix: ' /sqft',
})
export const PercentFormat = createNumberFormat({
  decimalScale: 1,
  suffix: '%',
  getValue: (values) =>
    values.floatValue ? (values.floatValue / 100).toFixed(3) : '0',
  getDefaultValue: (defaultValue) => {
    if (!defaultValue) {
      return ''
    }
    if (typeof defaultValue === 'number' || typeof defaultValue === 'string') {
      return (Number(defaultValue) * 100).toFixed(1)
    }
    return defaultValue
  },
})
export const PrecisePercentFormat = createNumberFormat({
  decimalScale: 2,
  suffix: '%',
  getValue: (values) =>
    values.floatValue ? (values.floatValue / 100).toFixed(4) : '0',
  getDefaultValue: (defaultValue) => {
    if (!defaultValue) {
      return ''
    }
    if (typeof defaultValue === 'number' || typeof defaultValue === 'string') {
      return (Number(defaultValue) * 100).toFixed(2)
    }
    return defaultValue
  },
})

export const CapRateFormat = createNumberFormat({
  decimalScale: 2,
  suffix: '%',
  getValue: (values) =>
    values.floatValue ? (values.floatValue / 100).toFixed(4) : '0',
  getDefaultValue: (defaultValue) => {
    if (!defaultValue) {
      return ''
    }
    if (typeof defaultValue === 'number' || typeof defaultValue === 'string') {
      return (Number(defaultValue) * 100).toFixed(2)
    }
    return defaultValue
  },
})

export const YearFormat = createNumberFormat({
  decimalScale: 0,
  format: '####',
})
