import * as React from 'react'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'

import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
  Typography,
  Box,
} from '@mui/material'

const apiKey =
  document.querySelector<HTMLMetaElement>('meta[name="gkey"]')?.content ?? ''

interface Props {
  onPlaceSelected: (value: string | null) => void
  container?: HTMLElement | null
}
export const GoogleMapsAutocomplete = ({
  container,
  onPlaceSelected: onSelect,
}: Props): JSX.Element | null => {
  const {placePredictions, getPlacePredictions} = usePlacesService({
    apiKey: apiKey,
    debounce: 500,
  })
  const [value, setValue] =
    React.useState<google.maps.places.AutocompletePrediction | null>(null)

  const handleSelectAddress = React.useCallback(
    (event, newValue: google.maps.places.AutocompletePrediction | null) => {
      setValue(newValue)
      onSelect(newValue?.place_id ?? null)
    },
    [onSelect],
  )

  const handleInputChange = React.useCallback(
    (event: React.SyntheticEvent<Element, Event>, searchString: string) => {
      if (searchString === value?.description) return

      getPlacePredictions({
        input: searchString,
        region: 'us',
        types: ['address'],
        componentRestrictions: {country: 'us'},
      })
    },
    [getPlacePredictions, value?.description],
  )

  return (
    <Autocomplete
      open={placePredictions.length > 0}
      value={value}
      filterOptions={(options) =>
        options.filter(
          (option) =>
            option.types.includes('street_address') ||
            option.types.includes('premise'),
        )
      }
      componentsProps={{
        popper: {container: container ?? document.body},
      }}
      ListboxProps={{
        style: {overflow: 'auto', maxHeight: '200px'},
      }}
      autoComplete
      includeInputInList
      filterSelectedOptions
      blurOnSelect
      popupIcon={null}
      options={placePredictions}
      noOptionsText="Address not found"
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      onChange={handleSelectAddress}
      onInputChange={handleInputChange}
      isOptionEqualToValue={(option, value) =>
        option.place_id === value?.place_id
      }
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} sx={{touchAction: 'none'}} />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Box>
              <Typography variant="body2" color="text.secondary">
                {option.structured_formatting.main_text}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Box>
          </li>
        )
      }}
    />
  )
}
