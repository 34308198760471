import * as React from 'react'

import {Logout} from '@mui/icons-material'
import {Button} from '@mui/material'

import {usersSignOutPath} from 'src/generated/routes'

interface Props {
  variant?: 'outlined' | 'contained' | 'text'
  color?: 'primary' | 'secondary' | 'inherit'
  size?: 'small' | 'medium' | 'large'
  showIcon?: boolean
}

export const SignOutButton = ({
  variant = 'outlined',
  color = 'primary',
  size = 'medium',
  showIcon = false,
}: Props): JSX.Element => {
  return (
    <Button
      variant={variant}
      size={size}
      color={color}
      role="button"
      href={usersSignOutPath()}
      sx={{whiteSpace: 'nowrap'}}
      startIcon={showIcon ? <Logout /> : null}
    >
      Sign out
    </Button>
  )
}
