import * as React from 'react'

import {AxiosResponse} from 'axios'

import {
  UseLazyRequestOptions,
  RequestOptions,
  useRequest,
} from 'src/hooks/request/useRequest'
import {JsonApiIndexResponse} from 'src/types'
import {JsonApiIncluded} from 'src/types/jsonApi'
import {
  ParsedIndexResponse,
  parseIndexResponse,
} from 'src/util/request/parseResponse'

export type IndexFetch<
  T = unknown,
  I = Record<never, unknown>,
  M = unknown,
  P = unknown,
> = (opts?: RequestOptions<void, P>) => Promise<ParsedIndexResponse<T, I, M>>

export function useIndex<
  T,
  I extends JsonApiIncluded = Record<never, unknown>,
  M = unknown,
  P = unknown,
>(
  url?: string,
  options?: Omit<
    UseLazyRequestOptions<JsonApiIndexResponse<T, I, M>>,
    'onSuccess'
  > & {
    onSuccess?: (response: ParsedIndexResponse<T, I, M>) => void
  },
): {
  loading: boolean
  completed: boolean
  error: AxiosResponse | null
  fetch: IndexFetch<T, I, M, P>
} & ParsedIndexResponse<T, I, M> {
  const {onSuccess: onSuccessParsed} = options ?? {}
  const onSuccess = React.useCallback(
    (res: JsonApiIndexResponse<T, I, M>) => {
      onSuccessParsed && onSuccessParsed(parseIndexResponse(res))
    },
    [onSuccessParsed],
  )
  const modifiedOptions = React.useMemo(
    () => ({
      ...options,
      onSuccess: options?.onSuccess ? onSuccess : undefined,
    }),
    [options, onSuccess],
  )

  const {request, response, loading, completed, error} = useRequest<
    JsonApiIndexResponse<T, I, M>,
    void,
    P
  >('GET', url, modifiedOptions)

  const fetch = React.useCallback(
    async (opts?: RequestOptions<void, P> | undefined) =>
      request(opts).then((res) => parseIndexResponse(res ?? null)),
    [request],
  )

  const {entities, results, meta} = React.useMemo(
    () => parseIndexResponse(response),
    [response],
  )

  return {fetch, entities, results, meta, loading, completed, error}
}
