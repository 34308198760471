import * as React from 'react'

import {Box, Stack, Typography} from '@mui/material'

import {PartnerDocumentUploads} from 'src/components/partners/PartnerDocumentUploads'
import {WizardStepProps} from 'src/components/portal/SBAMicroflow/SBAApplicationWizard'
import {apiPartnersAttachmentsPath} from 'src/generated/routes'
import {useIndex} from 'src/hooks/request/useIndex'
import {AttachmentTypeName, PartnerAttachment} from 'src/types/attachment'

type Props = WizardStepProps

export const ShareYourFinancials = ({onReadyToAdvance}: Props): JSX.Element => {
  const {
    entities: attachments,
    loading: attachmentsLoading,
    fetch: refetchAttachments,
  } = useIndex<PartnerAttachment>(apiPartnersAttachmentsPath(), {
    autoRequest: true,
  })

  const handleSuccessfulFinancialDocSubmission = React.useCallback(async () => {
    await refetchAttachments()
  }, [refetchAttachments])

  const businessTaxReturns = React.useMemo(() => {
    return (
      attachments?.filter(
        (attachment) =>
          attachment.attachmentType === AttachmentTypeName.BusinessTaxReturn,
      ) || []
    )
  }, [attachments])

  const individualTaxReturns = React.useMemo(() => {
    return (
      attachments?.filter(
        (attachment) =>
          attachment.attachmentType === AttachmentTypeName.IndividualTaxReturn,
      ) || []
    )
  }, [attachments])

  React.useEffect(() => {
    if (
      !attachmentsLoading &&
      businessTaxReturns.length &&
      individualTaxReturns.length
    ) {
      onReadyToAdvance()
    }
  }, [
    attachmentsLoading,
    businessTaxReturns.length,
    individualTaxReturns.length,
    onReadyToAdvance,
  ])

  return (
    <Stack spacing={{xs: 3, sm: 5}}>
      <Typography sx={{opacity: 0.8}}>
        {
          "To make an introduction, all we need is 3 years of business and personal tax returns from you. We'll send your returns and your information to our SBA lending partner so you can have an informed conversation with them."
        }
      </Typography>
      <Box>
        <PartnerDocumentUploads
          types={[
            AttachmentTypeName.BusinessTaxReturn,
            AttachmentTypeName.IndividualTaxReturn,
          ]}
          onDone={handleSuccessfulFinancialDocSubmission}
          autoSave={true}
        />
      </Box>
    </Stack>
  )
}
