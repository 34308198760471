import * as React from 'react'

import {Button, Stack, Typography} from '@mui/material'

import {Popup} from 'src/components/portal/RecommendationFlow/Popup'
import {PopupContent} from 'src/components/portal/RecommendationFlow/PopupContent'
import {
  CTAType,
  RECOMMENDATION_FLOW_BUTTON_PROPS,
} from 'src/components/portal/RecommendationFlow/RecommendationFlowCard'

type Props = {
  open: boolean
  onClose: () => void
  completedCTATypes: CTAType[]
}

export const CreatingRecommendationModal = ({
  open,
  onClose,
  completedCTATypes,
}: Props) => {
  const description = React.useMemo(() => {
    const completedCTA = completedCTATypes[completedCTATypes.length - 1]

    if (completedCTA === 'changeProperty') {
      return 'Let’s see if this property is a better fit for ownership! We’ll email you when the recommendation is ready.'
    }

    if (completedCTA === 'addFinances') {
      return 'We’ll email you with an ownership recommendation once we review your finances and your desired property!'
    }
  }, [completedCTATypes])

  return (
    <Popup onClose={onClose} open={open}>
      <PopupContent title={'Reviewing your options'} onDone={onClose}>
        <Stack spacing={3} mt={3}>
          <Typography>{description}</Typography>
          <Stack alignItems="center">
            <Button {...RECOMMENDATION_FLOW_BUTTON_PROPS} onClick={onClose}>
              GOT IT
            </Button>
          </Stack>
        </Stack>
      </PopupContent>
    </Popup>
  )
}
