import * as React from 'react'

import {Typography, Slider, Checkbox} from '@mui/material'
import {Box, Stack} from '@mui/system'

import {WizardStepProps} from 'src/components/portal/SBAMicroflow/SBAApplicationWizard'
import {SBAApplication} from 'src/types'
import {formatCurrency} from 'src/util/format'

const MAX_LOAN_AMOUNT = 5000000
const MIN_LOAN_AMOUNT = 100000

interface Props extends WizardStepProps {
  defaultSBAApplication: SBAApplication
  onSBAApplicationChanged: (sbaApplication: SBAApplication) => void
}

export const LoanAmount = ({
  defaultSBAApplication,
  onReadyToAdvance,
  onSBAApplicationChanged,
}: Props) => {
  React.useEffect(() => onReadyToAdvance(), [onReadyToAdvance])
  const [loanAmount, setLoanAmount] = React.useState(
    defaultSBAApplication.loanAmount || 1000000,
  )

  const [unsureOfLoanAmount, setUnsureOfLoanAmount] = React.useState(
    defaultSBAApplication.unsureOfLoanAmount || false,
  )

  React.useEffect(() => {
    const sbaApplication: SBAApplication = {
      loanAmount,
      unsureOfLoanAmount,
    }
    if (unsureOfLoanAmount) {
      sbaApplication.loanAmount = null
    }
    onSBAApplicationChanged(sbaApplication)
  }, [loanAmount, unsureOfLoanAmount, onSBAApplicationChanged])

  const handleLoanAmountSliderChange = React.useCallback(
    (event, newLoanAmount) => {
      setLoanAmount(newLoanAmount)
    },
    [],
  )

  const valueLabelFormat = React.useCallback(
    (value: number) => formatCurrency(value.toString(), {precision: 0}),
    [],
  )

  return (
    <Box textAlign="center">
      <Box
        py={{xs: 4, sm: 8}}
        px={{xs: 2, sm: 4}}
        sx={{background: (theme) => theme.palette.background.blue}}
        mb={2.5}
      >
        <Typography sx={{opacity: 0.8}}>Total amount</Typography>
        <Typography variant="h2">
          {unsureOfLoanAmount
            ? 'To be discussed'
            : valueLabelFormat(loanAmount)}
        </Typography>
        <Slider
          value={loanAmount}
          min={MIN_LOAN_AMOUNT}
          step={10000}
          max={MAX_LOAN_AMOUNT}
          onChange={handleLoanAmountSliderChange}
          valueLabelDisplay="auto"
          valueLabelFormat={valueLabelFormat}
          disabled={unsureOfLoanAmount}
          sx={{
            color: (theme) => theme.palette.secondary.dark,
            '& .MuiSlider-thumb': {
              height: '1.75rem',
              width: '1.75rem',
            },
            marginY: {xs: 3, sm: 4},
          }}
        />
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Checkbox
            checked={unsureOfLoanAmount}
            onChange={(e) => setUnsureOfLoanAmount(e.target.checked)}
          />
          <Typography sx={{opacity: 0.8}}>
            Not sure what loan amount I need yet
          </Typography>
        </Stack>
      </Box>
      <Box px={{sm: 9, xs: 3}}>
        <Typography sx={{opacity: 0.8}}>
          This is only a preliminary estimate, you&apos;ll work with your loan
          officer later in the process on the exact loan amount.
        </Typography>
      </Box>
    </Box>
  )
}
