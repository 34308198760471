import * as React from 'react'

import {AxiosResponse} from 'axios'

import {useRequest} from 'src/hooks/request/useRequest'
import {JsonApiResponse} from 'src/types/jsonApi'
import {ParsedResponse, parseResponse} from 'src/util/request/parseResponse'

export type FileUploadPayload = {
  file: File
} & {[key: string]: string | Blob}

export type FileUploadRequestOptions = {
  url?: string
}

export type FileUploadResult<P extends FileUploadPayload, E, R> = {
  uploadFile: (
    requestPayload: P,
    opts?: FileUploadRequestOptions,
  ) => Promise<void | ParsedResponse<R>>
  loading: boolean
  error: AxiosResponse<E> | null
}

export function useFileUpload<
  P extends FileUploadPayload,
  E = void,
  R = unknown,
>(uploadEndpoint?: string): FileUploadResult<P, E, R> {
  const requestOptions = React.useMemo(
    () => ({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      raise: true,
    }),
    [],
  )
  const {request, loading, error} = useRequest<JsonApiResponse<R>, FormData>(
    'POST',
    uploadEndpoint,
    requestOptions,
  )

  const uploadFile = React.useCallback(
    async (requestPayload: P, opts?: FileUploadRequestOptions) => {
      const formData = new FormData()
      Object.entries(requestPayload).forEach(([key, value]) => {
        formData.append(key, value)
      })
      return request({data: formData, url: opts?.url}).then(parseResponse)
    },
    [request],
  )

  return {uploadFile, loading, error}
}
