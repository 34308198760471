import React from 'react'

import {Error as ErrorIcon} from '@mui/icons-material'
import {Box, Container, Stack, Typography} from '@mui/material'

interface Props {
  children: React.ReactNode
}
interface State {
  error: Error | null
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {error: null}
  }

  static getDerivedStateFromError(error: Error): State {
    return {error}
  }

  render(): JSX.Element {
    if (this.state.error) {
      return (
        <Container maxWidth="md">
          <Box display="flex" mt={8}>
            <ErrorIcon
              color="error"
              fontSize="large"
              sx={{marginTop: '2px', mr: 2}}
            />
            <Stack spacing={4}>
              <Typography variant="h4">Something went wrong :(</Typography>
              <Typography variant="subtitle1">
                {this.state.error.message}
              </Typography>
              <Typography variant="caption" sx={{whiteSpace: 'pre-wrap'}}>
                {this.state.error.stack}
              </Typography>
            </Stack>
          </Box>
        </Container>
      )
    }

    return <>{this.props.children}</>
  }
}
