import * as React from 'react'

type UseDragEventsArgs = {
  onFileDrop: (fileList: FileList) => void
}

type UseDragEventsResult = {
  draggedItem: DataTransferItem | null | undefined
  handleDragEnter: (event: React.DragEvent<HTMLElement>) => void
  handleDragLeave: (event: React.DragEvent<HTMLElement>) => void
  handleDragOver: (event: React.DragEvent<HTMLElement>) => void
  handleDrop: (event: React.DragEvent<HTMLElement>) => void
}

export const useDragEvents = ({
  onFileDrop,
}: UseDragEventsArgs): UseDragEventsResult => {
  const dragCounterRef = React.useRef<number>(0)
  const [draggedItem, setDraggedItem] =
    React.useState<DataTransferItem | null>()

  const handleDragEnter = React.useCallback(
    (event: React.DragEvent<HTMLElement>) => {
      const dataItem: DataTransferItem | undefined = event.dataTransfer.items[0]
      if (!dataItem) return

      dragCounterRef.current += 1
      if (dragCounterRef.current > 0) {
        setDraggedItem({...dataItem})
      }
    },
    [],
  )

  const handleDragLeave = React.useCallback(
    (event: React.DragEvent<HTMLElement>) => {
      const dataItem: DataTransferItem | undefined = event.dataTransfer.items[0]
      if (!dataItem) return

      dragCounterRef.current -= 1
      if (dragCounterRef.current == 0) {
        setDraggedItem(null)
      }
    },
    [],
  )

  const handleDrop = React.useCallback(
    (event: React.DragEvent<HTMLElement>) => {
      event.preventDefault()
      event.stopPropagation()

      handleDragLeave(event)

      const fileList = event.dataTransfer.files
      if (fileList) {
        onFileDrop(fileList)
      }
    },
    [handleDragLeave, onFileDrop],
  )

  const handleDragOver = React.useCallback(
    (event: React.DragEvent<HTMLElement>) => {
      event.preventDefault()
    },
    [],
  )

  return {
    draggedItem,
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDrop,
  }
}
