import * as React from 'react'
import {useHistory} from 'react-router-dom'

import {ArrowBack, WarningAmberRounded} from '@mui/icons-material'
import {
  Box,
  Button,
  IconButton,
  Link,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material'
import {useTheme} from '@mui/material'
import axios from 'axios'
import {debounce} from 'lodash'
import {MuiOtpInput} from 'mui-one-time-password-input'
import {useSnackbar} from 'notistack'

import {OutlinedTextField} from 'src/framework/form/OutlinedTextField'
import {loginPath, loginWithOTPPath} from 'src/generated/routes'
import {useForm} from 'src/hooks/form/useForm'
import {useCurrentUser} from 'src/hooks/request/useCurrentUser'
import {useRequest} from 'src/hooks/request/useRequest'
import {useCookie} from 'src/hooks/util/useCookie'
import {useToggle} from 'src/hooks/util/useToggle'
import * as Analytics from 'src/util/analytics'
import {decodeRailsSignedCookie} from 'src/util/cookie'
import {ReactComponent as WithcoLogo} from 'svg/withco_logo.svg'

const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const matchIsNumeric = (character: string) => {
  return !Number.isNaN(Number(character))
}

const validateChar = (value: string) => {
  return matchIsNumeric(value)
}

type Props = {
  verifyUserFlow?: boolean
  afterLoginPath?: string
}

export const SMBLogin = ({
  verifyUserFlow: verifyLoginFlow = false,
  afterLoginPath = '/portal',
}: Props): JSX.Element => {
  const {refetch: fetchUser, user} = useCurrentUser()
  const history = useHistory()
  const theme = useTheme()
  const {enqueueSnackbar} = useSnackbar()

  const {request: sendOtp} = useRequest<void, {email: string}>(
    'POST',
    loginPath(),
    {disableDefaultErrorHandling: true},
  )

  const queryParams = new URLSearchParams(window.location.search)
  const redirectTo = queryParams.get('redirect_to')

  if (redirectTo) {
    afterLoginPath = redirectTo
  }

  const {request: loginWithOtp} = useRequest<
    void,
    {email: string; otp: string}
  >('POST', loginWithOTPPath(), {disableDefaultErrorHandling: true})

  const [wpui, unsetWpui] = useCookie('wpui')
  const [wpue, unsetWpue] = useCookie('wpue')
  const [skipWelcome, unsetSkipWelcome] = useCookie('skip_welcome')

  const [isEmailSubmitted, setEmailSubmitted, unsetEmailSubmitted] =
    useToggle(false)
  const [hasOtpLoginError, setHasOtpLoginError, unsetHasOtpLoginError] =
    useToggle(false)
  const [hasSentOTP, setHasSentOTP, unsetHasSentOTP] = useToggle(false)

  const hasUserCookie = React.useMemo(() => {
    return wpui && wpui.length > 0
  }, [wpui])

  const {formState, handlers, setters} = useForm({
    email: '',
    otp: '',
  })

  const resetOtpForm = React.useCallback(() => {
    unsetWpui()
    unsetWpue()
    setters.otp('')
    unsetHasOtpLoginError()
    unsetEmailSubmitted()
    unsetSkipWelcome()
    unsetHasSentOTP()
  }, [
    unsetWpui,
    unsetWpue,
    setters,
    unsetHasOtpLoginError,
    unsetEmailSubmitted,
    unsetSkipWelcome,
    unsetHasSentOTP,
  ])

  const sendOTPEmail = React.useCallback(async () => {
    try {
      await sendOtp({data: {email: formState.email}})
      setEmailSubmitted()
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 422) {
          resetOtpForm()
        }
      }
    }
  }, [formState.email, resetOtpForm, sendOtp, setEmailSubmitted])

  const handleSubmitEmail = React.useCallback(
    (event) => {
      event.preventDefault()
      sendOTPEmail()
    },
    [sendOTPEmail],
  )

  const emailDisplay = React.useMemo(() => {
    if (formState.email.length) return formState.email
    if (wpue) return decodeRailsSignedCookie(wpue)

    return ''
  }, [formState.email, wpue])

  const resendOtp = React.useMemo(
    () =>
      debounce(
        async () => {
          await sendOTPEmail()
          enqueueSnackbar(
            `An email with a new code has been sent to ${emailDisplay}`,
            {
              variant: 'success',
            },
          )
        },
        3000,
        {leading: true, trailing: false},
      ),
    [emailDisplay, enqueueSnackbar, sendOTPEmail],
  )

  const isEmailValid = React.useMemo(
    () => validateEmail(formState.email),
    [formState.email],
  )

  const loginType = React.useMemo(() => {
    verifyLoginFlow ? 'accountVerification' : 'login'
  }, [verifyLoginFlow])

  const handleCompleteOtp = React.useCallback(
    (otp: string) => {
      loginWithOtp({
        data: {email: formState.email, otp},
      })
        .then(() => fetchUser())
        .then(() => {
          Analytics.track('Sign in Success', {
            userId: user?.id,
            type: loginType,
          })
          history.replace(afterLoginPath)
        })
        .catch((err) => {
          Analytics.track('Sign in Failure', {
            type: loginType,
          })
          if (err.response.status === 400) {
            setHasOtpLoginError()
          } else if (err.response.status === 422) {
            resetOtpForm()
          }
        })
    },
    [
      afterLoginPath,
      fetchUser,
      formState.email,
      history,
      loginType,
      loginWithOtp,
      resetOtpForm,
      setHasOtpLoginError,
      user?.id,
    ],
  )

  React.useEffect(() => {
    if (isEmailSubmitted) {
      setHasSentOTP()
    }
    if (skipWelcome && hasUserCookie) {
      setHasSentOTP()
      unsetSkipWelcome()
    }
  }, [
    hasUserCookie,
    isEmailSubmitted,
    setHasSentOTP,
    skipWelcome,
    unsetSkipWelcome,
  ])

  const currentStep = React.useMemo(() => {
    if (hasSentOTP) return 'otp'
    if (hasUserCookie) return 'expiredSession'

    return 'newSession'
  }, [hasSentOTP, hasUserCookie])

  switch (currentStep) {
    case 'newSession':
      return (
        <Stack spacing={2} alignItems="center" m={4}>
          <SvgIcon
            component={WithcoLogo}
            inheritViewBox
            sx={{fontSize: 72}}
            color="primary"
          />
          <Stack spacing={3}>
            <Typography textAlign="center" variant="h4">
              Sign in to your account
            </Typography>
            <Box width="100%">
              <form onSubmit={handleSubmitEmail}>
                <Stack spacing={2}>
                  <OutlinedTextField
                    id="email"
                    label="Email"
                    onChange={handlers.email.change}
                    value={formState.email}
                    inputProps={{inputMode: 'email'}}
                  />
                  <Box display="flex" justifyContent="center">
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={!isEmailValid}
                    >
                      Login
                    </Button>
                  </Box>
                </Stack>
              </form>
            </Box>
          </Stack>
        </Stack>
      )
    case 'expiredSession':
      return (
        <Stack spacing={2} alignItems="center" m={4}>
          <SvgIcon
            component={WithcoLogo}
            inheritViewBox
            sx={{fontSize: 72}}
            color="primary"
          />
          <Stack spacing={3}>
            <Typography textAlign="center" variant="h4">
              {verifyLoginFlow ? `Account Verification` : `Welcome back!`}
            </Typography>
            <Typography textAlign="center" variant="body1">
              {'To protect your data we need to send you an email at '}
              <strong>{emailDisplay}</strong>{' '}
              {verifyLoginFlow
                ? 'to verify your account. '
                : 'to login again. '}
              {!verifyLoginFlow && (
                <Link onClick={resetOtpForm} sx={{cursor: 'pointer'}}>
                  Not you?
                </Link>
              )}
            </Typography>
            <Box width="100%">
              <form onSubmit={handleSubmitEmail}>
                <Stack spacing={2}>
                  <Box display="flex" justifyContent="center">
                    <Button type="submit" variant="contained">
                      {verifyLoginFlow ? 'Send Email' : 'Login'}
                    </Button>
                  </Box>
                </Stack>
              </form>
            </Box>
          </Stack>
        </Stack>
      )
    case 'otp':
      return (
        <>
          {!verifyLoginFlow && (
            <Box display="flex" position="absolute">
              <IconButton onClick={resetOtpForm}>
                <ArrowBack />
              </IconButton>
            </Box>
          )}
          <Stack spacing={2} alignItems="center" m={4}>
            <SvgIcon
              component={WithcoLogo}
              inheritViewBox
              sx={{fontSize: 72}}
              color="primary"
            />
            <Stack spacing={2}>
              <Typography textAlign="center" variant="h4">
                Check your email for a code
              </Typography>
              <Typography textAlign="center" variant="body1">
                We’ve sent a 6-digit code to <strong>{emailDisplay}</strong>.
                The code expires shortly, so please enter it soon.
              </Typography>
              <MuiOtpInput
                length={6}
                TextFieldsProps={{
                  inputProps: {pattern: '[0-9]*', inputMode: 'numeric'},
                }}
                value={formState.otp}
                onChange={setters.otp}
                validateChar={validateChar}
                onComplete={handleCompleteOtp}
                my={6}
                sx={{gap: {xs: 1, sm: 2.5}}}
              />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-around"
                color={theme.palette.error.main}
              >
                {hasOtpLoginError && <WarningAmberRounded fontSize="small" />}
                <Typography textAlign="center">
                  {hasOtpLoginError ? (
                    `That code wasn't valid. Please try again or request a new one.`
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </Typography>
              </Stack>

              <Typography variant="body1" textAlign="center">
                Can’t find your code?{' '}
                <Link onClick={resendOtp} sx={{cursor: 'pointer'}}>
                  Click here
                </Link>{' '}
                to resend it.
              </Typography>
            </Stack>
          </Stack>
        </>
      )
    default:
      return <></>
  }
}
