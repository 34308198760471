import * as React from 'react'

import {Box, Button, ButtonProps} from '@mui/material'

import {CalendlyModal} from 'src/components/partners/CalendlyModal'
import {useToggle} from 'src/hooks/util/useToggle'
import {CompanyOwningUser, User} from 'src/types'

interface Props extends Omit<ButtonProps, 'onClick'> {
  user: User
  companyOwningUser: CompanyOwningUser
  label: string
  onCallScheduled: () => void
}

export const ScheduleCallButton = ({
  label,
  user,
  companyOwningUser,
  onCallScheduled,
  ...buttonProps
}: Props): JSX.Element => {
  const [isModalOpen, openModal, closeModal] = useToggle(false)

  return (
    <Box>
      <CalendlyModal
        open={isModalOpen}
        onClose={closeModal}
        companyOwningUser={companyOwningUser}
        user={user}
        onScheduledCall={onCallScheduled}
        pageLocation="portal"
      />
      <Button onClick={openModal} {...buttonProps}>
        {label}
      </Button>
    </Box>
  )
}
