export type User = {
  id: string
  companyId: string | null
  email: string
  name: string | null
  firstName: string | null
  lastName: string | null
  createdAt: string | null
  avatarUrl: string | null
  agreedToTermsAt: string | null
  agreedToTermsVersion: string | null
  lastInvitedAt: string | null
  lastInvitedUsing: string | null
  lastInvitedTo: string | null
  hubspotId: string | null
  verifiedAccountAt: string | null
  initialConsultationEventCreatedAt: string | null
  recommendedTodoTipDismissedAt: string | null
  welcomeWizardStartedAt: string | null
  budgetInbound: boolean
  internal: boolean
  product: PortalProduct | null
}

export type UserUpdateAttributes = {
  recommendedTodoTipDismissedAt: Date
  welcomeWizardStartedAt: Date
}

export enum PortalProduct {
  DEFAULT = 'default',
  DIO_ONBOARDING = 'dio_onboarding',
}
