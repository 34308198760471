import Hotjar from '@hotjar/browser'

import {decodeBase64, getCookie} from 'src/util/cookie'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hj?: any
  }
}

const getHotjarSiteId = (): string | undefined => {
  return document.querySelector<HTMLMetaElement>('meta[name="hotJarSiteId"]')
    ?.content
}

export const initialize = () => {
  if (getHotjarSiteId()) {
    const hotJarVersion = 6
    Hotjar.init(Number(getHotjarSiteId()), hotJarVersion)
  }
}

export const identify = (id: string, email: string, name: string): void => {
  if (!window.hj) return

  Hotjar.identify(id, {email, name})
}

export const track = (eventName: string): void => {
  if (!window.hj) return

  Hotjar.event(eventName)
}

export const getUserId = (): string | null | undefined => {
  try {
    const cookieValue = getCookie(`_hjSessionUser_${getHotjarSiteId()}`)
    const userObject = cookieValue && JSON.parse(decodeBase64(cookieValue))
    return userObject.id
  } catch (e) {
    return
  }
}

export default {
  initialize,
  identify,
  track,
  getUserId,
}
