import * as React from 'react'

import {useMediaQuery, SwipeableDrawer, useTheme} from '@mui/material'
import {Box} from '@mui/system'

import {CenterModal} from 'src/framework/ui/CenterModal'

interface PopupProps {
  children: React.ReactNode
  onClose: () => void
  open: boolean
}
export const Popup = ({children, onClose, open}: PopupProps): JSX.Element => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      onClose={onClose}
      onOpen={() => null}
      open={open}
      sx={{
        '.MuiDrawer-paperAnchorBottom': {
          borderRadius: '10px 10px 0 0 ',
        },
      }}
    >
      <Box px={3} pb={3} pt={4} overflow="hidden">
        {children}
      </Box>
    </SwipeableDrawer>
  ) : (
    <CenterModal
      disableCloseButton
      withPadding={false}
      open={open}
      onClose={onClose}
    >
      <Box p={3}>{children}</Box>
    </CenterModal>
  )
}
