import * as React from 'react'

import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'

export const useAxiosErrorHandler = (
  ignoredErrorCodes?: Array<number>,
): ((error: AxiosError, prefix?: string) => void) => {
  const {enqueueSnackbar} = useSnackbar() ?? {}

  const handleError = React.useCallback(
    (error: AxiosError, prefix = '') => {
      const status = error.response?.status
      if (!status || !ignoredErrorCodes?.includes(status)) {
        enqueueSnackbar(
          `${prefix}

          ${error.response?.data?.error || error.message}`,
          {
            variant: 'error',
            autoHideDuration: 10000,
            style: {whiteSpace: 'pre-line'},
          },
        )
      }
    },
    [enqueueSnackbar, ignoredErrorCodes],
  )

  return handleError
}
