import * as React from 'react'

import {Typography, Button} from '@mui/material'
import {Box, Stack} from '@mui/system'

import {RECOMMENDATION_FLOW_BUTTON_PROPS} from 'src/components/portal/RecommendationFlow/RecommendationFlowCard'
import {
  TileStatus,
  TileStatusIcon,
} from 'src/components/portal/RecommendationFlow/Tile'
import {MaterialSymbolIcon} from 'src/framework/ui/MaterialSymbolIcon'

export type TilePopupConfig = {
  title: string | null
  status?: TileStatus
  description?: string[]
  explainerSubtitle?: string
  explainerDescription?: Array<string | JSX.Element>
}

interface Props {
  config: TilePopupConfig
  onDone: () => void
}

export const TilePopupContent = ({config, onDone}: Props): JSX.Element => {
  return (
    <Box mt={3}>
      {config?.description?.length ? (
        <Stack direction="row" spacing={1.5} mb={3.5}>
          <TileStatusIcon tileStatus={config?.status} />
          <Stack spacing={1.75}>
            {config?.description?.map((line, index) => (
              <Typography key={`popup-description-${index}`}>{line}</Typography>
            ))}
          </Stack>
        </Stack>
      ) : null}
      <Typography variant="h4" mb={1.5}>
        {config?.explainerSubtitle}
      </Typography>
      <Stack direction="row" spacing={1.5} mb={4}>
        <MaterialSymbolIcon weight={300} fill={1} color="secondary">
          info
        </MaterialSymbolIcon>
        <Stack spacing={1.75}>
          {config?.explainerDescription?.map((line, index) => (
            <Typography key={`popup-explainer-${index}`}>{line}</Typography>
          ))}
        </Stack>
      </Stack>
      <Stack alignItems="center">
        <Button {...RECOMMENDATION_FLOW_BUTTON_PROPS} onClick={onDone}>
          Got it
        </Button>
      </Stack>
    </Box>
  )
}
