import React from 'react'

import {
  Box,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'

import {PaymentScenarioComparisonChart} from 'src/components/portal/PaymentScenarioComparisonChart'
import {PaymentScenarioComparisonSummary} from 'src/components/portal/PaymentScenarioComparisonSummary'
import {apiPartnersPaymentScenarioComparisonPath} from 'src/generated/routes'
import {useShow} from 'src/hooks/request/useShow'
import {useToggle} from 'src/hooks/util/useToggle'
import {BudgetEstimate, PartnerDeal, PaymentComparison} from 'src/types'

type ComparisonMode = 'shortTerm' | 'longTerm'

type Props = {
  deal: PartnerDeal
  budgetEstimate: BudgetEstimate
  refetchDeal: () => void
  onPurchasePriceChange?: (purchasePrice: number) => void
  onRender: () => void
  trackingLocation: string
}

export const PaymentScenarios = ({
  deal,
  budgetEstimate,
  refetchDeal,
  onPurchasePriceChange,
  onRender,
  trackingLocation,
}: Props): JSX.Element => {
  const [purchasePrice, setPurchasePrice] = React.useState<number | null>(null)
  const [comparisonMode, setComparisonMode] =
    React.useState<ComparisonMode>('shortTerm')

  const [
    isPaymentScenarioComparisonSummaryRendered,
    renderPaymentScenarioSummaryComparison,
  ] = useToggle(false)

  const {fetch: fetchPaymentComparison, entity: paymentComparison} =
    useShow<PaymentComparison>(
      apiPartnersPaymentScenarioComparisonPath(deal.id),
    )

  React.useEffect(() => {
    if (
      !!(
        isPaymentScenarioComparisonSummaryRendered &&
        paymentComparison &&
        paymentComparison.withcoPayments.length > 0
      )
    ) {
      onRender()
    }
  }, [isPaymentScenarioComparisonSummaryRendered, paymentComparison, onRender])

  const numberOfYears = React.useMemo(() => {
    if (comparisonMode === 'shortTerm') {
      return 5
    }

    return paymentComparison ? paymentComparison.withcoPayments.length - 1 : 0
  }, [comparisonMode, paymentComparison])

  const title = React.useMemo(
    () => `${numberOfYears} Year Cost Comparison`,
    [numberOfYears],
  )

  React.useEffect(() => {
    if (purchasePrice) {
      fetchPaymentComparison({params: {purchasePrice}})
    }
  }, [fetchPaymentComparison, purchasePrice])

  const handlePurchasePriceChange = React.useCallback(
    (newPurchasePrice) => {
      setPurchasePrice(newPurchasePrice)
      onPurchasePriceChange?.(newPurchasePrice)
    },
    [onPurchasePriceChange],
  )

  return (
    <Stack spacing={{xs: 2, sm: 3}} sx={{textAlign: 'left'}}>
      <Typography variant="h2">
        Compare lease-to-own with owning today
      </Typography>
      {paymentComparison && (
        <Paper
          sx={{
            backgroundColor: (theme) => theme.palette.background.blue,
            p: {xs: 2, sm: 3},
          }}
        >
          <Stack spacing={{xs: 2, sm: 3}}>
            <Stack
              direction={{xs: 'column-reverse', sm: 'row'}}
              justifyContent={{xs: 'flex-start', sm: 'space-between'}}
              alignItems="center"
              spacing={2}
            >
              <Typography variant="h3">{title}</Typography>
              <Box>
                <ToggleButtonGroup
                  size="small"
                  exclusive
                  value={comparisonMode}
                  onChange={(event, newComparisonMode) => {
                    if (newComparisonMode) {
                      setComparisonMode(newComparisonMode)
                    }
                  }}
                  sx={{
                    padding: 0.5,
                    borderRadius: 2,
                    gap: 0.5,
                    backgroundColor: (theme) => theme.palette.secondary.light,
                    '.MuiToggleButton-root': {
                      textTransform: 'none',
                      border: 'none',
                      px: 2.5,
                      py: 1,
                      color: (theme) => theme.palette.secondary.dark,
                      '&.MuiToggleButtonGroup-grouped': {
                        borderRadius: 1.5,
                      },
                      backgroundColor: (theme) => theme.palette.secondary.light,
                      '&.Mui-selected': {
                        backgroundColor: 'white',
                        '&:hover': {
                          backgroundColor: 'white',
                        },
                      },
                    },
                  }}
                >
                  <ToggleButton value="shortTerm" disableRipple>
                    Short-Term
                  </ToggleButton>
                  <ToggleButton value="longTerm" disableRipple>
                    Long-Term
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Stack>
            <PaymentScenarioComparisonChart
              withcoPayments={paymentComparison.withcoPayments}
              mortgagePayments={paymentComparison.mortgagePayments}
              numberOfYears={numberOfYears}
            />
          </Stack>
        </Paper>
      )}
      {budgetEstimate && deal && (
        <Box>
          <PaymentScenarioComparisonSummary
            budgetEstimate={budgetEstimate}
            deal={deal}
            refetchDeal={refetchDeal}
            onPurchasePriceChange={handlePurchasePriceChange}
            onRender={renderPaymentScenarioSummaryComparison}
            trackingLocation={trackingLocation}
          />
        </Box>
      )}
    </Stack>
  )
}
