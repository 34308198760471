import * as React from 'react'
import {render} from 'react-dom'

import Rails from '@rails/ujs'
import * as Sentry from '@sentry/react'
import {BrowserTracing} from '@sentry/tracing'
import {asyncWithLDProvider} from 'launchdarkly-react-client-sdk'

import {PortalApp} from 'src/PortalApp'
import * as Hotjar from 'src/util/hotjar'

Rails.start()

document.addEventListener('DOMContentLoaded', async () => {
  const ldClientId =
    document.querySelector<HTMLMetaElement>('meta[name="ldcid"]')?.content || ''
  const context = {
    kind: 'user',
    key: 'anonymous-user',
    anonymous: true,
  }
  const LDProvider = await asyncWithLDProvider({
    clientSideID: ldClientId,
    context,
  })

  const sentryEnv = document.querySelector<HTMLMetaElement>(
    'meta[name="sentryenv"]',
  )?.content
  const sentryDSN = document.querySelector<HTMLMetaElement>(
    'meta[name="sentrydsn"]',
  )?.content
  Sentry.init({
    dsn: sentryDSN,
    environment: sentryEnv,
    integrations: [new BrowserTracing()],
    // debug: true,
  })

  Hotjar.initialize()

  render(
    <LDProvider>
      <PortalApp />
    </LDProvider>,
    document.body.appendChild(document.createElement('div')),
  )
})
