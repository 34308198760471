import * as React from 'react'

import {
  Box,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {ResponsiveLine, SliceTooltip, SliceTooltipProps} from '@nivo/line'
import {useFlags} from 'launchdarkly-react-client-sdk'

import {AnnualPayment} from 'src/types/annualPayment'
import {formatCurrency} from 'src/util/format'
import {formatCurrencyCompact} from 'src/util/format/formatCurrencyCompact'

const currentYear = new Date().getFullYear()

interface Props {
  withcoPayments: AnnualPayment[]
  mortgagePayments: AnnualPayment[]
  numberOfYears: number
}
export const PaymentScenarioComparisonChart = ({
  withcoPayments,
  mortgagePayments,
  numberOfYears,
}: Props): JSX.Element => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const {paymentScenarioPresentValueEnabled} = useFlags()

  const annualPaymentToPoint = React.useCallback(
    (annualPayment: AnnualPayment) => ({
      x: annualPayment.year + currentYear,
      y: paymentScenarioPresentValueEnabled
        ? annualPayment.presentValueCumulativeExpense
        : annualPayment.cumulativeExpense,
    }),
    [paymentScenarioPresentValueEnabled],
  )

  const [withcoPaymentsDataPoints, mortgagePaymentsDataPoints] =
    React.useMemo(() => {
      const withcoPaymentsDataPoints = withcoPayments.map(annualPaymentToPoint)

      const mortgagePaymentsDataPoints =
        mortgagePayments.map(annualPaymentToPoint)

      return [withcoPaymentsDataPoints, mortgagePaymentsDataPoints]
    }, [annualPaymentToPoint, mortgagePayments, withcoPayments])

  const chartData = React.useMemo(
    () => [
      {
        id: 'SBA 7(a) Loan Cumulative Cost',
        data: mortgagePaymentsDataPoints,
      },
      {
        id: 'Lease-to-own Cumulative Cost',
        data: withcoPaymentsDataPoints,
      },
    ],
    [mortgagePaymentsDataPoints, withcoPaymentsDataPoints],
  )

  const yValues = React.useMemo(() => {
    // https://stackoverflow.com/questions/326679/choosing-an-attractive-linear-scale-for-a-graphs-y-axis/326746#326746
    const yDataPoints = chartData[0].data
      .slice(0, numberOfYears + 1)
      .map(({y}) => y)
      .concat(chartData[1].data.slice(0, numberOfYears + 1).map(({y}) => y))
    const min = Math.min(...yDataPoints)
    const max = Math.max(...yDataPoints)
    const range = max - min
    const tickCount = 5
    const unroundedTickSize = range / (tickCount - 1)
    const x = Math.ceil(Math.log10(unroundedTickSize) - 1)
    const pow10x = Math.pow(10, x)
    const roundedTickRange = Math.ceil(unroundedTickSize / pow10x) * pow10x
    const minYValue = roundedTickRange * Math.floor(min / roundedTickRange)
    const maxYValue = roundedTickRange * Math.ceil(max / roundedTickRange)
    const yValues = []
    for (let i = minYValue; i <= maxYValue; i += roundedTickRange) {
      yValues.push(i)
    }
    return {min: minYValue, max: maxYValue, values: yValues}
  }, [chartData, numberOfYears])

  const tickValues = React.useMemo(() => {
    const values = [currentYear]
    const yearSpan = numberOfYears / 5
    for (let i = yearSpan; i < chartData[0].data.length; i += yearSpan) {
      values.push(currentYear + i)
    }
    return values
  }, [chartData, numberOfYears])

  const legendAndSpacingHeight = isMobile ? 64 : 36
  const yAxisLabelWidth = 50

  return (
    <Box
      sx={{
        height: '502px',
        width: '100%',
        maxHeight: {xs: '33vh', sm: '50vh'},
      }}
    >
      <ResponsiveLine
        // General
        margin={{
          top: legendAndSpacingHeight,
          bottom: 40,
          left: yAxisLabelWidth,
          right: isMobile ? 16 : 32,
        }}
        data={chartData}
        colors={['#EADFC7', '#006AFF']}
        pointLabelYOffset={0}
        enablePoints={false}
        enableArea={true}
        areaOpacity={0.4}
        // Axes
        yScale={{
          type: 'linear',
          min: yValues.min,
          max: yValues.max,
        }}
        xScale={{
          type: 'linear',
          min: currentYear,
          max: currentYear + numberOfYears,
        }}
        gridYValues={yValues.values}
        gridXValues={withcoPaymentsDataPoints.map(({x}) => x)}
        axisLeft={{
          tickPadding: 12,
          tickSize: 0,
          format: formatCurrencyCompact,
          tickValues: yValues.values,
        }}
        axisBottom={{
          tickPadding: 12,
          tickSize: 0,
          tickValues: tickValues,
        }}
        // Legends
        legends={[
          {
            anchor: isMobile ? 'top-left' : 'top',
            direction: isMobile ? 'column' : 'row',
            itemHeight: 20,
            itemWidth: 200,
            translateX: isMobile ? -yAxisLabelWidth : 0,
            translateY: -legendAndSpacingHeight,
          },
        ]}
        // Tooltips
        isInteractive={true}
        useMesh={true}
        enableCrosshair={true}
        crosshairType="x"
        enableSlices="x"
        sliceTooltip={Tooltip}
        // Theme
        theme={{
          fontFamily: theme.typography.h5.fontFamily,
          textColor: theme.palette.secondary.main,
          grid: {
            line: {
              stroke: '#EEEFF5',
              strokeWidth: 1,
              strokeDasharray: '4 4',
            },
          },
        }}
      />
    </Box>
  )
}

const tooltipLabel = (serieId: string): string => {
  switch (serieId) {
    case 'SBA 7(a) Loan Cumulative Cost':
      return 'SBA 7(a) Loan'
    case 'Lease-to-own Cumulative Cost':
      return 'Lease-to-own'
    default:
      return serieId
  }
}

const Tooltip: SliceTooltip = ({slice}: SliceTooltipProps) => {
  const {points} = slice

  return (
    <Paper sx={{p: 1, backgroundColor: 'rgb(255,255,255, 0.9)'}}>
      <Stack spacing={0.5}>
        <Typography variant="h5" textAlign="right">
          {slice.points[0].data.xFormatted}
        </Typography>
        {points.map((point) => (
          <Stack
            direction={{xs: 'column', sm: 'row'}}
            alignItems={{xs: 'flex-start', sm: 'center'}}
            spacing={{xs: 0, sm: 1}}
            justifyContent={'space-between'}
            key={point.id}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Box
                sx={{
                  height: '16px',
                  width: '16px',
                  backgroundColor: point.serieColor,
                }}
              />
              <Typography
                variant="caption"
                color={(theme) => theme.palette.secondary.main}
              >
                {tooltipLabel(`${point.serieId}`)}:
              </Typography>
            </Stack>
            <Typography variant="h5" alignSelf={{xs: 'flex-end', sm: 'center'}}>
              {formatCurrency(`${point.data.y}`, {precision: 0})}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Paper>
  )
}
