import {createSlice} from '@reduxjs/toolkit'

import {AttachmentType} from 'src/types'

interface AttachmentTypeState {
  items: AttachmentType[]
  partnerItems: AttachmentType[]
}

const initialState: AttachmentTypeState = {
  items: [],
  partnerItems: [],
}

export const attachmentTypesSlice = createSlice({
  name: 'attachmentTypes',
  initialState,
  reducers: {
    updateAttachmentTypes: (state, action) => {
      const payload = action.payload
      state.items = payload
    },
    updatePartnerAttachmentTypes: (state, action) => {
      const payload = action.payload
      state.partnerItems = payload
    },
  },
})

export const {updateAttachmentTypes, updatePartnerAttachmentTypes} =
  attachmentTypesSlice.actions
export default attachmentTypesSlice.reducer
