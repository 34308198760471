import {JsonApiResponse, JsonApiIndexResponse} from 'src/types'
import {JsonApiIncluded} from 'src/types/jsonApi'

export function responseToMeta<
  M,
  I extends JsonApiIncluded = Record<string, never>,
  A = unknown,
>(
  response: JsonApiResponse<A, I, M> | JsonApiIndexResponse<A, I, M> | null,
): M | null {
  return response?.meta ?? null
}
