import * as React from 'react'

import {Paper, Typography} from '@mui/material'
import {Stack} from '@mui/system'

import {PartnerDocumentUploadsSba} from 'src/components/partners/PartnerDocumentUploadsSba'
import {ScreenLayout} from 'src/framework/ScreenLayout'
import {PortalAppThemeProvider} from 'src/framework/theming/PortalAppThemeProvider'
import {apiPartnersUserCompanyPath} from 'src/generated/routes'
import {useTrackPage} from 'src/hooks/analytics/useTrackPage'
import {useCurrentUser} from 'src/hooks/request/useCurrentUser'
import {useShow} from 'src/hooks/request/useShow'
import {PartnerCompany} from 'src/types'
import {PartnerDocumentRequest} from 'src/types/documentRequest'

export const PartnerDocumentUploadsScreen = (): JSX.Element | null => {
  const {user} = useCurrentUser()

  const {fetch: fetchCompany} = useShow<
    PartnerCompany,
    {documentRequest: PartnerDocumentRequest}
  >()

  const refetchCompany = React.useCallback(async () => {
    if (user) {
      return fetchCompany({url: apiPartnersUserCompanyPath(user.id)})
    }
  }, [fetchCompany, user])

  React.useEffect(() => {
    refetchCompany()
  }, [refetchCompany])

  useTrackPage({pageName: 'uploadDocuments'})

  return (
    <PortalAppThemeProvider>
      <ScreenLayout fullHeight maxWidth="md">
        <Stack spacing={3} width="100%">
          <Typography variant="h2">Upload Documents</Typography>
          <Paper sx={{py: {xs: 3, sm: 4, md: 6}, px: {xs: 2, sm: 4, md: 6}}}>
            <PartnerDocumentUploadsSba />
          </Paper>
        </Stack>
      </ScreenLayout>
    </PortalAppThemeProvider>
  )
}
