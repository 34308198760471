export const formatCurrencyCompact = (amount: number | null) => {
  if (amount === null) {
    return null
  }

  const formatter = new Intl.NumberFormat('en', {
    notation: 'compact',
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 2,
    minimumSignificantDigits: Math.abs(amount) < 10 ? 1 : 2,
  })
  return formatter.format(amount)
}
