import * as React from 'react'

import {
  Button,
  useTheme,
  ButtonProps,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import {alpha, Box, Stack, SxProps} from '@mui/system'
import {useFlags} from 'launchdarkly-react-client-sdk'

import {ConnectFinancials} from 'src/components/portal/ConnectFinancials'
import {CenterModal} from 'src/framework/ui/CenterModal'
import {MaterialSymbolIcon} from 'src/framework/ui/MaterialSymbolIcon'
import {useToggle} from 'src/hooks/util/useToggle'
import {PartnerDeal} from 'src/types'
import {PortalState} from 'src/types/deal'
import * as Analytics from 'src/util/analytics'
import * as Hotjar from 'src/util/hotjar'

type GetPreapprovedButtonProps = {
  deal: PartnerDeal
  onSuccess: () => void
  buttonProps?: ButtonProps
  sx?: SxProps<Theme>
  label?: string
  children?: JSX.Element
  onSuccessfullyApplied?: () => void
}

export const GetPreapprovedButton = ({
  deal,
  onSuccess,
  buttonProps,
  sx,
  label = 'Get Your Purchasing Power',
  children,
  onSuccessfullyApplied = () => null,
}: GetPreapprovedButtonProps): JSX.Element => {
  const {
    connectFinancialsRefresh,
    hotjarSurveyAbandonedConnectFinancialsEnabled,
  } = useFlags()
  const theme = useTheme()

  const [isModalOpen, openModal, closeModal] = useToggle(false)

  const handleClick = React.useCallback(() => {
    Analytics.track('Start Preapproval Flow')
    openModal()
  }, [openModal])

  const handleClose = React.useCallback(() => {
    closeModal()
    if (hotjarSurveyAbandonedConnectFinancialsEnabled) {
      Hotjar.track('exitConnectFinancials')
    }
    Analytics.track('Exit Connect Financials')
  }, [closeModal, hotjarSurveyAbandonedConnectFinancialsEnabled])

  const handleConnectFinancialsSuccess = React.useCallback(() => {
    onSuccess()
    closeModal()
    onSuccessfullyApplied()
  }, [closeModal, onSuccessfullyApplied, onSuccess])

  if (deal.portalState == PortalState.PREAPPROVED) {
    return <></>
  }

  return (
    <>
      <CenterModal
        open={isModalOpen}
        onClose={handleClose}
        withPadding={false}
        size={connectFinancialsRefresh ? 'lg' : 'sm'}
      >
        <ConnectFinancials
          onSuccess={handleConnectFinancialsSuccess}
          onCancel={handleClose}
        />
      </CenterModal>
      {children ? (
        deal.portalState === PortalState.PREAPPROVAL_UNDER_REVIEW ? (
          children
        ) : (
          <Box onClick={handleClick} sx={{cursor: 'pointer'}}>
            {children}
          </Box>
        )
      ) : (
        <Button
          variant="contained"
          disabled={deal.portalState === PortalState.PREAPPROVAL_UNDER_REVIEW}
          sx={{
            '&.Mui-disabled': {
              backgroundColor: alpha(theme.palette.primary.main, 0.35),
              color: '#ffffff',
            },
            ...sx,
          }}
          onClick={handleClick}
          {...buttonProps}
        >
          {label}
        </Button>
      )}
    </>
  )
}

export const PrivacyTooltip = (): JSX.Element => (
  <Tooltip
    title="We take your data privacy and security seriously. Your financial data will only be used to underwrite your business and will not be shared with any third parties without your consent."
    componentsProps={{
      tooltip: {
        sx: {
          color: '#FFFFFF',
          backgroundColor: '#222',
          fontSize: '0.875rem',
          p: 2,
        },
      },
    }}
    arrow
  >
    <Stack direction="row" spacing={0.5}>
      <MaterialSymbolIcon color="secondary">shield_lock</MaterialSymbolIcon>
      <Typography color="secondary" sx={{textDecoration: 'underline'}}>
        Your data is protected
      </Typography>
    </Stack>
  </Tooltip>
)
