import React from 'react'

import {HelpOutlineRounded} from '@mui/icons-material'
import {Box, Tooltip, InputBaseComponentProps, TextField} from '@mui/material'
import {SxProps} from '@mui/system'

interface Props {
  id: string
  label?: string
  value?: string
  defaultValue?: string | number
  inputComponent?: React.FunctionComponent<InputBaseComponentProps>
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  required?: boolean
  multiline?: boolean
  type?: 'date'
  tooltip?: string
  disabled?: boolean
  rows?: number
  maxRows?: number
  fullWidth?: boolean
  sx?: SxProps
  size?: 'medium' | 'small'
  inputProps?: InputBaseComponentProps
  inputMode?:
    | 'text'
    | 'search'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
}

export const OutlinedTextField = ({
  id,
  label,
  value,
  defaultValue,
  inputComponent,
  onBlur,
  onChange,
  required,
  multiline,
  type,
  tooltip,
  disabled,
  rows,
  maxRows,
  fullWidth = false,
  size = 'medium',
  sx,
  inputProps,
  inputMode,
}: Props): JSX.Element => {
  return (
    <Box
      position="relative"
      overflow="visible"
      width={fullWidth ? '100%' : undefined}
    >
      {tooltip && (
        <Tooltip title={tooltip} placement="right">
          <HelpOutlineRounded
            sx={{
              position: 'absolute',
              top: '-6px',
              right: '8px',
              fontSize: '16px',
              zIndex: 100,
              backgroundColor: 'background.default',
              color: 'grey.400',
              '&:hover': {
                color: 'grey.800',
              },
            }}
          />
        </Tooltip>
      )}
      <TextField
        id={id}
        label={label}
        value={value}
        defaultValue={defaultValue}
        InputProps={inputComponent ? {inputComponent} : undefined}
        InputLabelProps={{shrink: true}}
        onBlur={onBlur}
        onChange={onChange}
        variant="outlined"
        required={required}
        multiline={multiline}
        type={type}
        disabled={disabled}
        fullWidth
        rows={rows}
        maxRows={maxRows}
        sx={sx}
        size={size}
        inputProps={inputProps}
        inputMode={inputMode}
      />
    </Box>
  )
}
