import * as React from 'react'

export type UseToggleResult = [
  isEnabled: boolean,
  enable: () => void,
  disable: () => void,
  toggle: () => void,
]

/*
 * This hook is used to manage boolean state. Its interface is a lot like
 * React.useState, but instead of a setter it returns each of the three
 * conceivable setter variations you'd ever need for boolean state --
 * enable, disable, and toggle.
 *
 * Examples:
 * const [isVisible, show, hide] = useToggle(true)
 * const [hasCompleted, complete] = useToggle(false)
 */
export const useToggle = (initialState: boolean): UseToggleResult => {
  const [isEnabled, setIsEnabled] = React.useState(initialState)
  const enable = React.useCallback(() => setIsEnabled(true), [])
  const disable = React.useCallback(() => setIsEnabled(false), [])
  const toggle = React.useCallback(
    () => setIsEnabled((wasEnabled) => !wasEnabled),
    [],
  )

  return [isEnabled, enable, disable, toggle]
}
