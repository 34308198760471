import * as React from 'react'

import {Button, Typography} from '@mui/material'
import {Stack} from '@mui/system'
import {debounce} from 'lodash'
import {useSnackbar} from 'notistack'

import {PartnerCompanyAttachmentType} from 'src/components/partners/PartnerCompanyAttachmentType'
import {ProgressButton} from 'src/framework/ui/ProgressButton'
import {
  apiPartnersAttachmentsPath,
  apiPartnersCompanyPath,
} from 'src/generated/routes'
import {useFileUpload} from 'src/hooks/request/useFileUpload'
import {useIndex} from 'src/hooks/request/useIndex'
import {useRequest} from 'src/hooks/request/useRequest'
import {useBeforeUnload} from 'src/hooks/useBeforeUnload'
import {
  PartnerAttachment,
  AttachmentTypeName,
  AttachmentReviewStatus,
} from 'src/types/attachment'
import {track} from 'src/util/analytics'

type TypedFile = {
  file: File
  attachmentType: string
}

type CompanyUpdatePayload = {
  documentsSaved?: boolean
}

type AttachmentTypeConfig = {
  attachmentType: AttachmentTypeName
  label: string
  tooltip?: string
}

interface Props {
  prompt?: string
  types?: AttachmentTypeName[]
  requiredTypes?: AttachmentTypeName[]
  reviewStatuses?: AttachmentReviewStatus[]
  cancelText?: string
  onDone: () => void
  onCancel?: () => void
  autoSave?: boolean
  saveButtonAlwaysEnabled?: boolean
  showToast?: boolean
}

const defaultRequestConfigs: Array<AttachmentTypeConfig> = [
  {
    attachmentType: AttachmentTypeName.LeaseAgreement,
    label: 'Current lease',
    tooltip:
      "Please upload a digital copy of your current lease agreement. If you don't have an existing lease, please upload your most recent lease agreement.",
  },
  {
    attachmentType: AttachmentTypeName.IncomeStatement,
    label: 'Income statements',
    tooltip:
      'Please upload a copy of your most recent business income statements',
  },
  {
    attachmentType: AttachmentTypeName.BalanceSheet,
    label: 'Balance sheets',
    tooltip: 'Please upload a copy of your most recent business balance sheets',
  },
  {
    attachmentType: AttachmentTypeName.BusinessTaxReturn,
    label: 'Business tax returns (2020-2022)',
    tooltip:
      'Please upload business tax returns for each of the last 3 years, up to and including 2022. This is most commonly an 1120S or a 1040 Schedule C.',
  },
  {
    attachmentType: AttachmentTypeName.BankStatement,
    label: 'Business bank statements (most recent 3 months)',
    tooltip:
      'Please upload business bank statements for each of the last 3 months.',
  },
  {
    attachmentType: AttachmentTypeName.IndividualTaxReturn,
    label: 'Individual tax returns (2020-2022)',
    tooltip:
      'Please upload individual tax returns (1040) for each of the last 3 years, up to and including 2022.',
  },
  {
    attachmentType: AttachmentTypeName.PersonalCreditScore,
    label: 'Personal credit score',
    tooltip:
      'Please upload a personal credit score report or estimate. Note that this is different from your business credit score.',
  },
  {
    attachmentType: AttachmentTypeName.Other,
    label: 'Supplementary materials',
    tooltip:
      'Please upload anything else that you think would be helpful in understanding the health of your business.',
  },
]

export const PartnerDocumentUploads = ({
  prompt,
  types = [],
  requiredTypes = [],
  reviewStatuses = [],
  cancelText = 'Cancel',
  onDone,
  onCancel,
  autoSave = false,
  saveButtonAlwaysEnabled = false,
  showToast = true,
}: Props): JSX.Element | null => {
  const {enqueueSnackbar} = useSnackbar()

  const [pendingFiles, setPendingFiles] = React.useState<Array<TypedFile>>([])
  useBeforeUnload(pendingFiles.length > 0)

  const {entities: attachments, fetch: refetchAttachments} =
    useIndex<PartnerAttachment>(apiPartnersAttachmentsPath(), {
      autoRequest: true,
    })

  const filteredAttachments = React.useMemo(() => {
    return attachments.filter((attachment) => {
      return (
        reviewStatuses.length == 0 ||
        reviewStatuses.includes(attachment.reviewStatus)
      )
    })
  }, [attachments, reviewStatuses])

  const {request: updateCompany} = useRequest<unknown, CompanyUpdatePayload>(
    'PUT',
    apiPartnersCompanyPath(),
  )

  const handleFileListStaged = React.useCallback(
    (fileList: FileList, attachmentType: string) => {
      Array.from(fileList).forEach((file) => {
        setPendingFiles((prevFiles) => [...prevFiles, {file, attachmentType}])
      })
    },
    [],
  )
  const handleFileRemoved = React.useCallback((file: File) => {
    setPendingFiles((prevFiles) => prevFiles.filter((x) => x.file !== file))
  }, [])

  const {uploadFile} = useFileUpload<{
    file: File
    attachmentType: string
  }>(apiPartnersAttachmentsPath())

  const [uploadInProgress, setUploadInProgress] = React.useState(false)
  const saveAll = React.useCallback(async () => {
    setUploadInProgress(true)
    return Promise.all(
      pendingFiles.map((pendingFile) =>
        uploadFile({
          file: pendingFile.file,
          attachmentType: pendingFile.attachmentType,
        }).then(() => {
          track('Upload Application Document', {
            documentType: pendingFile.attachmentType,
            location: 'portal',
          })
        }),
      ),
    ).then(() => {
      setUploadInProgress(false)
    })
  }, [pendingFiles, uploadFile])

  const uploadAndUpdateCompany = React.useCallback(async () => {
    setSaveInProgress(true)
    await saveAll()
    await updateCompany({data: {documentsSaved: true}})
    if (!autoSave && showToast) {
      enqueueSnackbar(`Documents successfully saved!`, {variant: 'success'})
    }
    await refetchAttachments()
    setPendingFiles([])
    setSaveInProgress(false)
  }, [
    saveAll,
    updateCompany,
    autoSave,
    showToast,
    refetchAttachments,
    enqueueSnackbar,
  ])

  const [saveInProgress, setSaveInProgress] = React.useState<boolean>(false)
  const handleSave = React.useMemo(
    () =>
      debounce(
        async () => {
          await uploadAndUpdateCompany()
          onDone()
        },
        3000,
        {leading: true, trailing: false},
      ),
    [onDone, uploadAndUpdateCompany],
  )

  React.useEffect(() => {
    if (autoSave && pendingFiles.length > 0) {
      handleSave()
    }
  }, [autoSave, handleSave, pendingFiles])

  const filteredRequestConfigs = React.useMemo(() => {
    return defaultRequestConfigs.filter(
      (f) => types.length == 0 || types.includes(f.attachmentType),
    )
  }, [types])

  const groupedAttachments = React.useMemo(
    () =>
      groupByAttachmentType(
        filteredAttachments,
        (x) => x,
        defaultRequestConfigs,
      ),
    [filteredAttachments],
  )

  const groupedPendingFiles = React.useMemo(
    () =>
      groupByAttachmentType(pendingFiles, (x) => x.file, defaultRequestConfigs),
    [pendingFiles],
  )

  const allRequiredTypesPresent = React.useMemo(() => {
    return requiredTypes.every(
      (requiredType) =>
        groupedAttachments[requiredType]?.length ||
        groupedPendingFiles[requiredType]?.length,
    )
  }, [groupedAttachments, groupedPendingFiles, requiredTypes])

  const showCTA = React.useMemo(
    () => onCancel || !autoSave,
    [autoSave, onCancel],
  )

  const saveButtonDisabled = React.useMemo(() => {
    if (saveButtonAlwaysEnabled) {
      return false
    }

    return (
      uploadInProgress || pendingFiles.length === 0 || !allRequiredTypesPresent
    )
  }, [
    allRequiredTypesPresent,
    pendingFiles.length,
    saveButtonAlwaysEnabled,
    uploadInProgress,
  ])

  return (
    <Stack spacing={4}>
      {prompt && (
        <Typography variant="body1" align="left">
          {prompt}
        </Typography>
      )}
      <Stack spacing={2}>
        {filteredRequestConfigs.map((config) => (
          <PartnerCompanyAttachmentType
            key={config.attachmentType}
            attachments={groupedAttachments[config.attachmentType] ?? []}
            attachmentType={config.attachmentType}
            label={config.label}
            tooltip={config.tooltip}
            pendingFiles={groupedPendingFiles[config.attachmentType] ?? []}
            onFileListStaged={(fileList: FileList) => {
              handleFileListStaged(fileList, config.attachmentType)
            }}
            onFileRemoved={handleFileRemoved}
          />
        ))}
      </Stack>
      {showCTA && (
        <Stack
          direction={{xs: 'column-reverse', sm: 'row'}}
          spacing={2}
          justifyContent="center"
        >
          {onCancel && (
            <Button
              onClick={onCancel}
              variant="outlined"
              sx={{flexBasis: '50%'}}
            >
              {cancelText}
            </Button>
          )}
          {!autoSave && (
            <ProgressButton
              onClick={handleSave}
              variant="contained"
              loading={uploadInProgress || saveInProgress}
              disabled={saveButtonDisabled}
              sx={{flexBasis: onCancel ? '50%' : '100%'}}
            >
              Save
            </ProgressButton>
          )}
        </Stack>
      )}
    </Stack>
  )
}

const groupByAttachmentType = <T extends {attachmentType: string}, R>(
  entities: Array<T>,
  callback: (x: T) => R,
  typeConfigs: Array<AttachmentTypeConfig>,
) => {
  const groups: Record<string, R[]> = Object.fromEntries(
    typeConfigs.map((x) => [x.attachmentType, []]),
  )

  entities.forEach((entity) => {
    if (!(entity.attachmentType in groups)) return
    groups[entity.attachmentType] = [
      ...groups[entity.attachmentType],
      callback(entity),
    ]
  })

  return groups
}
