import React from 'react'

import {Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material'

import {PlaidItem} from 'src/types/plaidItem'
import {formatDatetime} from 'src/util/format/formatDatetime'

type Props = {
  plaidItems: PlaidItem[]
}
export const PartnerPlaidTransactionLinks = ({
  plaidItems,
}: Props): JSX.Element => {
  return (
    <Table data-testid="accounts-table" size="small" sx={{mt: 5}}>
      <colgroup>
        <col width="20%" />
        <col width="40%" />
        <col width="40%" />
      </colgroup>
      <TableHead>
        <TableRow>
          <TableCell style={{fontWeight: 'bold'}}>Bank</TableCell>
          <TableCell style={{fontWeight: 'bold'}}>Accounts</TableCell>
          <TableCell style={{fontWeight: 'bold'}}>Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {plaidItems.map((plaidItem) => (
          <TableRow key={plaidItem.id}>
            <TableCell>{plaidItem.institutionName}</TableCell>
            <TableCell>{plaidItem.accountNames.join(', ')}</TableCell>
            <TableCell>{formatDatetime(plaidItem.createdAt)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
