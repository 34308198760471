import * as React from 'react'

import {CheckCircle} from '@mui/icons-material'
import {
  AppBar,
  Box,
  Button,
  ButtonProps,
  Grid,
  Link,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {Stack} from '@mui/system'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {uniq} from 'lodash'

import {MonochromeChip} from 'src/components/MonochromeChip'
import {
  GetPreapprovedButton,
  PrivacyTooltip,
} from 'src/components/portal/GetPreapprovedButton'
import {ChangePropertyButton} from 'src/components/portal/RecommendationFlow/ChangePropertyButton'
import {CreatingRecommendationModal} from 'src/components/portal/RecommendationFlow/CreatingRecommendationsModal'
import {Popup} from 'src/components/portal/RecommendationFlow/Popup'
import {PopupContent} from 'src/components/portal/RecommendationFlow/PopupContent'
import {
  TileStatus,
  TileConfig,
  TileType,
  Tile,
} from 'src/components/portal/RecommendationFlow/Tile'
import {
  TilePopupConfig,
  TilePopupContent,
} from 'src/components/portal/RecommendationFlow/TilePopupContent'
import {SBAApplicationButton} from 'src/components/portal/SBAApplicationButton'
import {ScheduleCallButton} from 'src/components/portal/ScheduleCallButton'
import {SANS_SERIF_FONT_FAMILY} from 'src/framework/theming/AppThemeProvider'
import {MaterialSymbolIcon} from 'src/framework/ui/MaterialSymbolIcon'
import {useToggle} from 'src/hooks/util/useToggle'
import {BudgetEstimate, CompanyOwningUser, PartnerDeal, User} from 'src/types'
import {PortalState} from 'src/types/deal'
import {track} from 'src/util/analytics'
import {formatCurrency} from 'src/util/format'
import {formatCurrencyCompact} from 'src/util/format/formatCurrencyCompact'

export const RECOMMENDATION_FLOW_BUTTON_PROPS: Partial<ButtonProps> = {
  variant: 'contained',
  size: 'large',
  sx: {width: {md: 320, xs: '100%'}, fontSize: 14, lineHeight: 'normal'},
}

export type CTAType =
  | 'addFinances'
  | 'scheduleCall'
  | 'applyForSBA'
  | 'emailUs'
  | 'changeProperty'
  | null
type Step = 'welcome' | 'ownershipRecommnedation'
type InlineCTAType = 'waitlisted' | 'sba_application_complete' | null
type AdditionalInfoType = 'sbaFinancing' | 'leaseToOwn'

type Config = {
  title: string
  description: string
  step: Step
  additionalInfoType?: AdditionalInfoType
  ctaType: CTAType
  inlineCTAType: InlineCTAType
  purchasingPowerStatus?: TileStatus
  creditScoreStatus: TileStatus
  propertyValueStatus?: TileStatus
  assetTypeStatus?: TileStatus
}

type ConfigOptions = {
  changePropertyEnabled?: boolean
}

const getConfig = (
  user: User,
  deal: PartnerDeal,
  opts: ConfigOptions = {},
): Config | undefined => {
  if (
    [PortalState.PREQUALIFIED, PortalState.ADDITIONAL_FINANCIALS].includes(
      deal.portalState,
    )
  ) {
    return {
      title: 'PRELIMINARY INSIGHTS',
      description:
        'Add your finances and receive a financial review and property ownership recommendation.',
      step: 'welcome',
      ctaType: 'addFinances',
      inlineCTAType: null,
      purchasingPowerStatus: 'notStarted',
      creditScoreStatus: 'eligible',
      propertyValueStatus: 'notStarted',
      assetTypeStatus: 'notStarted',
    }
  } else if (deal.portalState === PortalState.PREAPPROVAL_UNDER_REVIEW) {
    const userHasScheduledCall = !!user.initialConsultationEventCreatedAt

    return {
      title: 'REVIEWING YOUR OPTIONS',
      step: 'ownershipRecommnedation',
      description:
        'We’re analyzing your finances and property to provide an ownership recommendation. Watch your email for updates!',
      ctaType: userHasScheduledCall ? null : 'scheduleCall',
      inlineCTAType: null,
      purchasingPowerStatus: 'inProgress',
      creditScoreStatus: 'eligible',
      propertyValueStatus: 'inProgress',
      assetTypeStatus: 'inProgress',
    }
  } else if (deal.portalState === PortalState.LETS_TALK) {
    return {
      title: "LET'S TALK",
      step: 'ownershipRecommnedation',
      description:
        'You have a unique property/financial scenario. Schedule a call about making ownership a reality!',
      ctaType: 'scheduleCall',
      inlineCTAType: null,
      purchasingPowerStatus: 'eligible',
      creditScoreStatus: 'eligible',
      propertyValueStatus: 'ineligible',
      assetTypeStatus: 'eligible',
    }
  } else if (deal.portalState === PortalState.WAITLISTED) {
    return {
      title: 'LEASE-TO-OWN IN 2024',
      description:
        'You’re a great fit for our lease-to-own program. We’ll email you with next steps.',
      step: 'ownershipRecommnedation',
      additionalInfoType: 'leaseToOwn',
      ctaType: null,
      inlineCTAType: 'waitlisted',
      purchasingPowerStatus: 'eligible',
      creditScoreStatus: 'eligible',
      propertyValueStatus: 'eligible',
      assetTypeStatus: 'eligible',
    }
  } else if (deal.portalState === PortalState.SBA_PREAPPROVED) {
    return {
      title: 'APPLY for SBA FINANCING',
      description:
        'If you need financing within 6 months, or your property value is less than $1M, we recommend working with an SBA lender.',
      step: 'ownershipRecommnedation',
      additionalInfoType: 'sbaFinancing',
      ctaType: 'applyForSBA',
      inlineCTAType: null,
      purchasingPowerStatus: 'eligible',
      creditScoreStatus: 'eligible',
      propertyValueStatus: 'eligible',
      assetTypeStatus: 'eligible',
    }
  } else if (deal.portalState === PortalState.SBA_APPLICATION_COMPLETE) {
    return {
      title: 'SBA FINANCING IS YOUR BEST PATH to Ownership',
      description:
        'Two factors determine this– timing and property value. You may need financing in the next 6 months or your property value is less than $1M.',
      step: 'ownershipRecommnedation',
      additionalInfoType: 'sbaFinancing',
      ctaType: null,
      inlineCTAType: 'sba_application_complete',
      purchasingPowerStatus: 'eligible',
      creditScoreStatus: 'eligible',
      propertyValueStatus: 'eligible',
      assetTypeStatus: 'eligible',
    }
  } else if (deal.portalState === PortalState.PROPERTY_INELIGIBLE) {
    return {
      title: 'CONSIDER ANOTHER PROPERTY',
      description:
        'withco can’t support this property right now. Email us if you have you another property in mind.',
      step: 'ownershipRecommnedation',
      ctaType: opts.changePropertyEnabled ? 'changeProperty' : 'emailUs',
      inlineCTAType: null,
      creditScoreStatus: 'eligible',
      assetTypeStatus: 'ineligible',
    }
  } else if (deal.portalState == PortalState.UNAFFORDABLE_PROPERTY) {
    return {
      title: 'CONSIDER ANOTHER PROPERTY',
      description:
        'withco can’t support this property right now. Email us if you have you another property in mind.',
      step: 'ownershipRecommnedation',
      ctaType: opts.changePropertyEnabled ? 'changeProperty' : 'emailUs',
      inlineCTAType: null,
      creditScoreStatus: 'eligible',
      assetTypeStatus: 'eligible',
      propertyValueStatus: 'ineligible',
      purchasingPowerStatus: 'eligible',
    }
  } else if (
    deal.portalState === PortalState.LEAD ||
    deal.portalState === PortalState.DISQUALIFIED
  ) {
    return {
      title: 'Sorry!',
      description:
        "We don't think withco is the right option for you at this time.",
      step: 'ownershipRecommnedation',
      ctaType: 'emailUs',
      inlineCTAType: null,
      creditScoreStatus: 'ineligible',
    }
  }
}

interface Props {
  user: User | null
  refetchUser: () => void
  deal: PartnerDeal | null
  refetchDeal: () => void
  companyOwningUser: CompanyOwningUser | null
  budgetEstimate: BudgetEstimate | null
}

export const RecommendationFlowCard = ({
  user,
  refetchUser,
  deal,
  refetchDeal,
  companyOwningUser,
  budgetEstimate,
}: Props) => {
  const {changePropertyEnabled} = useFlags()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [popupContent, setPopupContent] = React.useState<
    TilePopupConfig | null | undefined
  >(null)
  const [completedCTATypes, setCompletedCTATypes] = React.useState<CTAType[]>(
    [],
  )

  const [
    isCreatingRecommendationsOpen,
    openCreatingRecommendations,
    closeCreatingRecommendations,
  ] = useToggle(false)

  const config: Config | null | undefined = React.useMemo(
    () => deal && user && getConfig(user, deal, {changePropertyEnabled}),
    [user, deal, changePropertyEnabled],
  )

  const assetType: AssetType | undefined = React.useMemo(() => {
    if (deal?.residential) {
      return 'residential'
    }

    if (deal?.multiUnit) {
      return 'multiUnit'
    }
  }, [deal])

  const preapprovedBudget = React.useMemo(() => {
    if (config?.purchasingPowerStatus !== 'ineligible') {
      return budgetEstimate?.preapprovedBudget
    }
  }, [budgetEstimate, config])

  const handleCTACompleted = React.useCallback(
    (ctaType: CTAType) => {
      setCompletedCTATypes((prev) => uniq([...prev, ctaType]))

      switch (ctaType) {
        case 'addFinances':
          openCreatingRecommendations()
        case 'scheduleCall':
          refetchUser()
        case 'applyForSBA':
          refetchDeal()
        case 'emailUs':
          track('Email Us Clicked', {
            portalState: deal?.portalState,
          })
        case 'changeProperty':
          refetchDeal()
          openCreatingRecommendations()
      }

      window.scrollTo({top: 0, behavior: 'auto'})
    },
    [deal?.portalState, openCreatingRecommendations, refetchDeal, refetchUser],
  )

  const cta = React.useMemo(() => {
    switch (config?.ctaType) {
      case 'addFinances':
        if (!deal) return null

        return (
          <Stack
            direction="column"
            spacing={1}
            alignItems={{xs: 'center', sm: 'flex-start'}}
          >
            <GetPreapprovedButton
              deal={deal}
              label="Add your finances"
              sx={RECOMMENDATION_FLOW_BUTTON_PROPS.sx}
              buttonProps={RECOMMENDATION_FLOW_BUTTON_PROPS}
              onSuccess={refetchDeal}
              onSuccessfullyApplied={() => handleCTACompleted('addFinances')}
            />
            <PrivacyTooltip />
          </Stack>
        )
        break
      case 'scheduleCall':
        return (
          companyOwningUser &&
          user && (
            <ScheduleCallButton
              label="Schedule a call"
              companyOwningUser={companyOwningUser}
              user={user}
              onCallScheduled={() => handleCTACompleted('scheduleCall')}
              {...RECOMMENDATION_FLOW_BUTTON_PROPS}
            />
          )
        )
        break
      case 'applyForSBA':
        return (
          <SBAApplicationButton
            onWizardClose={() => handleCTACompleted('applyForSBA')}
            label="Apply for SBA financing"
            {...RECOMMENDATION_FLOW_BUTTON_PROPS}
          />
        )
        break
      case 'emailUs':
        return (
          <Button
            component={Link}
            href="mailto:join@with.co"
            sx={RECOMMENDATION_FLOW_BUTTON_PROPS.sx}
            variant={RECOMMENDATION_FLOW_BUTTON_PROPS.variant}
            size={RECOMMENDATION_FLOW_BUTTON_PROPS.size}
            onClick={() => handleCTACompleted('emailUs')}
          >
            Email us
          </Button>
        )
        break
      case 'changeProperty':
        if (!deal) return null

        return (
          <ChangePropertyButton
            {...RECOMMENDATION_FLOW_BUTTON_PROPS}
            onSuccess={() => handleCTACompleted('changeProperty')}
            deal={deal}
          />
        )
      default:
        return null
    }
  }, [
    companyOwningUser,
    config?.ctaType,
    deal,
    handleCTACompleted,
    refetchDeal,
    user,
  ])

  const inlineCTA = React.useMemo(() => {
    switch (config?.inlineCTAType) {
      case 'sba_application_complete':
        return (
          <Paper
            sx={{
              width: {xs: '100%', sm: '60%'},
              px: 2.5,
              backgroundColor: 'success.light',
              border: 'none',
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center" py={1.5}>
              <CheckCircle sx={{color: 'success.main'}} />
              <Typography
                textTransform="uppercase"
                variant="h5"
                color="success.main"
              >
                SBA APPLICATION COMPLETE
              </Typography>
            </Stack>
          </Paper>
        )
        break
      default:
        return null
    }
  }, [config?.inlineCTAType])

  const tileConfigs: TileConfig[] = React.useMemo(
    () => [
      {
        tileType: 'purchasingPower',
        tileStatus: config?.purchasingPowerStatus,
        label: 'Purchasing Power',
        blur: !(
          (budgetEstimate?.budgetLow && budgetEstimate?.budgetHigh) ||
          (preapprovedBudget && deal?.hasRecommendation)
        ),
        content: (
          <ValueRange
            low={budgetEstimate?.budgetLow}
            high={budgetEstimate?.budgetHigh}
            actual={preapprovedBudget}
          />
        ),
      },
      {
        tileType: 'creditWorthiness',
        tileStatus: config?.creditScoreStatus,
        label: 'Credit Worthiness',
        content: (
          <MaterialSymbolIcon
            weight={700}
            color="primary"
            sx={{fontSize: '55px'}}
          >
            savings
          </MaterialSymbolIcon>
        ),
      },
      {
        tileType: 'propertyValuation',
        tileStatus: config?.propertyValueStatus,
        label: 'Property Value',
        blur: !(
          deal?.propertyValuation &&
          (config?.propertyValueStatus === 'eligible' ||
            config?.propertyValueStatus === 'ineligible')
        ),
        content: (
          <ValueRange
            actual={
              config?.propertyValueStatus === 'eligible' ||
              config?.propertyValueStatus === 'ineligible'
                ? deal?.propertyValuation
                : null
            }
          />
        ),
      },
      {
        tileType: 'propertyType',
        tileStatus: config?.assetTypeStatus,
        label: 'Property Viability',
        blur: !(
          config?.assetTypeStatus &&
          ['eligible', 'ineligible'].includes(config.assetTypeStatus)
        ),
        content: <AssetTypeIcon assetType={assetType} />,
      },
    ],
    [config, budgetEstimate, assetType, deal, preapprovedBudget],
  )

  const handleTileClick = React.useCallback(
    (tileType: TileType) => {
      const tileConfig = tileConfigs.find((tc) => tc.tileType === tileType)
      if (tileConfig) {
        const popupConfig = getTilePopupConfig(
          tileConfig.tileType,
          tileConfig.tileStatus,
          deal?.portalState,
          {
            preApprovedBudget: preapprovedBudget,
            residential: deal?.residential,
            multiUnit: deal?.multiUnit,
          },
        )

        track('Recommendation Tile Clicked', {
          tileType: tileType,
          tileStatus: tileConfig.tileStatus,
          portalState: deal?.portalState,
        })

        setPopupContent(popupConfig)
      }
    },
    [tileConfigs, deal, preapprovedBudget],
  )

  const handleAdditionalInfoClick = React.useCallback(() => {
    if (config?.additionalInfoType) {
      const popupConfig = getInformationalPopupConfig(config.additionalInfoType)
      setPopupContent(popupConfig)
    }
  }, [config])

  if (!config) return null

  return (
    <>
      <Paper sx={{p: {xs: 2.5, sm: 4}}}>
        <Grid container spacing={{xs: 3.5, sm: 4}}>
          <Grid item sm={12} md={6}>
            <Stack
              direction="column"
              height="100%"
              spacing={5}
              justifyContent="center"
            >
              <Box>
                <MonochromeChip
                  label={
                    config?.step === 'ownershipRecommnedation'
                      ? 'Ownership Recommendation'
                      : 'Welcome!'
                  }
                  textColor={theme.palette.primary.main}
                  size="small"
                />
                <Typography
                  sx={{
                    fontSize: '1.375rem',
                    lineHeight: '100%',
                    fontFamily: SANS_SERIF_FONT_FAMILY,
                    fontWeight: 800,
                    textTransform: 'uppercase',
                  }}
                  mt={1}
                  mb={1}
                >
                  {config?.title}
                </Typography>
                <Typography variant="body1">
                  {`${config?.description} `}
                  {config?.additionalInfoType && (
                    <Typography
                      component="span"
                      color="secondary"
                      onClick={handleAdditionalInfoClick}
                      sx={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        display: 'inline',
                      }}
                    >
                      Learn more.
                    </Typography>
                  )}
                </Typography>

                {inlineCTA && <Box mt={2.5}>{inlineCTA}</Box>}
              </Box>
              {!isMobile && cta}
            </Stack>
          </Grid>
          <Grid item sm={12} md={6}>
            <Grid container columnSpacing={2} rowSpacing={3.5}>
              {tileConfigs.map((tileConfig) => (
                <Grid item xs={6} key={`tile-${tileConfig.tileType}`}>
                  <Tile config={tileConfig} onClick={handleTileClick} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {isMobile && cta && (
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
            borderTop: '1px solid #E0E1E8',
            p: 2,
            left: '0',
            bottom: '0',
            top: 'auto',
          }}
        >
          {cta}
        </AppBar>
      )}
      <Popup onClose={() => setPopupContent(null)} open={!!popupContent}>
        {popupContent && (
          <PopupContent
            title={popupContent.title}
            onDone={() => setPopupContent(null)}
          >
            <TilePopupContent
              config={popupContent}
              onDone={() => setPopupContent(null)}
            />
          </PopupContent>
        )}
      </Popup>
      <CreatingRecommendationModal
        open={isCreatingRecommendationsOpen}
        onClose={closeCreatingRecommendations}
        completedCTATypes={completedCTATypes}
      />
    </>
  )
}

interface ValueRangeProps {
  low?: number | null
  high?: number | null
  actual?: number | null
}
const ValueRange = ({low, high, actual}: ValueRangeProps) => {
  const values = React.useMemo(() => {
    if (actual) {
      return [formatCurrencyCompact(actual)]
    }

    if (low && high) {
      return [`${formatCurrencyCompact(low)} - `, formatCurrencyCompact(high)]
    }

    return ['$X.YZ']
  }, [actual, high, low])

  return (
    <Box>
      {values.map((value, index) => (
        <Typography
          key={index}
          color="primary"
          fontFamily={SANS_SERIF_FONT_FAMILY}
          fontWeight={800}
          fontSize={values.length > 1 ? '1.5rem' : '2.5rem'}
        >
          {value}
        </Typography>
      ))}
    </Box>
  )
}

type AssetType = 'residential' | 'multiUnit'
interface AssetTypeIconProps {
  assetType?: AssetType
}
const AssetTypeIcon = ({assetType}: AssetTypeIconProps): JSX.Element | null => {
  const iconString = React.useMemo(() => {
    if (assetType === 'residential') {
      return 'home'
    }
    if (assetType === 'multiUnit') {
      return 'home_work'
    }

    return 'store'
  }, [assetType])

  return (
    <MaterialSymbolIcon weight={700} color="primary" sx={{fontSize: '55px'}}>
      {iconString}
    </MaterialSymbolIcon>
  )
}

const reCharacteristicString = (
  residential?: boolean | null,
  multiUnit?: boolean | null,
) => {
  const characteristics = []
  if (residential) {
    characteristics.push('residential')
  }

  if (multiUnit) {
    characteristics.push('multi-tenant')
  }

  return characteristics.join(' and ')
}

type PopupData = {
  preApprovedBudget?: number | null
  residential?: boolean | null
  multiUnit?: boolean | null
}

const getTilePopupConfig = (
  type: TileType,
  status?: TileStatus,
  portalState?: PortalState,
  data: Partial<PopupData> = {},
): TilePopupConfig | undefined => {
  if (type === 'purchasingPower') {
    const DESCRIPTIONS = new Map<TileStatus, string[]>([
      [
        'notStarted',
        [
          'This is an estimated range of how much money you can afford to spend on a commercial property. Add your finances to view your actual purchasing power.',
        ],
      ],
      [
        'inProgress',
        [
          'This is an estimate. A real estate expert is finalizing your purchasing budget.',
        ],
      ],
      [
        'eligible',
        [
          `Based on your financials, you have a ${formatCurrency(
            data.preApprovedBudget?.toString() ?? null,
            {
              precision: 0,
            },
          )} budget to purchase a commercial property.`,
        ],
      ],
    ])

    return {
      title: 'Purchasing Power',
      status: status,
      description: status && DESCRIPTIONS.get(status),
      explainerSubtitle: 'What is Purchasing Power?',
      explainerDescription: [
        'Purchasing power is how much you can afford to pay for a property.',
        'withco uses this value and compares it with your desired property to determine whether you qualify for financing.',
      ],
    }
  } else if (type === 'creditWorthiness') {
    const DESCRIPTIONS = new Map<TileStatus, string[]>([
      [
        'ineligible',
        [
          'Unfortunately, your property and/or finances are not a good fit for withco at this time.',
        ],
      ],
    ])
    return {
      title: 'Credit Worthiness',
      status: status,
      description: status && DESCRIPTIONS.get(status),
      explainerSubtitle: "What's Credit Worthiness?",
      explainerDescription: [
        'Credit worthiness is evaluated by reviewing your monthly revenue, years of operation, and credit score.',
      ],
    }
  } else if (type === 'propertyValuation') {
    const DESCRIPTIONS = new Map<TileStatus, string[]>([
      [
        'notStarted',
        [
          'Add your finances and a member of our investments team will determine the value of your property and if you should own it.',
        ],
      ],
      [
        'inProgress',
        ['A real estate expert is working on assessing your property value.'],
      ],
      [
        'eligible',
        ["The property you're looking to own is within your purchasing power."],
      ],
      [
        'ineligible',
        [
          'This property’s value is higher than what you can afford (your purchasing power).',
        ],
      ],
    ])

    return {
      title: 'Property Value',
      status: status,
      description: status && DESCRIPTIONS.get(status),
      explainerSubtitle: "What's Property Value?",
      explainerDescription: [
        'Property value is calculated based on the square footage of the property and transactions of similar properties nearby.',
      ],
    }
  } else if (type === 'propertyType') {
    const DESCRIPTIONS = new Map<TileStatus, string[]>([
      [
        'inProgress',
        ['A real estate expert is checking your property viability.'],
      ],
      [
        'eligible',
        portalState === PortalState.SBA_PREAPPROVED ||
        portalState === PortalState.SBA_APPLICATION_COMPLETE
          ? [
              "Retail properties are eligible for financing through withco's partners.",
            ]
          : [],
      ],
      [
        'ineligible',
        [
          `This is a ${reCharacteristicString(
            data.residential,
            data.multiUnit,
          )} property which withco does not currently support.`,
        ],
      ],
    ])

    return {
      title: 'Property Viability',
      status: status,
      description: status && DESCRIPTIONS.get(status),
      explainerSubtitle: "What's Property Viability?",
      explainerDescription: [
        'Property viability helps us determine whether your property is eligible for our financing options.',
        'Currently, we only support single-tenant, commercial properties. Multi-tenant and residential properties are not supported at this time.',
      ],
    }
  }
}

const getInformationalPopupConfig = (
  lookup: AdditionalInfoType,
): TilePopupConfig | undefined => {
  if (lookup === 'sbaFinancing') {
    return {
      title: 'SBA Financing',
      explainerDescription: [
        'The U.S. Small Business Administration (SBA) is a federal government agency dedicated to assisting and supporting small businesses in the United States. They set loan guidelines and reducing lender risk through specific financing programs such as the 7(a) program.',
        'withco has partnered with the largest 7(a) lenders in the country to streamline the application process for qualified businesses.',
        <Link
          key="sba"
          href="https://www.sba.gov/funding-programs/loans/7a-loans"
          sx={{color: 'text.primary', textDecorationColor: '#000000'}}
        >
          Read more about the SBA 7(a) loan program here.
        </Link>,
      ],
    }
  }

  if (lookup === 'leaseToOwn') {
    return {
      title: 'Lease-to-Own',
      explainerDescription: [
        'withco works with you to agree upon rent terms and a purchase price. From there, withco purchases your property and you sign a 10 year lease with us. This lease will include a year 5 purchase option at a pre-agreed price.',
        'If you choose to exercise this option, we’ll help you secure a mortgage through the SBA, and contribute a 10% down payment credit towards your new mortgage. This allows you to become a property owner in 5 years and save the money you would’ve used for a down payment.',
      ],
    }
  }
}
