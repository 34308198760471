import React from 'react'
import {useCalendlyEventListener, PopupModal} from 'react-calendly'

import {User} from '@sentry/react'

import {apiPartnersInitialConsultationPath} from 'src/generated/routes'
import {useRequest} from 'src/hooks/request/useRequest'
import {CompanyOwningUser} from 'src/types'
import {track} from 'src/util/analytics'

interface CalendlyProps {
  open: boolean
  onClose: () => void
  companyOwningUser: CompanyOwningUser
  user: User
  onScheduledCall: () => void
  pageLocation: string
}

export const CalendlyModal = ({
  open,
  onClose,
  companyOwningUser,
  user,
  onScheduledCall,
  pageLocation,
}: CalendlyProps) => {
  const elementRef = React.useRef<HTMLInputElement>(null)

  const {request: initialConsultation} = useRequest<
    void,
    {eventApiUrl: string}
  >('POST', apiPartnersInitialConsultationPath())

  useCalendlyEventListener({
    onDateAndTimeSelected: () => {
      track('Select Consultation Date/Time via Calendly', {
        location: pageLocation,
      })
    },
    onEventScheduled: async (event) => {
      track('Complete Scheduling via Calendly', {
        location: pageLocation,
      })
      await initialConsultation({
        data: {eventApiUrl: event.data.payload.event.uri},
      })
      onScheduledCall()
    },
  })

  React.useEffect(() => {
    if (open) {
      track('Start Scheduling via Calendly', {
        location: pageLocation,
      })
    }
  }, [open, pageLocation])

  return (
    <>
      <div ref={elementRef} />

      {elementRef.current && companyOwningUser?.calendlyUrl && (
        <PopupModal
          url={companyOwningUser?.calendlyUrl}
          onModalClose={onClose}
          open={open}
          rootElement={elementRef.current}
          prefill={{
            email: user.email,
            name: user.name || undefined,
          }}
        />
      )}
    </>
  )
}
