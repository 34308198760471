import * as React from 'react'

import {Box, Paper, Stack, Typography} from '@mui/material'

import {PlaidButton} from 'src/components/deal/PlaidButton'
import {PartnerPlaidTransactionLinks} from 'src/components/partners/PartnerPlaidTransactionLinks'
import {apiPartnersPlaidItemsPath} from 'src/generated/routes'
import {useTrackPage} from 'src/hooks/analytics/useTrackPage'
import {useCurrentUser} from 'src/hooks/request/useCurrentUser'
import {useIndex} from 'src/hooks/request/useIndex'
import {PlaidItem} from 'src/types'
import {ReactComponent as PlaidLogo} from 'svg/plaid_logo.svg'

export const PartnerFinancials = (): JSX.Element | null => {
  useTrackPage({pageName: 'connectPlaid'})
  const [plaidItems, setPlaidItems] = React.useState<PlaidItem[]>([])

  const {user} = useCurrentUser()

  const {fetch: refetchPlaidItems, completed} = useIndex<PlaidItem>(
    apiPartnersPlaidItemsPath(),
    {
      autoRequest: true,
      onSuccess: ({entities: plaidItems}) => setPlaidItems(plaidItems),
    },
  )

  if (!user || !user.companyId || !completed) return null

  return (
    <>
      <Stack spacing={4} width="100%" pb={6}>
        {plaidItems.length > 0 ? <PostSyncHeader /> : <PreSyncHeader />}
        <Paper
          sx={{
            py: {xs: 3, sm: 4},
            px: {xs: 2, sm: 4},
            my: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <PlaidLogo width="378px" height="144px" />
          <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
            <PlaidButton onSuccessCallback={refetchPlaidItems} />
            <PlaidButton
              onSuccessCallback={refetchPlaidItems}
              buttonText="Connect personal bank account"
            />
          </Stack>
          {plaidItems.length > 0 ? (
            <PartnerPlaidTransactionLinks plaidItems={plaidItems} />
          ) : null}
        </Paper>
      </Stack>
    </>
  )
}

const PreSyncHeader = () => (
  <Box>
    <Typography variant="h2" mb={2}>
      {'We use Plaid to get instant, read-only access to bank financials.'}
    </Typography>
    <Typography variant="body1">
      {'Requires online banking credentials.'}
    </Typography>
  </Box>
)

const PostSyncHeader = () => (
  <Box>
    <Typography variant="h2" mb={2}>
      {"You're connected!"}
    </Typography>
    <Stack spacing={2} alignItems="center">
      <Typography variant="body1" component="span">
        {
          'If you have other bank accounts not listed below, please connect them too.'
        }
      </Typography>
    </Stack>
  </Box>
)
