import * as React from 'react'

import {Button, ButtonProps} from '@mui/material'
import {SxProps} from '@mui/system'

import {ProgressBox} from 'src/framework/ui/ProgressBox'

export interface Props {
  onClick: () => void
  loading: boolean
  disabled?: boolean
  children: React.ReactNode
  sx?: SxProps
  fullWidth?: boolean
  variant?: 'text' | 'contained' | 'outlined'
  startIcon?: React.ReactNode
  buttonProps?: ButtonProps
}

export const ProgressButton = ({
  onClick,
  loading,
  disabled,
  children,
  fullWidth = true,
  variant = 'contained',
  sx,
  startIcon,
  buttonProps,
}: Props): JSX.Element => {
  return (
    <ProgressBox loading={loading} indicatorSize={24} sx={sx}>
      <Button
        variant={variant}
        color="primary"
        disabled={loading || disabled}
        onClick={onClick}
        fullWidth={fullWidth}
        startIcon={startIcon}
        {...buttonProps}
      >
        {children}
      </Button>
    </ProgressBox>
  )
}
