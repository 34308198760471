import * as React from 'react'

import {Chip, alpha} from '@mui/material'

interface Props extends React.ComponentProps<typeof Chip> {
  textColor: string
}
export const MonochromeChip = ({
  textColor,
  sx,
  ...chipProps
}: Props): JSX.Element => {
  const monochromeSx = Object.assign({}, sx, {
    color: textColor,
    backgroundColor: alpha(textColor, 0.12),
  })

  return <Chip {...chipProps} sx={monochromeSx} />
}
