import {JsonApiResponse} from 'src/types/jsonApi'
import {User} from 'src/types/user'

export type Attachment = {
  contentType: string
  id: string
  name: string
  type: AttachmentTypeName
  attachmentType: AttachmentTypeName
  displayType: string
  createdAt: string
  user: JsonApiResponse<User>
  reviewStatus: AttachmentReviewStatus
  externallyVisible: boolean
  uploaderInternal: boolean
  broadLevelGrouping: AttachmentBroadLevelGroupingName
}

export enum AttachmentBroadLevelGroupingName {
  FinancialDocuments = 'Financial documents',
  ClosingDocuments = 'Closing documents',
  Contracts = 'Contracts',
  MISC = 'Miscellaneous',
}

export type PartnerAttachment = {
  id: string
  name: string
  attachmentType: string
  createdAt: string
  reviewStatus: AttachmentReviewStatus
  broadLevelGrouping: AttachmentBroadLevelGroupingName
}

export enum AttachmentReviewStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum AttachmentTypeName {
  ALTASurvey = 'alta_survey',
  BalanceSheet = 'balance_sheet',
  BankStatement = 'bank_statement',
  BusinessDebtSchedule = 'business_debt_schedule',
  BusinessLoanDocuments = 'business_loan_documents',
  BusinessTaxReturn = 'business_tax_return',
  CapitalizationTable = 'capitalization_table',
  ClosingStatement = 'closing_statement',
  EMDReceipt = 'emd_receipt',
  FinalTitlePolicy = 'final_title_policy',
  GeneralLiabilityPolicy = 'general_liability_policy',
  IncomeStatement = 'income_statement',
  IndividualTaxReturn = 'individual_tax_return',
  LeaseAgreement = 'lease_agreement',
  LeaseCheckupDeck = 'lease_checkup_deck',
  LetterOfIntent = 'letter_of_intent_loi',
  OfferingMemorandum = 'offering_memorandum_om',
  Other = 'other',
  PartnerLeaseAgreement = 'partner_lease_agreement_pla',
  PersonalCreditScore = 'personal_credit_score',
  PersonalFinancialStatement = 'personal_financial_statement',
  PhaseIESA = 'phase_i_esa',
  ProfitAndLossStatement = 'profit_and_loss_statement_p_l',
  PropertyConditionReport = 'property_condition_report_pcr',
  PropertyInsurance = 'property_insurance',
  PropertyInsuranceQuote = 'property_insurance_quote',
  PropertyPriceAssessment = 'property_price_assessment_deck',
  PropertyPriceAssessmentLight = 'property_price_assessment_light_deck',
  PropertyTaxStatement = 'property_tax_statement',
  PurchaseAndSaleAgreement = 'purchase_and_sale_agreement_psa',
  PurchasingEntityCreation = 'purchasing_entity_creation',
  Survey = 'survey',
  TermSheet = 'term_sheet',
  TitleCommitment = 'title_commitment',
  WireInstructions = 'wire_instructions',
  WithcoBudget = 'withco_budget_letter',
  WithcoMaterials = 'withco_materials',
  ZoningLetter = 'zoning_letter',
}

export type AttachmentType = {
  name: AttachmentTypeName
  displayName: string
  id: string
}
