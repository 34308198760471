import * as React from 'react'

import {ExpandMore, Forum} from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'

const faqItems = [
  {
    summary: 'How does withco make money?',
    details: `We built lease-to-own to be a win-win solution for small businesses on their paths to property ownership. We invest our capital to purchase your building, earn a return through rent payments, and profit upon the sale of the building to you.`,
  },
  {
    summary: 'How do you determine my rent?',
    details: `We need you to be successful in your space to make our business work. We research your market's rent and compare that with your business's ability to pay rents throughout our 5 - 10 year lease term. You'll have full transparency on future rent payments before we finalize any agreement.`,
  },
  {
    summary: 'How do you determine my year 5 buyback price?',
    details: `We set your year 5 buyback price upfront so you know exactly what you can buy the property back for in the future. We research the market price of the building today, expected increases in price over our 5 - 10 year lease term, and your business's ability to support a mortgage at buyback.`,
  },
  {
    summary:
      'What if I want to buy my property before or after the 5 year mark?',
    details: `Our typical term is 10 years with a buyback option in year 5. If you elect to not buyback the property, the lease converts to the greater of a standard market lease or our lease with agreed upon rent escalations. Our goal is for you to own the building, but we've built in flexibility for you to at least have the security of a long-term lease. Case-by-case, we've considered early exercises.`,
  },
]

export const PortalFAQ = (): JSX.Element => {
  const theme = useTheme()
  return (
    <Stack direction="column" alignItems="center" spacing={3}>
      <Typography variant="h2">Frequently Asked Questions</Typography>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography color="text.primary" variant="h6">
          Got Questions?
        </Typography>
        <Button
          variant="contained"
          className="open-intercom"
          startIcon={<Forum />}
        >
          Chat with us
        </Button>
      </Stack>
      <Stack direction="column" spacing={0}>
        {faqItems.map((item, index) => (
          <Accordion
            key={index}
            disableGutters={true}
            sx={{
              border: `1px solid ${theme.palette.divider}`,
              '&:not(:last-child)': {
                borderBottom: 0,
              },
              '&:before': {
                display: 'none',
              },
            }}
          >
            <AccordionSummary
              sx={{
                px: 3,
                minHeight: 0,
                py: 2,
                '.MuiAccordionSummary-content': {margin: 0},
              }}
              expandIcon={
                <ExpandMore
                  color="primary"
                  fontSize="medium"
                  sx={{strokeWidth: 1.25}}
                />
              }
            >
              <Typography color="text.primary" variant="h6" textAlign="left">
                {item.summary}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{pl: 3, pr: 8}}>
              <Typography textAlign="left">{item.details}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </Stack>
  )
}
