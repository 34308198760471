import * as React from 'react'

import {Typography} from '@mui/material'
import {Stack} from '@mui/system'
import {debounce} from 'lodash'
import {useSnackbar} from 'notistack'

import {PartnerCompanyAttachmentType} from 'src/components/partners/PartnerCompanyAttachmentType'
import {ProgressButton} from 'src/framework/ui/ProgressButton'
import {
  apiPartnersAttachmentsPath,
  apiPartnersCompanyPath,
} from 'src/generated/routes'
import {useFileUpload} from 'src/hooks/request/useFileUpload'
import {useIndex} from 'src/hooks/request/useIndex'
import {useRequest} from 'src/hooks/request/useRequest'
import {useBeforeUnload} from 'src/hooks/useBeforeUnload'
import {PartnerAttachment, AttachmentTypeName} from 'src/types/attachment'

type TypedFile = {
  file: File
  attachmentType: string
}

type CompanyUpdatePayload = {
  documentsSaved?: boolean
  documentsSubmitted?: boolean
}

type AttachmentTypeConfig = {
  attachmentType: AttachmentTypeName
  label: string
  tooltip?: string
}

const typeConfigs: Array<AttachmentTypeConfig> = [
  {
    attachmentType: AttachmentTypeName.LeaseAgreement,
    label: 'Business leases',
    tooltip: 'Please provide executed business leases.',
  },
  {
    attachmentType: AttachmentTypeName.BusinessLoanDocuments,
    label: 'Business loan documents',
    tooltip:
      'Please provide executed loan documents for all outstanding business debt, including promissory notes, loan agreements, and any other loan documents.',
  },
  {
    attachmentType: AttachmentTypeName.BusinessTaxReturn,
    label: 'Business tax returns (2021-2023)',
    tooltip:
      'Please provide business tax returns for each of the last 3 years, up to and including 2023 if you have it. This is most commonly an 1120S or a 1040 Schedule C.',
  },
  {
    attachmentType: AttachmentTypeName.IndividualTaxReturn,
    label: 'Personal tax returns (2021-2023)',
    tooltip:
      'Please provide individual tax returns (1040) for each of the last 3 years, up to and including 2023 if you have it.',
  },
  {
    attachmentType: AttachmentTypeName.PersonalCreditScore,
    label: 'Experian FICO score',
    tooltip:
      'Please provide an Experian FICO credit score report, or equivalent.',
  },
  {
    attachmentType: AttachmentTypeName.Other,
    label: 'Other',
    tooltip:
      'Please provide anything else that you think would be helpful in understanding the health of your business.',
  },
]

export const PartnerDocumentUploadsSba = (): JSX.Element | null => {
  const {enqueueSnackbar} = useSnackbar()

  const [pendingFiles, setPendingFiles] = React.useState<Array<TypedFile>>([])
  useBeforeUnload(pendingFiles.length > 0)

  const {entities: attachments, fetch: refetchAttachments} =
    useIndex<PartnerAttachment>(apiPartnersAttachmentsPath(), {
      autoRequest: true,
    })

  const {request: updateCompany} = useRequest<unknown, CompanyUpdatePayload>(
    'PUT',
    apiPartnersCompanyPath(),
  )

  const handleFileListStaged = React.useCallback(
    (fileList: FileList, attachmentType: string) => {
      Array.from(fileList).forEach((file) => {
        setPendingFiles((prevFiles) => [...prevFiles, {file, attachmentType}])
      })
    },
    [],
  )
  const handleFileRemoved = React.useCallback((file: File) => {
    setPendingFiles((prevFiles) => prevFiles.filter((x) => x.file !== file))
  }, [])
  const [isUploading, setIsUploading] = React.useState(false)

  const {uploadFile} = useFileUpload<{
    file: File
    attachmentType: string
  }>(apiPartnersAttachmentsPath())

  const saveAll = React.useCallback(async () => {
    setIsUploading(true)
    return Promise.all(
      pendingFiles.map((pendingFile) =>
        uploadFile({
          file: pendingFile.file,
          attachmentType: pendingFile.attachmentType,
        }),
      ),
    ).then(() => {
      setIsUploading(false)
    })
  }, [pendingFiles, uploadFile])

  const uploadAndUpdateCompany = React.useCallback(
    async (data: CompanyUpdatePayload) => {
      await saveAll()
      await updateCompany({data})
      enqueueSnackbar(`Documents successfully saved!`, {variant: 'success'})
      await refetchAttachments()
      setPendingFiles([])
    },
    [saveAll, refetchAttachments, enqueueSnackbar, updateCompany],
  )

  const handleSave = React.useMemo(
    () =>
      debounce(
        async () => {
          await uploadAndUpdateCompany({documentsSaved: true})
        },
        3000,
        {leading: true, trailing: false},
      ),
    [uploadAndUpdateCompany],
  )

  const groupedAttachments = React.useMemo(
    () => groupByAttachmentType(attachments, (x) => x, typeConfigs),
    [attachments],
  )

  const groupedPendingFiles = React.useMemo(
    () => groupByAttachmentType(pendingFiles, (x) => x.file, typeConfigs),
    [pendingFiles],
  )
  return (
    <Stack spacing={4} px={1}>
      <Stack spacing={2} px={{xs: 1, sm: 2}} py={1}>
        <Typography variant="body1" align="left">
          {`Please upload each of the following by clicking "Upload" or drag-and-drop to a section below.`}
        </Typography>
        <Typography variant="body1" align="left">
          <strong>NOTE: You can upload multiple files in each section.</strong>
        </Typography>
        <Typography variant="body1" align="left">
          {`Click "Save" when you're done.`}
        </Typography>
      </Stack>
      <Stack spacing={2}>
        {typeConfigs.map((config) => (
          <PartnerCompanyAttachmentType
            key={config.attachmentType}
            attachments={groupedAttachments[config.attachmentType] ?? []}
            attachmentType={config.attachmentType}
            label={config.label}
            tooltip={config.tooltip}
            pendingFiles={groupedPendingFiles[config.attachmentType] ?? []}
            onFileListStaged={(fileList: FileList) => {
              handleFileListStaged(fileList, config.attachmentType)
            }}
            onFileRemoved={handleFileRemoved}
          />
        ))}
      </Stack>
      <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
        <ProgressButton
          onClick={handleSave}
          variant="outlined"
          loading={isUploading}
          disabled={isUploading || pendingFiles.length === 0}
          sx={{minWidth: 120}}
        >
          Save
        </ProgressButton>
      </Stack>
    </Stack>
  )
}

const groupByAttachmentType = <T extends {attachmentType: string}, R>(
  entities: Array<T>,
  callback: (x: T) => R,
  typeConfigs: Array<AttachmentTypeConfig>,
) => {
  const groups: Record<string, R[]> = Object.fromEntries(
    typeConfigs.map((x) => [x.attachmentType, []]),
  )

  entities.forEach((entity) => {
    if (!(entity.attachmentType in groups)) return
    groups[entity.attachmentType] = [
      ...groups[entity.attachmentType],
      callback(entity),
    ]
  })

  return groups
}
