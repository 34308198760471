import * as React from 'react'

import {Stack, Typography} from '@mui/material'

import {WizardStepProps} from 'src/components/portal/SBAMicroflow/SBAApplicationWizard'

type Props = WizardStepProps

export const Thanks = ({onReadyToAdvance}: Props): JSX.Element => {
  React.useEffect(() => onReadyToAdvance(), [onReadyToAdvance])
  return (
    <Stack spacing={6} alignItems="center">
      <Stack spacing={3} alignItems="center">
        <Typography variant="h6" textAlign="center">
          Thanks for providing documents. We’ll pass your information along to
          our SBA lending partner and make an introduction shortly.
        </Typography>
      </Stack>
    </Stack>
  )
}
