import * as React from 'react'

import {Close} from '@mui/icons-material'
import {Box, BoxProps, IconButton, Stack, Typography} from '@mui/material'

import {SANS_SERIF_FONT_FAMILY} from 'src/framework/theming/AppThemeProvider'

interface Props extends Omit<BoxProps, 'title' | 'display' | 'flexDirection'> {
  title: string | null
  onDone: () => void
  children: React.ReactNode
}

export const PopupContent = ({
  title,
  onDone,
  children,
  ...boxProps
}: Props): JSX.Element => {
  return (
    <Box display="flex" flexDirection="column" {...boxProps}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          sx={{
            fontSize: '1.375rem',
            lineHeight: '100%',
            fontFamily: SANS_SERIF_FONT_FAMILY,
            fontWeight: 800,
            textTransform: 'uppercase',
          }}
        >
          {title}
        </Typography>
        <Box>
          <IconButton onClick={onDone} sx={{mr: -1}}>
            <Close color="secondary" />
          </IconButton>
        </Box>
      </Stack>
      <Box flexGrow={1} sx={{overflowY: 'scroll'}}>
        {children}
      </Box>
    </Box>
  )
}
