import * as React from 'react'

import {
  DescriptionOutlined,
  ControlPointOutlined,
  SvgIconComponent,
} from '@mui/icons-material'
import {Box, Grid, Paper, Stack, SvgIcon, Typography} from '@mui/material'

import {WizardStepProps} from 'src/components/portal/SBAMicroflow/SBAApplicationWizard'

const requirements = [
  {description: '3 years', title: 'Business Tax Returns'},
  {description: '3 years', title: 'Personal Tax Returns'},
  {description: 'Personal', title: 'Credit Score Report'},
  {description: 'Business', title: 'Capitalization Table'},
  {description: 'SBA Form 413', title: 'Personal Finance Statement'},
]

type Props = WizardStepProps

export const ApplicationRequirements = ({
  onReadyToAdvance,
}: Props): JSX.Element => {
  React.useEffect(() => {
    onReadyToAdvance()
  }, [onReadyToAdvance])

  return (
    <Stack spacing={4} alignItems="center">
      <Box>
        <Grid container spacing={2}>
          {requirements.map((requirement, index) => (
            <Square key={index} {...requirement} />
          ))}
          <RequirementGridItem icon={ControlPointOutlined}>
            <Typography
              variant="h6"
              fontSize="0.75rem"
              lineHeight={1}
              textAlign="center"
            >
              Other supporting documents requested by the underwriting
              department
            </Typography>
          </RequirementGridItem>
        </Grid>
      </Box>
      <Box px={{sm: 9, xs: 3}}>
        <Typography textAlign="center" sx={{opacity: 0.8}}>
          As a part of your SBA loan application, you are going to be asked to
          pull together a lot of documents in order for a lender to underwrite
          your business and ensure that you can afford the loan’s monthly
          payments.
        </Typography>
      </Box>
    </Stack>
  )
}

type RequirementGridItemProps = {
  children: React.ReactNode
  icon: SvgIconComponent
}
const RequirementGridItem = ({children, icon}: RequirementGridItemProps) => {
  return (
    <Grid item xs={12} sm={4}>
      <Paper
        sx={{
          backgroundColor: 'background.default',
          pt: 2.25,
          pb: 4.5,
          height: 120,
        }}
      >
        <Stack spacing={0.75} alignItems="center" px={3}>
          <SvgIcon
            height="1.25rem"
            width="1.25rem"
            component={icon}
            color="primary"
          />
          {children}
        </Stack>
      </Paper>
    </Grid>
  )
}

type SquareProps = {
  description: string
  title: string
}

const Square = ({description, title}: SquareProps) => {
  return (
    <RequirementGridItem icon={DescriptionOutlined}>
      <Typography variant="h6" fontSize="0.75rem" lineHeight={1}>
        {description}
      </Typography>
      <Typography
        variant="h3"
        fontSize="0.8125rem"
        lineHeight={1}
        textAlign="center"
      >
        {title}
      </Typography>
    </RequirementGridItem>
  )
}
