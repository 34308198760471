import * as React from 'react'

import {CssBaseline, ThemeProvider, createTheme, alpha} from '@mui/material'
import {grey} from '@mui/material/colors'
import SharpSansDisplayNo1Bold from 'fonts/SharpSans-Bold.ttf'
import SharpSansDisplayNo1ExtraBold from 'fonts/SharpSansDisplayNo1-Extrabold.woff2'
import TiemposTextWoff2 from 'fonts/TiemposText-Regular.woff2'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    strong: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    strong?: React.CSSProperties
  }
  interface Palette {
    neutral: Palette['primary']
    yellow: Palette['primary']
  }
  interface PaletteColor {
    faint?: string
  }
  interface SimplePaletteColorOptions {
    faint?: string
  }
  interface PaletteOptions {
    yellow?: PaletteOptions['primary']
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    strong: true
  }
}

const WITHCO_BLUE = '#006AFF'
const WITHCO_GREY = '#7D7D7D'

export const withcoBlue = {
  background: alpha(WITHCO_BLUE, 0.03),
  backgroundHover: alpha(WITHCO_BLUE, 0.08),
  main: WITHCO_BLUE,
}

const tiemposFontFace = `
  @font-face {
    font-family: 'Tiempos Text';
    font-style: normal;
    font-display: swap;
    font-weight: normal;
    src: url('${TiemposTextWoff2}') format('woff2');
  }`

const sharpSansDisplayNo1ExtraBoldFontFace = `
  @font-face {
    font-family: "Sharp Sans Display No1";
    src: url('${SharpSansDisplayNo1ExtraBold}') format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }
`
const sharpSansDisplayNo1BoldFontFace = `
  @font-face {
    font-family: "Sharp Sans Display No1";
    src: url('${SharpSansDisplayNo1Bold}') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
`
const SANS_SERIF_FONT_FAMILY = ['Sharp Sans Display No1', 'sans-serif'].join(
  ',',
)

const theme = createTheme({
  palette: {
    text: {
      primary: grey[900],
    },
    background: {
      default: '#f8f8f8',
      paper: '#ffffff',
      blue: withcoBlue.background,
    },
    primary: {
      main: WITHCO_BLUE,
      faint: withcoBlue.background,
      contrastText: '#ffffff',
    },
    secondary: {
      light: grey[100],
      main: WITHCO_GREY,
      dark: grey[800],
    },
    yellow: {
      main: '#B88400',
      light: '#FCF7E4',
    },
    info: {
      main: grey[100],
      contrastText: '#222222',
      dark: grey[200],
    },
    success: {
      main: '#158432',
      light: '#F0FBF3',
    },
    warning: {
      main: '#EFB623',
    },
  },
  typography: {
    fontFamily: ['Tiempos Text', 'sans-serif'].join(','),
    h1: {
      fontFamily: SANS_SERIF_FONT_FAMILY,
      fontSize: '2rem',
      fontWeight: 800,
      lineHeight: 1.3,
    },
    h2: {
      fontFamily: SANS_SERIF_FONT_FAMILY,
      fontSize: '1.5rem',
      lineHeight: 1.2,
      fontWeight: 800,
    },
    h3: {
      fontSize: '1.125rem',
      fontFamily: SANS_SERIF_FONT_FAMILY,
      fontWeight: 800,
      lineHeight: 1.5,
    },
    h4: {
      fontSize: '0.875rem',
      fontFamily: SANS_SERIF_FONT_FAMILY,
      fontWeight: 800,
      lineHeight: 1.5,
    },
    h5: {
      fontSize: '0.75rem',
      fontFamily: SANS_SERIF_FONT_FAMILY,
      fontWeight: 800,
      lineHeight: 1.3,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 400,
      color: WITHCO_GREY,
      lineHeight: 1.5,
    },
    body1: {
      fontSize: '0.875rem',
      lineHeight: 1.5,
    },
    caption: {
      fontSize: '0.75rem',
    },
    button: {
      fontFamily: SANS_SERIF_FONT_FAMILY,
      fontWeight: 800,
      fontSize: '0.75rem',
    },
    strong: {
      fontFamily: SANS_SERIF_FONT_FAMILY,
      fontSize: '2rem',
      fontWeight: 800,
      lineHeight: 1.2,
    },
    overline: {
      fontSize: '0.875rem',
      lineHeight: 2.25,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: `
          overscrollBehavior: 'none';
          ${tiemposFontFace};
          ${sharpSansDisplayNo1ExtraBoldFontFace};
          ${sharpSansDisplayNo1BoldFontFace};
        `,
        body: {
          overscrollBehavior: 'none',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: SANS_SERIF_FONT_FAMILY,
          fontWeight: 800,
          fontSize: '0.75rem',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: SANS_SERIF_FONT_FAMILY,
          fontWeight: 800,
          fontSize: '0.75rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
  },
})

theme.typography.h1 = {
  ...theme.typography.h1,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}

theme.typography.h2 = {
  ...theme.typography.h2,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.125rem',
  },
}

theme.typography.h6 = {
  ...theme.typography.h6,
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.875rem',
  },
}

theme.typography.strong = {
  ...theme.typography.strong,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}

interface Props {
  children: React.ReactNode
}

export const PortalAppThemeProvider = ({children}: Props): JSX.Element => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
)
