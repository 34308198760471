import * as React from 'react'
import {NumberFormatValues} from 'react-number-format'
import {useLocation} from 'react-router-dom'

import {
  CheckCircleOutlineOutlined,
  ErrorOutlineOutlined,
  HelpOutline,
} from '@mui/icons-material'
import {
  Grid,
  Paper,
  Slider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  darken,
  useTheme,
} from '@mui/material'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {debounce} from 'lodash'

import {GetPreapprovedButton} from 'src/components/portal/GetPreapprovedButton'
import {createNumberFormat} from 'src/framework/form/NumberFormat'
import {OutlinedTextField} from 'src/framework/form/OutlinedTextField'
import {apiPartnersPaymentScenariosComparisonSummaryPath} from 'src/generated/routes'
import {useShow} from 'src/hooks/request/useShow'
import {useInit} from 'src/hooks/useInit'
import {BudgetEstimate, PartnerDeal} from 'src/types'
import {PortalState} from 'src/types/deal'
import {track} from 'src/util/analytics'
import {formatCurrency} from 'src/util/format'

type Summary = {
  downPayment: string
  firstFiveYearCost: string
  fiveYearAverageMonthlyPayment: string
  totalCost: string
  averageMonthlyPayment: string
  presentValueFirstFiveYearCost: string
  presentValueFiveYearAverageMonthlyPayment: string
  presentValueTotalCost: string
  presentValueAverageMonthlyPayment: string
}

type Summaries = {
  buyNow: Summary
  leaseToOwn: Summary | null
}

type SummaryFields = keyof Summary

type SummaryFieldDescription = {
  [SummaryField in SummaryFields]: string
}

type HandlePurchasePriceChangeProps = {
  newPurchasePrice: number
  inputLocation: 'slider' | 'textField'
}

const summaryFieldDescriptions: SummaryFieldDescription = {
  downPayment: 'Upfront Cost',
  firstFiveYearCost: '5-Year Total Cost',
  fiveYearAverageMonthlyPayment: '5-Year Average Monthly Payment',
  totalCost: '25-Year Total Cost',
  averageMonthlyPayment: '25-Year Average Monthly Payment',
  presentValueFirstFiveYearCost: '5-Year Total Cost',
  presentValueFiveYearAverageMonthlyPayment: '5-Year Average Monthly Payment',
  presentValueTotalCost: '25-Year Total Cost',
  presentValueAverageMonthlyPayment: '25-Year Average Monthly Payment',
}

interface Props {
  deal: PartnerDeal
  budgetEstimate: BudgetEstimate
  refetchDeal: () => void
  onRender?: () => void
  onPurchasePriceChange?: (purchasePrice: number) => void
  trackingLocation: string
}

const MAX_PURCHASE_PRICE = 5000000
const MIN_PURCHASE_PRICE = 300000

export const PaymentScenarioComparisonSummary = ({
  deal,
  budgetEstimate,
  refetchDeal,
  onRender = () => null,
  onPurchasePriceChange,
  trackingLocation,
}: Props): JSX.Element | null => {
  const {realEstateValuationsEnabled} = useFlags()
  const theme = useTheme()

  const {search: location} = useLocation()

  const purchasePriceFromQueryParam = React.useMemo(() => {
    const query = new URLSearchParams(location)
    const value = query.get('purchasePrice')
    return value ? Number(value) : null
  }, [location])

  const defaultPurchasePrice = React.useMemo(() => {
    if (purchasePriceFromQueryParam) {
      return purchasePriceFromQueryParam
    }
    if (budgetEstimate.preapprovedBudget) {
      return budgetEstimate.preapprovedBudget
    }
    if (budgetEstimate.budgetHigh && budgetEstimate.budgetLow) {
      return (budgetEstimate.budgetHigh + budgetEstimate.budgetLow) / 2
    }
    return 1000000
  }, [
    budgetEstimate.budgetHigh,
    budgetEstimate.budgetLow,
    budgetEstimate.preapprovedBudget,
    purchasePriceFromQueryParam,
  ])

  const [purchasePrice, setPurchasePrice] =
    React.useState<number>(defaultPurchasePrice)

  const maxBudgetPrice = React.useMemo(() => {
    if (budgetEstimate.preapprovedBudget) {
      return budgetEstimate.preapprovedBudget
    }
    if (budgetEstimate.budgetHigh) {
      return budgetEstimate.budgetHigh
    }
    return MAX_PURCHASE_PRICE
  }, [budgetEstimate.budgetHigh, budgetEstimate.preapprovedBudget])

  const isPurchasePriceAllowed = React.useCallback(
    (values: NumberFormatValues): boolean => {
      const {floatValue} = values

      return (
        floatValue != undefined &&
        floatValue <= MAX_PURCHASE_PRICE &&
        floatValue >= MIN_PURCHASE_PRICE
      )
    },
    [],
  )

  const isPurchasePriceInBounds = React.useMemo(() => {
    return purchasePrice <= maxBudgetPrice
  }, [maxBudgetPrice, purchasePrice])

  const budgetText = React.useMemo(() => {
    if (isPurchasePriceInBounds) {
      return 'Looking good! Based on the financial information you’ve provided, the purchase is within your purchasing power.'
    }
    let text =
      'The purchase price above may be out of range, based on the financial information you’ve provided.'
    if (
      [
        PortalState.LEAD,
        PortalState.PREQUALIFIED,
        PortalState.ADDITIONAL_FINANCIALS,
      ].includes(deal.portalState)
    ) {
      text +=
        ' Share more financials to potentially increase your purchasing power.'
    }
    return text
  }, [deal.portalState, isPurchasePriceInBounds])

  const {fetch, entity: summaries} = useShow<Summaries>(
    apiPartnersPaymentScenariosComparisonSummaryPath(deal.id),
  )

  const fetchAndTrack = React.useCallback(
    async (newPurchasePrice: number) => {
      const {entity: newSummaries} = await fetch({
        params: {purchasePrice: newPurchasePrice},
      })
      if (newSummaries) {
        track('View Compare Options', {
          buyNow: newSummaries.buyNow,
          leaseToOwn: newSummaries.leaseToOwn,
          newPurchasePrice,
          location: trackingLocation,
        })
      }
    },
    [fetch, trackingLocation],
  )

  useInit(() => {
    fetchAndTrack(purchasePrice)
  })

  const buyNow = React.useMemo(() => summaries?.buyNow, [summaries?.buyNow])
  const leaseToOwn = React.useMemo(
    () => summaries?.leaseToOwn,
    [summaries?.leaseToOwn],
  )

  const summaryFields: SummaryFields[] = [
    'downPayment',
    'presentValueFirstFiveYearCost',
    'presentValueFiveYearAverageMonthlyPayment',
    'presentValueTotalCost',
    'presentValueAverageMonthlyPayment',
  ]

  const summaryFieldsColorMap = React.useCallback(
    (field: SummaryFields) => {
      if (buyNow?.[field] === undefined || leaseToOwn?.[field] === undefined) {
        return {
          leaseToOwn: theme.palette.text.primary,
          buyNow: theme.palette.text.primary,
        }
      } else if (buyNow?.[field] > leaseToOwn?.[field]) {
        return {
          leaseToOwn: theme.palette.primary.main,
          buyNow: theme.palette.text.primary,
        }
      } else {
        return {
          leaseToOwn: theme.palette.text.primary,
          buyNow: theme.palette.primary.main,
        }
      }
    },
    [
      buyNow,
      leaseToOwn,
      theme.palette.primary.main,
      theme.palette.text.primary,
    ],
  )

  const valueLabelFormat = React.useCallback(
    (value: number) => formatCurrency(value.toString(), {precision: 0}),
    [],
  )

  const handlePurchasePriceChange = React.useCallback(
    async ({
      newPurchasePrice,
      inputLocation,
    }: HandlePurchasePriceChangeProps) => {
      track('Adjust Compare Options Price', {
        price: newPurchasePrice,
        location: inputLocation,
      })
      await fetchAndTrack(newPurchasePrice)
      onPurchasePriceChange && onPurchasePriceChange(newPurchasePrice)
    },
    [fetchAndTrack, onPurchasePriceChange],
  )

  const handlePurchasePriceChangeDebounced = React.useMemo(
    () => debounce(handlePurchasePriceChange, 500),
    [handlePurchasePriceChange],
  )

  const handlePurchasePriceSliderChange = React.useCallback(
    (event, newPurchasePrice) => {
      handlePurchasePriceChangeDebounced({
        newPurchasePrice,
        inputLocation: 'slider',
      })
      setPurchasePrice(newPurchasePrice)
    },
    [handlePurchasePriceChangeDebounced],
  )

  const handlePurchasePriceTextFieldChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newPurchasePrice = Number(event.target.value)
      handlePurchasePriceChangeDebounced({
        newPurchasePrice,
        inputLocation: 'textField',
      })
      setPurchasePrice(newPurchasePrice)
    },
    [handlePurchasePriceChangeDebounced],
  )

  const MinMaxDollarFormat = React.useMemo(
    () =>
      createNumberFormat({
        decimalScale: 0,
        prefix: '$',
        isAllowed: isPurchasePriceAllowed,
      }),
    [isPurchasePriceAllowed],
  )

  const shouldRender = React.useMemo(() => {
    return !!(buyNow && leaseToOwn)
  }, [buyNow, leaseToOwn])

  React.useEffect(() => {
    if (shouldRender) {
      onRender()
    }
  }, [shouldRender, onRender])

  React.useEffect(() => {
    onPurchasePriceChange && onPurchasePriceChange(defaultPurchasePrice)
  }, [defaultPurchasePrice, onPurchasePriceChange])

  if (!shouldRender) {
    return null
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography>
          {`At withco, we want to ensure that you choose the best financing
              option for you and your business. Use this calculator to
              understand how withco's lease-to-own arrangement compares to
              purchasing your building today using an SBA 7(a) loan. Both
              scenarios are estimates based on expected changes to market
              interest rates, and assume you fully pay off your mortgage in 25
              years.`}
        </Typography>
      </Grid>
      <Grid item sm={6} xs={12}>
        <Stack spacing={{xs: 2, sm: 3}}>
          <Stack spacing={2}>
            <Typography variant="h3">Property purchase price</Typography>
            <OutlinedTextField
              id="startingPurchasePrice"
              onChange={handlePurchasePriceTextFieldChange}
              inputComponent={MinMaxDollarFormat}
              value={purchasePrice.toString()}
            />
            <Slider
              value={purchasePrice}
              min={MIN_PURCHASE_PRICE}
              step={10000}
              max={MAX_PURCHASE_PRICE}
              onChange={handlePurchasePriceSliderChange}
              valueLabelDisplay="auto"
              valueLabelFormat={valueLabelFormat}
              sx={{
                color: theme.palette.secondary.dark,
                '& .MuiSlider-thumb': {
                  height: '1.75rem',
                  width: '1.75rem',
                },
              }}
            />
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            {isPurchasePriceInBounds ? (
              <CheckCircleOutlineOutlined
                sx={{fill: theme.palette.primary.main, strokeWidth: '1px'}}
                fontSize="large"
              />
            ) : (
              <ErrorOutlineOutlined
                sx={{fill: theme.palette.yellow.main}}
                fontSize="large"
              />
            )}
            <Typography>{budgetText}</Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid item sm={6} xs={12}>
        <Paper
          sx={{
            backgroundColor: theme.palette.background.blue,
            padding: {xs: 2, sm: 3},
          }}
        >
          <Stack spacing={{xs: 2, sm: 3}}>
            <Table sx={{border: 0}}>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    component="th"
                    scope="row"
                    sx={{paddingLeft: 0}}
                  >
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <Typography variant="h4">
                        Key Takeaways (in Present Value)
                      </Typography>
                      <Tooltip
                        title="Money saved today can be reinvested in your business and is more valuable than money saved later. All dollar values below assume money saved is invested with a 10% annual rate of return."
                        placement="top"
                        arrow
                        componentsProps={{
                          tooltip: {
                            sx: {
                              color: '#FFFFFF',
                              backgroundColor: '#222',
                              fontSize: '0.875rem',
                              p: 2,
                            },
                          },
                        }}
                      >
                        <HelpOutline sx={{fontSize: '0.875rem'}} />
                      </Tooltip>
                    </Stack>
                  </TableCell>
                  <TableCell align="right">Lease-to-Own</TableCell>
                  <TableCell align="right" sx={{paddingRight: 0}}>
                    {`SBA 7(a) Loan`}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {summaryFields.map((field) => (
                  <TableRow
                    key={field}
                    sx={{
                      border: 0,
                      '&:last-child': {
                        borderBottom: `1px solid ${darken(
                          theme.palette.secondary.light,
                          0.1,
                        )}`,
                      },
                      borderBottom: `1px dashed ${darken(
                        theme.palette.secondary.light,
                        0.1,
                      )}`,
                      borderTop: '0px',
                    }}
                  >
                    <TableCell
                      align="left"
                      component="th"
                      scope="row"
                      sx={{border: 0, padding: {xs: 1, sm: 2}, paddingLeft: 0}}
                    >
                      <Typography variant="h4">
                        {summaryFieldDescriptions[field]}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{border: 0, padding: {xs: 1, sm: 2}}}
                    >
                      <Typography
                        variant="h4"
                        color={summaryFieldsColorMap(field).leaseToOwn}
                      >
                        {formatCurrency(leaseToOwn?.[field]?.toString(), {
                          precision: 0,
                        })}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{border: 0, padding: {xs: 1, sm: 2}, paddingRight: 0}}
                    >
                      <Typography
                        variant="h4"
                        color={summaryFieldsColorMap(field).buyNow}
                      >
                        {formatCurrency(buyNow?.[field]?.toString(), {
                          precision: 0,
                        })}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {!realEstateValuationsEnabled && (
              <Stack direction={{xs: 'column', sm: 'row'}} justifyContent="end">
                <GetPreapprovedButton deal={deal} onSuccess={refetchDeal} />
              </Stack>
            )}
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  )
}
