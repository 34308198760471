import * as React from 'react'
import {useRutterLink} from 'react-rutter-link'

import {useSnackbar} from 'notistack'

import {apiPartnersRutterConnectionsPath} from 'src/generated/routes'
import {useRequest} from 'src/hooks/request/useRequest'
import {JsonApiResponse, RutterConnection} from 'src/types'
import {track} from 'src/util/analytics'
import {parseResponse} from 'src/util/request/parseResponse'

type RutterResponsePayload = {
  publicToken: string
}

type UseRutterArgs = {
  onSuccess: (publicKey: string) => void
  onExit?: () => void
  platform?: string
  pageLocation: string
  skipCreateAccessToken?: boolean
  showToast?: boolean
}

type UseRutterResult = {
  open: () => void
  isRutterReady: boolean
}

export const useRutter = ({
  platform,
  pageLocation,
  onSuccess,
  onExit,
  skipCreateAccessToken = false,
  showToast = true,
}: UseRutterArgs): UseRutterResult => {
  const {enqueueSnackbar} = useSnackbar()

  const publicKey = document.querySelector<HTMLMetaElement>(
    'meta[name="rutterPublicKey"]',
  )?.content

  const {request: createRutterConnection} = useRequest<
    JsonApiResponse<RutterConnection>,
    RutterResponsePayload
  >('POST', apiPartnersRutterConnectionsPath())

  const createAccessToken = React.useCallback(
    async (publicToken: string) => {
      const response = await createRutterConnection({data: {publicToken}})
      const connection = parseResponse(response)
      if (showToast && connection?.entity?.platform) {
        enqueueSnackbar(
          `Successfully connected to your ${connection.entity.platform}`,
          {
            variant: 'success',
          },
        )
      }
    },
    [createRutterConnection, enqueueSnackbar, showToast],
  )

  const handleSuccessfulAuth = React.useCallback(
    async (publicToken: string) => {
      track('Complete Rutter Connection', {
        accountingPlatform: platform,
        location: pageLocation,
      })

      if (!skipCreateAccessToken) {
        await createAccessToken(publicToken)
      }

      onSuccess && onSuccess(publicToken)
    },
    [
      createAccessToken,
      onSuccess,
      pageLocation,
      platform,
      skipCreateAccessToken,
    ],
  )

  const rutterConfig = React.useMemo(() => {
    return {
      publicKey: publicKey ?? '',
      onSuccess: handleSuccessfulAuth,
      onExit: onExit,
    }
  }, [handleSuccessfulAuth, onExit, publicKey])

  const {open: openRutter, ready: isRutterReady} = useRutterLink(rutterConfig)

  const open = React.useCallback(() => {
    track('Start Rutter Connection', {
      accountingPlatform: platform,
      location: pageLocation,
    })
    openRutter({platform})
  }, [openRutter, pageLocation, platform])

  return {
    open,
    isRutterReady,
  }
}
