import * as React from 'react'

import {Box, Button, ButtonProps, Stack, Typography} from '@mui/material'

import {Popup} from 'src/components/portal/RecommendationFlow/Popup'
import {PopupContent} from 'src/components/portal/RecommendationFlow/PopupContent'
import {RECOMMENDATION_FLOW_BUTTON_PROPS} from 'src/components/portal/RecommendationFlow/RecommendationFlowCard'
import {GoogleMapsAutocomplete} from 'src/framework/GooglePlacesAutocomplete'
import {updateAddressApiPartnersDealPath} from 'src/generated/routes'
import {useRequest} from 'src/hooks/request/useRequest'
import {useToggle} from 'src/hooks/util/useToggle'
import {PartnerDeal} from 'src/types'
import {track} from 'src/util/analytics'

interface Props extends ButtonProps {
  deal: PartnerDeal
  onSuccess: () => void
}

export const ChangePropertyButton = ({
  deal,
  onSuccess,
  ...buttonProps
}: Props) => {
  const [placeId, setPlaceId] = React.useState<string | null>(null)
  const {request: updateDealAddress} = useRequest<void, {placeId: string}>(
    'POST',
    updateAddressApiPartnersDealPath(),
  )
  const [ref, setRef] = React.useState<HTMLElement | null>(null)

  const [
    isChangePropertyFormOpen,
    openChangePropertyForm,
    closeChangePropertyForm,
  ] = useToggle(false)

  const handleAddressSelection = React.useCallback((address: string | null) => {
    setPlaceId(address)
  }, [])

  const handleOpenChangePropertyForm = React.useCallback(() => {
    openChangePropertyForm()
    track('Property Change Started', {
      portalState: deal.portalState,
    })
  }, [deal.portalState, openChangePropertyForm])

  const handleCancelChangeProperty = React.useCallback(() => {
    closeChangePropertyForm()
    setPlaceId(null)
    track('Property Change Cancelled', {
      portalState: deal.portalState,
    })
  }, [closeChangePropertyForm, deal.portalState])

  const handleChangeProperty = React.useCallback(async () => {
    if (placeId) {
      await updateDealAddress({data: {placeId}})
      onSuccess()
      track('Property Change Completed', {
        portalState: deal.portalState,
      })
    }

    closeChangePropertyForm()
  }, [
    closeChangePropertyForm,
    deal.portalState,
    onSuccess,
    placeId,
    updateDealAddress,
  ])

  return (
    <>
      <Button {...buttonProps} onClick={handleOpenChangePropertyForm}>
        Change Property
      </Button>
      <Popup
        onClose={handleCancelChangeProperty}
        open={isChangePropertyFormOpen}
      >
        <PopupContent
          title="Change property"
          onDone={handleCancelChangeProperty}
          height={{xs: '70svh', md: '600px'}}
        >
          <Stack
            justifyContent="space-between"
            pt={2}
            height="100%"
            ref={setRef}
          >
            <Box>
              <Typography>
                Enter another property address and we’ll create a new ownership
                recommendation.
              </Typography>
              <Box my={4}>
                <Typography variant="caption" color="secondary" mb={1}>
                  Property Address
                </Typography>
                <GoogleMapsAutocomplete
                  onPlaceSelected={handleAddressSelection}
                  container={ref}
                />
              </Box>
            </Box>
            <Stack alignItems="center">
              <Button
                {...RECOMMENDATION_FLOW_BUTTON_PROPS}
                onClick={handleChangeProperty}
                disabled={!placeId}
              >
                Change property
              </Button>
            </Stack>
          </Stack>
        </PopupContent>
      </Popup>
    </>
  )
}
