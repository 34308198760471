import * as React from 'react'

import {Cancel} from '@mui/icons-material'
import {
  Box,
  IconButton,
  LinearProgress,
  Link,
  Stack,
  Typography,
} from '@mui/material'

import {FileIcon} from 'src/framework/FileIcon'

interface Props {
  name: string
  pendingFile?: File | null
  onRemove?: () => void
}

export const PartnerCompanyAttachment = ({
  name,
  pendingFile = null,
  onRemove,
}: Props): JSX.Element => {
  const [loadingBarValue, setLoadingBarValue] = React.useState(0)
  React.useEffect(() => {
    const timeoutId = setTimeout(() => setLoadingBarValue(100), 100)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  const pendingFileReadResult = React.useMemo(() => {
    if (!pendingFile) return {href: ''}
    const result = {href: ''}
    const reader = new FileReader()
    reader.addEventListener(
      'load',
      () => (result.href = reader.result?.toString() ?? ''),
      false,
    )
    reader.readAsDataURL(pendingFile)
    return result
  }, [pendingFile])

  return (
    <Box display="flex" alignItems="flex-end">
      <Box height="24px" mr={1} pl={{xs: 0.5, sm: 0}}>
        <FileIcon fileName={name} sx={{fontSize: '24px'}} />
      </Box>
      <Stack flexGrow={1} minWidth="0px">
        <Box display="flex" alignItems="center">
          <Typography
            variant="body2"
            fontWeight={500}
            flexGrow={1}
            textAlign="left"
            minWidth={0}
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
          >
            {pendingFile ? (
              <Link href={pendingFileReadResult.href} download={name}>
                {name}
              </Link>
            ) : (
              name
            )}
          </Typography>
          <Stack direction="row" mb={-0.5} mr={-0.5} height="32px">
            {onRemove && (
              <Box>
                <IconButton size="small" onClick={onRemove}>
                  <Cancel sx={{fontSize: '16px'}} />
                </IconButton>
              </Box>
            )}
          </Stack>
        </Box>
        <LinearProgress
          value={loadingBarValue}
          variant={'determinate'}
          color={pendingFile ? 'info' : 'success'}
        />
      </Stack>
    </Box>
  )
}
