export type Deal = {
  id: string
  name: string | null
  closedWon: boolean
  underwritingSheetUrl: string | null
  googleDriveFolderUrl: string | null
  hubspotUrl: string | null
  address: string | null
  city: string | null
  state: string | null
  zipCode: string | null
  amount: string | null
  ownerName: string | null
  capRate: string | null
  tenancy: string | null
  grossAnnualRent: string | null
  netAnnualRent: string | null
  mapsEmbedUrl: string | null
  area: string | null
  rentPerSqft: string | null
  tenantAdjustedRevenue: string | null
  tenantAdjustedNetIncome: string | null
  tenantFinancialNotes: string | null
  readOnly: boolean
  submarketId: string | null
  hubspotSubmarket: string | null
  lat: string | null
  lng: string | null
  companyId: string | null
  companyIndustryId: string | null
  companyNaicsCode: string | null
  tenantFinancialUrl: string | null
  inDealPipeline: boolean
  smbContactHubspotId: string | null
  investmentContactHubspotId: string | null
  creContactHubspotId: string | null
  reOpsContactHubspotId: string | null
  startingPurchasePrice: string | null
  maxPurchasePrice: string | null
  preapprovedBudget: string | null
  portalState: PortalState | null
  marketInfoAddressId: string | null
  completeAddress: string | null
  googleDriveFolderName: string | null
}

export type DashboardDeal = {
  id: string
  name: string
  dealType: string
  psaCourt: string
  lastDayToOrderDueDiligence: string
  withcoIcMeetingStatus: string
  hubspotUrl: string
  hasDealTasks: boolean
  loiStatus: string
  withcoCourt: Array<string>
  activeTasksCount: number
  creNextSteps: string | null
  smbNextSteps: string | null
  tasksDueToday: boolean
  tasksLate: boolean
  tasksRejected: boolean
  taskCompletionRate: string
  completedTasksCount: number
  tasksCount: number
  tenantMeetingStatus: string | null
  amount: string | null
  latestProposalPurchasePrice: string | null
  latestProposalCapRate: string | null
  latestProposalNetAnnualRent: string | null
  askingCapRate: string | null
  netAnnualRent: string | null
  ownerHubspotId: string | null
  investmentContactHubspotId: string | null
  creContactHubspotId: string | null
  smbContactHubspotId: string | null
  reOpsContactHubspotId: string | null
  latestCompanyFinancialMonthlyRent: string | null
  latestCompanyFinancialAnnualRent: string | null
  latestCompanyFinancialMonthlyRevenue: string | null
  latestCompanyFinancialAnnualRevenue: string | null
  latestCompanyFinancialEBITDAR: string | null
  daysInQualifiedLeadStage: string | null
  currentQuarterSubmarketRentPsf: string | null
  propertyType: string | null
  mostProbableSubmarketName: string | null
  latestFormsortWebhookEventOriginalLeadScore: string | null
  latestFormsortWebhookEventYearsOfOperation: string | null
  latestFormsortWebhookEventLeaseExpirationDate: string | null
  latestFormsortWebhookEventTiming: string | null
  hasLeaseAgreement: boolean
  rutterConnected: boolean | null
  hasBalanceSheet: boolean
  hasIncomeStatement: boolean
  address: string | null
  city: string | null
  state: string | null
  zipCode: string | null
  area: string | null
  latestProposalRentableArea: string | null
  latestProposalStartingAnnualRentPerSqft: string | null
  latestProposalPurchasePricePerSqft: string | null
  currentQuarterSubmarketSalePricePsf: string | null
  currentQuarterSubmarketMarketCapRate: string | null
  currentAnnualRentPerSqft: string | null
  salePricePerSqft: string | null
  completeAddress: string | null
}

export type RecommendationDeal = {
  id: string
  name: string
  address: string | null
  residential: boolean | null
  multiUnit: boolean | null
  propertyValuation: string | null
  preapprovedBudget: string | null
  budgetRequestedAt: string | null
  connectionType: string | null
}

export const enum PortalState {
  LEAD = 'lead',
  PREQUALIFIED = 'prequalified',
  PREAPPROVAL_UNDER_REVIEW = 'preapproval_under_review',
  DISQUALIFIED = 'disqualified',
  PROPERTY_INELIGIBLE = 'property_ineligible',
  UNAFFORDABLE_PROPERTY = 'unaffordable_property',
  PREAPPROVED = 'preapproved',
  WAITLISTED = 'waitlisted',
  SBA_PREAPPROVED = 'sba_preapproved',
  SBA_APPLICATION_COMPLETE = 'sba_application_complete',
  CLOSED_WON = 'closed_won',
  CLOSED_LOST = 'closed_lost',
  ADDITIONAL_FINANCIALS = 'additional_financials',
  LETS_TALK = 'lets_talk',
}

export type PartnerDeal = {
  id: string
  portalState: PortalState
  smbInbound: boolean
  residential: boolean | null
  multiUnit: boolean | null
  propertyValuation: number | null
  hasRecommendation: boolean
}
