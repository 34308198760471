import * as React from 'react'

import {MutexContext} from 'src/contexts/mutex/MutexContext'

export const useMutex = () => {
  const mutexSet = React.useContext(MutexContext)

  const getLock = React.useCallback(
    (key: string) => {
      if (mutexSet.has(key)) {
        return false
      } else {
        mutexSet.add(key)
        return true
      }
    },
    [mutexSet],
  )

  const releaseLock = React.useCallback(
    (key: string) => {
      return mutexSet.delete(key)
    },
    [mutexSet],
  )

  return {getLock, releaseLock}
}
