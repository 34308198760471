import * as React from 'react'

import {Email, Textsms, Forum} from '@mui/icons-material'
import {
  Typography,
  Button,
  Menu,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Link,
} from '@mui/material'
import {Box, Container, Stack} from '@mui/system'

import {DemoPortalState} from 'src/components/portal/DemoPortalState'
import {SkeletonLoader} from 'src/framework/ui/SkeletonLoader'
import {PartnerCompany, User} from 'src/types'

interface Props {
  user: User | null
  company: PartnerCompany | null
}

export const ContactUsBanner = ({user, company}: Props): JSX.Element => {
  return (
    <Box width="100%" py={2} sx={{backgroundColor: '#FFFFFF'}}>
      <Container maxWidth="lg">
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Typography variant="h2" textAlign="left">
              <SkeletonLoader loading={!user || !company}>
                {company?.name}
              </SkeletonLoader>
            </Typography>
            <Typography variant="h6" textAlign="left">
              <SkeletonLoader loading={!user || !company} width={150}>
                {user?.name}
              </SkeletonLoader>
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <DemoPortalState />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              flexShrink={0}
            >
              <ContactUs />
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}

const ContactUs = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        fullWidth={false}
        color="primary"
        variant="outlined"
        startIcon={<Forum />}
        onClick={handleClick}
      >
        Chat With Us
      </Button>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList disablePadding>
          <MenuItem className="open-intercom">
            <ListItemIcon>
              <Forum fontSize="small" sx={{color: '#000000'}} />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="h4">Chat with us</Typography>
            </ListItemText>
          </MenuItem>
          <MenuItem component={Link} href="mailto:join@with.co">
            <ListItemIcon>
              <Email fontSize="small" sx={{color: '#000000'}} />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="h4">
                Email us at{' '}
                <Typography component="span" variant="h4" color="primary">
                  join@with.co
                </Typography>
              </Typography>
            </ListItemText>
          </MenuItem>
          <MenuItem component={Link} href="sms:4707489484">
            <ListItemIcon>
              <Textsms fontSize="small" sx={{color: '#000000'}} />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="h4">
                Text us at{' '}
                <Typography component="span" variant="h4" color="primary">
                  (470) 748-9484
                </Typography>
              </Typography>
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  )
}
