import * as React from 'react'
import {Link as RouterLink} from 'react-router-dom'

import {Paper, Typography, Button} from '@mui/material'
import {Stack} from '@mui/system'

export const PortfolioCustomersCard = (): JSX.Element => {
  return (
    <Paper sx={{p: 3, overflow: 'hidden'}}>
      <Stack spacing={2}>
        <Typography variant="h3">
          Congratulations on completing your lease-to-own journey!
        </Typography>
        <Stack spacing={1}>
          <Typography variant="body1">
            {`You're one of our most valued customers, and we're working on a more complete portal experience for you.`}
          </Typography>
          <Typography variant="body1">
            {`In the meantime, here are some tools that might be useful:`}
          </Typography>
        </Stack>
      </Stack>
      <Stack spacing={2} maxWidth={320} mt={4}>
        <Button variant="contained" component={RouterLink} to="/portal/plaid">
          Connect Plaid
        </Button>
        <Button variant="contained" component={RouterLink} to="/portal/rutter">
          Connect Your Accounting Software
        </Button>
        <Button
          variant="contained"
          component={RouterLink}
          to="/portal/document_uploads"
        >
          Upload Documents
        </Button>
      </Stack>
    </Paper>
  )
}
