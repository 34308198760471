import {PortalState} from 'src/types/deal'

export const preapprovedPortalState = (portalState: PortalState) => {
  return [
    PortalState.PREAPPROVED,
    PortalState.SBA_PREAPPROVED,
    PortalState.WAITLISTED,
    PortalState.PROPERTY_INELIGIBLE,
  ].includes(portalState)
}
