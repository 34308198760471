import * as React from 'react'

import {HelpOutlineRounded} from '@mui/icons-material'
import {Box, Collapse, Divider, Stack, Tooltip, Typography} from '@mui/material'
import {green, grey, yellow} from '@mui/material/colors'

import {PartnerCompanyAttachment} from 'src/components/partners/PartnerCompanyAttachment'
import {FileExtension, FileInput} from 'src/framework/FileInput'
import {useDragEvents} from 'src/hooks/useDragEvent'
import {PartnerAttachment, AttachmentTypeName} from 'src/types/attachment'

interface Props {
  attachments: Array<PartnerAttachment>
  attachmentType: AttachmentTypeName
  fileExtensionRestrictions?: FileExtension[]
  label: string
  tooltip?: string
  pendingFiles: Array<File>
  onFileListStaged: (fileList: FileList) => void
  onFileRemoved: (file: File) => void
}

export const PartnerCompanyAttachmentType = ({
  attachments,
  attachmentType,
  fileExtensionRestrictions = [],
  label,
  tooltip = '',
  pendingFiles,
  onFileListStaged,
  onFileRemoved,
}: Props): JSX.Element => {
  const handleFileSelection: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      (event) => {
        const files: FileList | null = event.target?.files
        if (!files) return
        onFileListStaged(files)
      },
      [onFileListStaged],
    )

  const {draggedItem, handleDragEnter, handleDragLeave, handleDrop} =
    useDragEvents({onFileDrop: onFileListStaged})

  const hasAttachments = attachments.length > 0
  const hasPendingFiles = pendingFiles.length > 0

  return (
    <Box
      data-testid={`attachment_type_${attachmentType}`}
      position="relative"
      border={(theme) => `1px dashed ${theme.palette.grey[600]}`}
      borderRadius={2}
      borderColor={draggedItem ? 'primary.main' : undefined}
      pl={{xs: 2, sm: 3}}
      pr={{xs: 1, sm: 2}}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onDragOver={(e) => e.preventDefault()}
      bgcolor={draggedItem ? 'primary.faint' : 'background.paper'}
    >
      <Box
        width="8px"
        position="absolute"
        top={-1}
        left={-1}
        height="calc(100% + 2px)"
        bgcolor={
          hasPendingFiles
            ? yellow[300]
            : hasAttachments
            ? green[300]
            : grey[300]
        }
        sx={{transition: 'background-color 0.5s'}}
        borderRadius={`8px 0 0 8px`}
      />
      <Stack>
        <Box
          display="flex"
          alignItems="center"
          py={0.5}
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="row">
            <Typography
              variant="h4"
              align="left"
              fontWeight="bold"
              pl={{xs: 0.5, sm: 0}}
            >
              {label}
            </Typography>
            {tooltip ? (
              <Tooltip title={tooltip} placement="top" arrow>
                <HelpOutlineRounded
                  sx={{
                    fontSize: '16px',
                    color: 'grey.500',
                    '&:hover': {color: 'grey.800'},
                    cursor: 'help',
                    ml: 1,
                  }}
                />
              </Tooltip>
            ) : null}
          </Box>
          <Box textAlign="left">
            <FileInput
              id={`${attachmentType}-file-input`}
              onChange={handleFileSelection}
              fileExtensionRestrictions={fileExtensionRestrictions}
              buttonText={'Upload'}
              size="small"
              multiple
            />
          </Box>
        </Box>
        <Collapse in={hasAttachments || hasPendingFiles}>
          <Divider flexItem />
          <Stack mb={2} mt={0.5} spacing={1} mr={0.5}>
            {attachments.map((attachment) => (
              <PartnerCompanyAttachment
                key={`${attachment.name}_${attachment.createdAt}`}
                name={attachment.name}
              />
            ))}
            {pendingFiles.map((file) => (
              <PartnerCompanyAttachment
                key={`${file.name}_${file.lastModified}`}
                name={file.name}
                pendingFile={file}
                onRemove={() => onFileRemoved(file)}
              />
            ))}
          </Stack>
        </Collapse>
      </Stack>
    </Box>
  )
}
