import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'

import {configureStore} from '@reduxjs/toolkit'

import attachmentTypesReducer from 'src/store/internal/attachmentTypesSlice'
import companiesReducer from 'src/store/internal/companiesSlice'
import currentUserReducer from 'src/store/internal/currentUserSlice'
import dealsReducer from 'src/store/internal/dealsSlice'
import employeeUsersReducer from 'src/store/internal/employeeUsers'
import industriesReducer from 'src/store/internal/industriesSlice'
import submarketsReducer from 'src/store/internal/submarketsSlice'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const initializeStore = () =>
  configureStore({
    reducer: {
      attachmentTypes: attachmentTypesReducer,
      companies: companiesReducer,
      currentUser: currentUserReducer,
      deals: dealsReducer,
      industries: industriesReducer,
      employeeUsers: employeeUsersReducer,
      submarkets: submarketsReducer,
    },
  })
export const store = initializeStore()

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
