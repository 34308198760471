type Options = {
  negate?: boolean
  precision?: number
}

export const formatCurrency = (
  value: string | null | undefined,
  options?: Options,
): string => {
  if (!value) return '-'

  const {negate = false, precision = 2} = options ?? {}

  const floatValue = parseFloat(value) * (negate ? -1 : 1)
  if (floatValue === 0) return '$0'
  if (isNaN(floatValue)) return '-'

  return floatValue.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: precision,
    minimumFractionDigits: precision,
  })
}
