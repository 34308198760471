import * as React from 'react'

import axios, {CancelToken} from 'axios'

export const useAxiosCancelToken = (): CancelToken => {
  const source = React.useMemo(() => axios.CancelToken.source(), [])
  React.useEffect(() => {
    return () =>
      source.cancel(`Canceling request because component was unmounted`)
  }, [source])

  return source.token
}
