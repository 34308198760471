import * as React from 'react'

import {VerifiedUser} from '@mui/icons-material'
import {ButtonProps, SvgIcon, SxProps} from '@mui/material'

import {ProgressButton} from 'src/framework/ui/ProgressButton'
import {useRutter} from 'src/hooks/useRutter'
import {ReactComponent as QuickBooksLogo} from 'svg/quickbooks.svg'
import {ReactComponent as SquareLogo} from 'svg/square_logo.svg'
import {ReactComponent as StripeLogo} from 'svg/stripe_logo.svg'
import {ReactComponent as WaveLogo} from 'svg/wave.svg'
import {ReactComponent as XeroLogo} from 'svg/xero.svg'

interface Props {
  onSuccess: (publicToken: string) => void
  onExit?: () => void
  platform?: string
  skipCreateAccessToken?: boolean
  buttonProps?: ButtonProps
  sx?: SxProps
  defaultButtonText?: string
  displayIcon?: boolean
  buttonVariant?: 'contained' | 'outlined'
  pageLocation: string
  disabled?: boolean
}

export const accountingIcon = (platform: string | undefined): JSX.Element => {
  switch (platform) {
    case 'QUICKBOOKS':
      return (
        <SvgIcon
          component={QuickBooksLogo}
          inheritViewBox
          sx={{fontSize: 20}}
        />
      )
    case 'QUICKBOOKS_DESKTOP':
      return (
        <SvgIcon
          component={QuickBooksLogo}
          inheritViewBox
          sx={{fontSize: 20}}
        />
      )
    case 'XERO':
      return <SvgIcon component={XeroLogo} inheritViewBox sx={{fontSize: 20}} />
    case 'WAVE':
      return <SvgIcon component={WaveLogo} inheritViewBox sx={{fontSize: 20}} />
    case 'SQUARE':
      return (
        <SvgIcon component={SquareLogo} inheritViewBox sx={{fontSize: 20}} />
      )
    case 'STRIPE':
      return (
        <SvgIcon component={StripeLogo} inheritViewBox sx={{fontSize: 20}} />
      )
    default:
      return <VerifiedUser />
  }
}

export const RutterButton = function ({
  onSuccess,
  onExit,
  platform,
  skipCreateAccessToken = false,
  buttonProps,
  sx,
  defaultButtonText,
  displayIcon = true,
  buttonVariant = 'outlined',
  pageLocation,
  disabled = false,
}: Props) {
  const buttonText = React.useMemo(() => {
    if (defaultButtonText) return defaultButtonText

    switch (platform) {
      case 'QUICKBOOKS':
        return 'Quickbooks'
      case 'QUICKBOOKS_DESKTOP':
        return 'QB Desktop'
      case 'XERO':
        return 'Xero'
      case 'WAVE':
        return 'Wave'
      case 'SQUARE':
        return 'Square'
      case 'STRIPE':
        return 'Stripe'
      default:
        return 'Connect now'
    }
  }, [defaultButtonText, platform])

  const icon = React.useMemo(() => {
    if (!displayIcon) return null

    return accountingIcon(platform)
  }, [displayIcon, platform])

  const {open, isRutterReady} = useRutter({
    platform,
    onSuccess: onSuccess,
    onExit: onExit,
    pageLocation,
    skipCreateAccessToken,
  })

  return (
    <ProgressButton
      loading={!isRutterReady}
      startIcon={icon}
      fullWidth={false}
      variant={buttonVariant}
      sx={{
        whiteSpace: 'nowrap',
        width: 'fit-content',
        ...sx,
      }}
      onClick={open}
      buttonProps={buttonProps}
      disabled={disabled}
    >
      {buttonText}
    </ProgressButton>
  )
}
