import * as React from 'react'
import {Link as RouterLink, useLocation} from 'react-router-dom'

import {
  AccountBalance,
  AccountCircle,
  BarChart,
  HouseOutlined,
  Menu as MenuIcon,
  PointOfSale,
  UploadFile,
} from '@mui/icons-material'
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import {SignOutButton} from 'src/components/susi/SignOutButton'
import {useAnalyticsUser} from 'src/hooks/analytics/useAnalyticsUser'
import {useCurrentUser} from 'src/hooks/request/useCurrentUser'
import {useLaunchDarklyUser} from 'src/hooks/useLaunchDarklyUser'
import {useToggle} from 'src/hooks/util/useToggle'
import {PortalProduct} from 'src/types/user'
import {ReactComponent as WithcoWordmark} from 'svg/withco_wordmark.svg'

type NavTabConfig = {
  label: string
  labelDense?: string
  href: string
  icon: React.ReactElement
}

const navTabsConfig: NavTabConfig[] = [
  {
    label: 'Dashboard',
    href: '/portal',
    icon: <HouseOutlined />,
  },
]

const navTabsConfigOnboarding: NavTabConfig[] = []

const navTabsConfigNonPortal: NavTabConfig[] = [
  {
    label: 'Connect Bank Accounts',
    labelDense: 'Bank Accounts',
    href: '/portal/plaid',
    icon: <AccountBalance />,
  },
  {
    label: 'Connect Accounting Software',
    labelDense: 'Accounting Software',
    href: '/portal/rutter',
    icon: <BarChart />,
  },
  {
    label: 'Connect Point of Sale',
    labelDense: 'Point of Sale',
    href: '/portal/point_of_sale',
    icon: <PointOfSale />,
  },
  {
    label: 'Upload Documents',
    labelDense: 'Documents',
    href: '/portal/document_uploads',
    icon: <UploadFile />,
  },
]

const getNavIndex = (tabConfigs: NavTabConfig[], pathname: string) => {
  const foundIndex = tabConfigs.findIndex(
    (tabConfig) => tabConfig.href === pathname,
  )

  return foundIndex === -1 ? 0 : foundIndex
}

export const PortalNavBar = ({}): JSX.Element => {
  const location = useLocation()
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [isNavMenuOpen, openNavMenu, closeNavMenu] = useToggle(false)
  const ref = React.useRef<HTMLDivElement>(null)
  const {user, hasBeenFetched} = useCurrentUser()
  useLaunchDarklyUser(user)
  useAnalyticsUser(user)

  const tabConfigs = React.useMemo(() => {
    if (user?.product === PortalProduct.DIO_ONBOARDING) {
      return navTabsConfigOnboarding
    }

    const nonPortalTab = navTabsConfigNonPortal.find(
      (tabConfig) => tabConfig.href === location.pathname,
    )

    return nonPortalTab ? navTabsConfigNonPortal : navTabsConfig
  }, [location.pathname, user])

  const wordmarkLink = React.useMemo(
    () => (tabConfigs.length ? tabConfigs[0].href : location.pathname),
    [location.pathname, tabConfigs],
  )

  const currentTabIndex = React.useMemo(
    () => getNavIndex(tabConfigs, location.pathname),
    [location.pathname, tabConfigs],
  )

  const dense = React.useMemo(() => {
    return tabConfigs.length > 3
  }, [tabConfigs])

  React.useEffect(() => {
    setAnchorElNav(ref.current)
  }, [])

  return (
    <AppBar position="static">
      <Container maxWidth="xl" ref={ref}>
        <Toolbar disableGutters sx={{justifyContent: 'space-between'}}>
          <Box py={2}>
            <Link
              component={RouterLink}
              to={wordmarkLink}
              color="inherit"
              underline="none"
            >
              <WithcoWordmark height="24px" fill="#ffffff" />
            </Link>
          </Box>
          <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}} ml={4}>
            <NavTabs tabConfigs={tabConfigs} />
          </Box>
          {hasBeenFetched && user && (
            <Box display={{xs: 'none', md: 'flex'}} alignItems="center">
              <Box
                mr={{md: 2, lg: 4}}
                ml={{md: 1, lg: 2}}
                display={dense ? 'none' : 'inherit'}
                alignItems="inherit"
              >
                <AccountCircle fontSize="medium" sx={{mr: 1}} />
                <Typography variant="h4">{user?.name}</Typography>
              </Box>
              <SignOutButton variant="outlined" size="small" color="inherit" />
            </Box>
          )}
          <Box display={{xs: 'block', md: 'none'}}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={openNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              open={isNavMenuOpen}
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              anchorPosition={{left: 0, top: 0}}
              PaperProps={{
                sx: {
                  width: '100%',
                  maxWidth: '100%',
                  borderRadius: 0,
                  border: 'none',
                  boxShadow: 2,
                },
              }}
              componentsProps={{
                backdrop: {style: {backgroundColor: 'rgba(0, 0, 0, 0.5)'}},
              }}
              keepMounted
              marginThreshold={0}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              onClose={closeNavMenu}
              sx={{
                display: {xs: 'block', md: 'none'},
              }}
            >
              {tabConfigs.map((config, index) => (
                <MenuItem
                  key={config.href}
                  component={RouterLink}
                  to={config.href}
                  selected={currentTabIndex === index}
                  onClick={closeNavMenu}
                >
                  <Button startIcon={config.icon} disableRipple>
                    {config.label}
                  </Button>
                </MenuItem>
              ))}
              <MenuItem>
                <SignOutButton variant="text" showIcon />
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

interface NavTabsProps {
  tabConfigs: NavTabConfig[]
}
const NavTabs = ({
  tabConfigs = navTabsConfig,
}: NavTabsProps): JSX.Element | null => {
  const location = useLocation()
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.down('lg'))

  const currentTabIndex = React.useMemo(
    () => getNavIndex(tabConfigs, location.pathname),
    [location.pathname, tabConfigs],
  )

  return (
    <Tabs
      value={currentTabIndex}
      aria-label="nav tabs"
      TabIndicatorProps={{style: {backgroundColor: '#ffffff'}}}
    >
      {tabConfigs.map((tabConfig) => (
        <Tab
          key={tabConfig.href}
          component={RouterLink}
          label={
            isMd ? tabConfig.labelDense ?? tabConfig.label : tabConfig.label
          }
          to={tabConfig.href}
          icon={tabConfig.icon}
          iconPosition="start"
          sx={{
            color: alpha('#ffffff', 0.6),
            pb: {xs: 2, md: 1.5},
            pt: {xs: 2, sm: 2.5},
            mr: {lg: 2, xl: 4},
            minHeight: 'unset',
            '&.Mui-selected': {
              color: 'primary.contrastText',
            },
          }}
        />
      ))}
    </Tabs>
  )
}
