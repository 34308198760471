import * as React from 'react'

import {Box, SxProps} from '@mui/material'

interface Props {
  fileName: string | null | undefined
  sx?: SxProps
}

export const FileIcon = ({fileName, sx}: Props): JSX.Element | null => {
  if (!fileName) return null

  const fileNameComponents = fileName.split('.')
  const extension =
    fileNameComponents.length > 1
      ? fileNameComponents[fileNameComponents.length - 1]?.toLowerCase() ??
        'blank'
      : 'blank'

  return (
    <Box
      className={`fiv-cla fiv-icon-${extension}`}
      sx={sx}
      role="img"
      aria-label="File icon"
    />
  )
}
