import {useHistory} from 'react-router-dom'

import {useSnackbar} from 'notistack'

import {usePlaid} from 'src/hooks/usePlaid'

export const PartnerPlaidRedirectScreen = (): JSX.Element | null => {
  const history = useHistory()
  const {enqueueSnackbar} = useSnackbar()

  usePlaid({
    onSuccess: () => {
      history.replace('/portal')
    },
    onExit: () => {
      enqueueSnackbar(
        'Encountered a redirect error when linking bank account',
        {
          variant: 'error',
        },
      )
      history.replace('/portal')
    },
    reinitialize: true,
  })

  return null
}
