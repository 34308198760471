import * as React from 'react'

import {Box, useTheme} from '@mui/material'
import {DotsItem} from '@nivo/core'
import {CustomLayer, CustomLayerProps, ResponsiveLine} from '@nivo/line'

import {QuarterlyChange} from 'src/types'
import {formatPercentage} from 'src/util/format'

interface Props {
  changes: QuarterlyChange[]
  startQuarter: string
}
export const AppreciationChart = ({
  changes,
  startQuarter,
}: Props): JSX.Element => {
  const theme = useTheme()

  const dataPoints = React.useMemo(() => {
    return changes.map(({period, percent}) => {
      return {
        x: period,
        y: percent,
      }
    })
  }, [changes])

  const data = React.useMemo(
    () => [
      {
        id: 'price appreciation',
        data: dataPoints,
      },
    ],
    [dataPoints],
  )

  const yValues = React.useMemo(() => {
    // https://stackoverflow.com/questions/326679/choosing-an-attractive-linear-scale-for-a-graphs-y-axis/326746#326746
    const yDataPoints = dataPoints.map(({y}) => y)
    const min = Math.min(...yDataPoints)
    const max = Math.max(...yDataPoints)
    const range = max - min
    const tickCount = 5
    const unroundedTickSize = range / (tickCount - 1)
    const x = Math.ceil(Math.log10(unroundedTickSize) - 1)
    const pow10x = Math.pow(10, x)
    const roundedTickRange = Math.ceil(unroundedTickSize / pow10x) * pow10x
    const minYValue = roundedTickRange * Math.floor(min / roundedTickRange)
    const maxYValue = roundedTickRange * Math.ceil(max / roundedTickRange)
    const yValues = []
    for (let i = minYValue; i <= maxYValue; i += roundedTickRange) {
      yValues.push(i)
    }
    return {min: minYValue, max: maxYValue, values: yValues}
  }, [dataPoints])

  const startYear = React.useMemo(
    () => startQuarter.split(' ')[0],
    [startQuarter],
  )

  const lastQuarter = React.useMemo(
    () => changes[changes.length - 1].period,
    [changes],
  )

  return (
    <Box width="100%" height="25vh">
      <ResponsiveLine
        data={data}
        colors={[theme.palette.primary.main]}
        curve="natural"
        enableGridX={false}
        pointSize={11}
        layers={[
          'axes',
          'grid',
          'lines',
          'markers',
          RangePoints({startQuarter, lastQuarter}),
        ]}
        yScale={{
          type: 'linear',
          min: yValues.min,
          max: yValues.max,
        }}
        axisBottom={null}
        axisLeft={{
          tickPadding: 8,
          tickValues: yValues.values,
          tickSize: 0,
          format: (value) => {
            return formatPercentage(`${value}`, {precision: 0})
          },
        }}
        gridYValues={yValues.values}
        markers={[
          {
            axis: 'x',
            value: startQuarter,
            legend: startYear,
            legendOrientation: 'horizontal',
            legendPosition: 'top-right',
            lineStyle: {
              stroke: theme.palette.secondary.main,
              strokeWidth: 1,
              strokeDasharray: '4 4',
            },
            textStyle: {
              fontFamily: theme.typography.h5.fontFamily,
              fontSize: 12,
              fill: theme.palette.secondary.main,
            },
          },
          {
            axis: 'x',
            value: changes[changes.length - 1].period,
            legend: 'TODAY',
            legendOrientation: 'horizontal',
            legendPosition: 'top-left',
            lineStyle: {
              stroke: theme.palette.secondary.main,
              strokeWidth: 1,
              strokeDasharray: '4 4',
            },
            textStyle: {
              fontFamily: theme.typography.h5.fontFamily,
              fontSize: 12,
              fill: theme.palette.secondary.main,
            },
          },
        ]}
        margin={{top: 16, bottom: 16, left: 32, right: 24}}
        theme={{
          fontFamily: theme.typography.h5.fontFamily,
          textColor: theme.palette.secondary.main,
          grid: {
            line: {
              stroke: '#EEEFF5',
              strokeWidth: 1,
              strokeDasharray: '4 4',
            },
          },
        }}
      />
    </Box>
  )
}

interface PointsProps {
  startQuarter: string
  lastQuarter: string
}

const RangePoints = ({startQuarter, lastQuarter}: PointsProps) => {
  const theme = useTheme()

  const InnerRangePoints: CustomLayer = ({
    points,
    pointSize,
  }: CustomLayerProps) => {
    const pointsToDisplay = React.useMemo(() => {
      const fiveYearsAgoQ4 =
        points.find(({data}) => data.x === startQuarter) || points[0]
      const lastYearQ4 =
        points.find(({data}) => data.x === lastQuarter) ||
        points[points.length - 1]

      return [fiveYearsAgoQ4, lastYearQ4]
    }, [points])

    return (
      <>
        {pointsToDisplay.map((point, index) => (
          <DotsItem
            key={point.id}
            x={point.x}
            y={point.y}
            size={pointSize || 0}
            color={index === 0 ? '#D9D9D9' : theme.palette.primary.main}
            datum={point.data}
            borderWidth={0}
            borderColor={'blue'}
            labelYOffset={12}
          />
        ))}
      </>
    )
  }

  return InnerRangePoints
}
