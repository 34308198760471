export const getCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  return parts.length === 2 ? parts.pop()?.split(';').shift() || null : null
}

export const unsetCookie = (name: string) => {
  document.cookie = `${name}=;SameSite=Lax`
}

export const decodeBase64 = (value: string): string => {
  return Buffer.from(value, 'base64').toString()
}
export const decodeRailsSignedCookie = (
  value: string | null,
): string | null => {
  try {
    const cookie_value = decodeURIComponent((value || '').split('--')[0])
    const cookie_payload = JSON.parse(decodeBase64(cookie_value))
    const decoded_stored_value = decodeBase64(cookie_payload._rails.message)
    return JSON.parse(decoded_stored_value)
  } catch {
    return null
  }
}
