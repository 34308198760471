import * as React from 'react'

import {ArrowBack, Close} from '@mui/icons-material'
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material'
import {Stack} from '@mui/system'
import {useSnackbar} from 'notistack'

import {ApplicationRequirements} from 'src/components/portal/SBAMicroflow/ApplicationRequirements'
import {LoanAmount} from 'src/components/portal/SBAMicroflow/LoanAmount'
import {ShareYourFinancials} from 'src/components/portal/SBAMicroflow/ShareYourFinancials'
import {Start} from 'src/components/portal/SBAMicroflow/Start'
import {Thanks} from 'src/components/portal/SBAMicroflow/Thanks'
import {apiPartnersSBAApplicationsPath} from 'src/generated/routes'
import {useRequest} from 'src/hooks/request/useRequest'
import {useToggle} from 'src/hooks/util/useToggle'
import {SBAApplication} from 'src/types'
import {track} from 'src/util/analytics'

export interface WizardStepProps {
  onReadyToAdvance: () => void
}
interface Props {
  onClose: () => void
}

export type GoNextProps = {
  sbaApplication?: SBAApplication
}

export type SBAApplicationWizardOnNext = ({}: GoNextProps) => void

export const SBAApplicationWizard = ({onClose}: Props): JSX.Element => {
  const [stepNumber, setStepNumber] = React.useState(0)

  const [sbaApplication, setSBAApplication] = React.useState<SBAApplication>({})

  const [nextButtonEnabled, enableNextButton, disableNextButton] =
    useToggle(false)

  const {request: createSBAApplication} = useRequest<unknown, SBAApplication>(
    'POST',
    apiPartnersSBAApplicationsPath(),
  )

  const {enqueueSnackbar} = useSnackbar()

  const goBack = React.useCallback(() => {
    setStepNumber((prev) => prev - 1)
  }, [setStepNumber])

  const handleSBAApplicationChanged = React.useCallback((sbaApplication) => {
    setSBAApplication((prev) => ({...prev, ...sbaApplication}))
  }, [])

  const handleSaveSBAApplication = React.useCallback(async () => {
    await createSBAApplication({data: sbaApplication})
    track('SBA Application Created', {sbaApplication})
    enqueueSnackbar('SBA application created', {variant: 'success'})
  }, [createSBAApplication, enqueueSnackbar, sbaApplication])

  const handleWizardComplete = React.useCallback(async () => {
    onClose()
  }, [onClose])

  const onReadyToAdvance = React.useCallback(
    () => enableNextButton(),
    [enableNextButton],
  )

  const steps = React.useMemo(
    () => [
      {
        stepName: 'Start',
        component: <Start key="start" onReadyToAdvance={onReadyToAdvance} />,
        title: 'Start your SBA loan application process',
      },
      {
        stepName: 'Application Requirements',
        component: (
          <ApplicationRequirements
            key="applicationRequirements"
            onReadyToAdvance={onReadyToAdvance}
          />
        ),
        title: 'Application requirements',
      },
      {
        stepName: 'Loan Amount',
        component: (
          <LoanAmount
            defaultSBAApplication={sbaApplication}
            key="loanAmount"
            onReadyToAdvance={onReadyToAdvance}
            onSBAApplicationChanged={handleSBAApplicationChanged}
          />
        ),
        title: 'Loan amount',
      },
      {
        stepName: 'Share Financials',
        component: (
          <ShareYourFinancials
            key="shareYourFinancials"
            onReadyToAdvance={onReadyToAdvance}
          />
        ),
        title: 'Share your financials',
        handleSaveSBAApplication,
        cta: 'Submit',
      },
      {
        stepName: 'Thanks',
        component: <Thanks key="thanks" onReadyToAdvance={onReadyToAdvance} />,
        title: "We'll be in touch!",
        cta: 'Close',
      },
    ],
    [
      handleSBAApplicationChanged,
      handleSaveSBAApplication,
      onReadyToAdvance,
      sbaApplication,
    ],
  )

  const currentStep = React.useMemo(
    () => steps[stepNumber],
    [stepNumber, steps],
  )

  const showBackLink = React.useMemo(() => {
    return stepNumber > 0 && stepNumber < steps.length - 1
  }, [stepNumber, steps.length])

  const handleNextClicked = React.useCallback(async () => {
    disableNextButton()
    if (currentStep.handleSaveSBAApplication) {
      await currentStep.handleSaveSBAApplication()
    }
    const nextStepNumber = stepNumber + 1
    track(`SBA Complete ${currentStep.stepName}`)
    if (nextStepNumber === steps.length) {
      await handleWizardComplete()
    } else {
      setStepNumber(nextStepNumber)
    }
  }, [
    currentStep,
    disableNextButton,
    handleWizardComplete,
    stepNumber,
    steps.length,
  ])

  const progress = React.useMemo(() => {
    return ((stepNumber + 1) / steps.length) * 100
  }, [stepNumber, steps])

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      maxHeight="inherit"
      py={{xs: 2, sm: 4}}
      px={{xs: 3, sm: 5}}
    >
      <Stack
        direction="row"
        spacing={3}
        width="100%"
        alignItems="center"
        mb={{xs: 3, sm: 5}}
      >
        {showBackLink && (
          <IconButton color="secondary" onClick={goBack}>
            <ArrowBack />
          </IconButton>
        )}
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            flexGrow: 1,
            height: 8,
            borderRadius: 4,
            '.MuiLinearProgress-bar': {borderRadius: 4},
          }}
        />
        <IconButton color="secondary" onClick={onClose}>
          <Close />
        </IconButton>
      </Stack>
      <Typography variant="h2" mb={2.5} textAlign="center">
        {currentStep.title}
      </Typography>
      <Box
        sx={{overflowY: 'scroll', overflowX: 'hidden'}}
        flexGrow="1"
        textAlign="center"
      >
        {currentStep.component}
      </Box>
      <Box display="flex" justifyContent="center" mt={{xs: 2, sm: 4}}>
        <Button
          variant="contained"
          color="primary"
          sx={{fontSize: '0.875rem', minWidth: '16rem'}}
          size="medium"
          onClick={handleNextClicked}
          disabled={!nextButtonEnabled}
        >
          {currentStep.cta || 'Next'}
        </Button>
      </Box>
    </Box>
  )
}
