import * as React from 'react'

import {AxiosResponse} from 'axios'

import {
  UseLazyRequestOptions,
  RequestOptions,
  useRequest,
} from 'src/hooks/request/useRequest'
import {JsonApiResponse} from 'src/types'
import {Identified, JsonApiIncluded} from 'src/types/jsonApi'
import {ParsedResponse, parseResponse} from 'src/util/request/parseResponse'

export type ShowFetch<
  T = unknown,
  I = Record<never, unknown>,
  P = Record<never, unknown>,
> = (opts?: RequestOptions<void, P>) => Promise<ParsedResponse<T, I>>

type UseShowResult<T, I extends JsonApiIncluded, P> = {
  entity: Identified<T> | null
  included: I | Record<string, never>
  loading: boolean
  completed: boolean
  error: AxiosResponse | null
  fetch: ShowFetch<T, I, P>
  statusCode: number | null
}

export function useShow<
  T,
  I extends JsonApiIncluded = Record<never, unknown>,
  P = Record<never, unknown>,
>(
  url?: string,
  options?: Omit<UseLazyRequestOptions<JsonApiResponse<T, I>>, 'onSuccess'> & {
    onSuccess?: (response: ParsedResponse<T, I>) => void
  },
): UseShowResult<T, I, P> {
  const {onSuccess: onSuccessParsed} = options ?? {}
  const onSuccess = React.useCallback(
    (res: JsonApiResponse<T, I>) => {
      onSuccessParsed && onSuccessParsed(parseResponse(res))
    },
    [onSuccessParsed],
  )
  const modifiedOptions = React.useMemo(
    () => ({
      ...options,
      onSuccess: options?.onSuccess ? onSuccess : undefined,
    }),
    [options, onSuccess],
  )

  const {request, response, loading, completed, error, statusCode} = useRequest<
    JsonApiResponse<T, I>,
    unknown,
    P
  >('GET', url, modifiedOptions)

  const fetch = React.useCallback(
    async (opts?: RequestOptions<unknown, P> | undefined) => {
      return request(opts).then(parseResponse)
    },
    [request],
  )

  const result = React.useMemo(() => parseResponse(response), [response])

  return {
    fetch,
    entity: result.entity,
    included: result.included ?? {},
    loading,
    completed,
    error,
    statusCode,
  }
}
