import {createSlice} from '@reduxjs/toolkit'

import {User} from 'src/types/user'

interface UserState {
  item: User | null
  hasBeenFetched: boolean
}

const initialState: UserState = {
  item: null,
  hasBeenFetched: false,
}

export const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      const payload = action.payload
      state.item = payload
      state.hasBeenFetched = true
    },
    removeCurrentUser: (state) => {
      state.item = null
      state.hasBeenFetched = true
    },
  },
})

export const {setCurrentUser, removeCurrentUser} = currentUserSlice.actions
export default currentUserSlice.reducer
