import * as React from 'react'

import {merge} from 'lodash'

import {isNumericallyEqual} from 'src/util/isNumericallyEqual'

export type Handlers<T> = {
  [x in keyof T]: {
    change: React.ChangeEventHandler<HTMLInputElement>
    reset: () => void
  }
}

export type FormSetters<T> = {[x in keyof T]: (value: string | boolean) => void}

type UseFormResult<T extends {[x: string]: string | boolean}> = {
  formState: T
  handlers: Handlers<T>
  setters: FormSetters<T>
  isFormComplete: boolean
  isFormDirty: boolean
  diff: Partial<T>
}

/*
 * This hook is used to manage form state, and provides handlers that our input
 * components can easily consume to keep the form state up-to-date.
 *
 * Examples:
 * const {formState, handlers} = useForm({
 *   firstName: 'Jane',
 *   middleName: '',
 *   lastName: 'Doe',
 * })
 * ...
 *   <TextField
 *     label="First"
 *     value={formState.firstName}
 *     onChange={handlers.firstName.change}
 *   />
 *   <TextField
 *     label="Middle"
 *     value={formState.middleName}
 *     onChange={handlers.middleName.change}
 *   />
 *   <TextField
 *     label="Last"
 *     value={formState.lastName}
 *     onChange={handlers.lastName.change}
 *   />
 */
export const useForm = <T extends {[x: string]: string | boolean}>(
  defaultState: T,
  {required = []}: {required?: Array<keyof T>} = {},
): UseFormResult<T> => {
  const [formState, setFormState] = React.useState(defaultState)

  const createSetHandler = React.useCallback(
    (key: keyof T): ((value: string | boolean) => void) =>
      (value) => {
        setFormState((prevState) => merge({}, prevState, {[key]: value}))
      },
    [],
  )
  const createChangeHandler = React.useCallback(
    (key: keyof T): React.ChangeEventHandler<HTMLInputElement> =>
      (event) => {
        setFormState((prevState) =>
          merge({}, prevState, {[key]: event.target.value}),
        )
      },
    [],
  )
  const createResetHandler = React.useCallback(
    (key: keyof T) => () =>
      setFormState((prevState) =>
        merge({}, prevState, {[key]: defaultState[key]}),
      ),
    [defaultState],
  )

  const [keys] = React.useState<Array<keyof T>>(
    Object.keys(defaultState) as Array<keyof T>,
  )

  const handlers = React.useMemo(
    () =>
      Object.fromEntries(
        keys.map((key) => [
          key,
          {
            change: createChangeHandler(key),
            reset: createResetHandler(key),
          },
        ]),
      ) as Handlers<T>,
    [keys, createChangeHandler, createResetHandler],
  )

  const setters = React.useMemo(
    () =>
      Object.fromEntries(
        keys.map((key) => [key, createSetHandler(key)]),
      ) as FormSetters<T>,
    [keys, createSetHandler],
  )

  const isFormComplete = React.useMemo(
    () => required.length === 0 || required.every((key) => formState[key]),
    [required, formState],
  )

  const isFormDirty = React.useMemo(() => {
    return !isNumericallyEqual(defaultState, formState)
  }, [defaultState, formState])

  const diff = React.useMemo(() => {
    return Object.fromEntries(
      Object.entries(formState).filter(
        ([key, value]) => defaultState[key] !== value,
      ),
    ) as Partial<T>
  }, [defaultState, formState])

  return {formState, handlers, setters, isFormComplete, isFormDirty, diff}
}
