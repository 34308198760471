import * as React from 'react'
import {Provider as ReduxProvider} from 'react-redux'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  RouteProps,
} from 'react-router-dom'

import {useFlags} from 'launchdarkly-react-client-sdk'
import {SnackbarProvider} from 'notistack'

import {ErrorBoundary} from 'src/ErrorBoundary'
import {AuthContainer} from 'src/framework/AuthContainer'
import {PortalNavFrame} from 'src/framework/PortalNavFrame'
import {PortalAppThemeProvider} from 'src/framework/theming/PortalAppThemeProvider'
import {useCurrentUser} from 'src/hooks/request/useCurrentUser'
import {NotFoundScreen} from 'src/screens/NotFoundScreen'
import {PartnerConnectScreen} from 'src/screens/PartnerConnectScreen'
import {PartnerDocumentUploadsScreen} from 'src/screens/PartnerDocumentUploadsScreen'
import {PartnerDocumentsScreen} from 'src/screens/PartnerDocumentsScreen'
import {PartnerPlaidRedirectScreen} from 'src/screens/PartnerPlaidRedirectScreen'
import {PartnerPlaidScreen} from 'src/screens/PartnerPlaidScreen'
import {PartnerRutterScreen} from 'src/screens/PartnerRutterScreen'
import {PortalHomeScreen} from 'src/screens/PortalHomeScreen'
import {PortalPointOfSaleScreen} from 'src/screens/PortalPointOfSaleScreen'
import {store} from 'src/store/partners/store'
import {PortalProduct} from 'src/types/user'

export const PortalApp = (): JSX.Element => {
  return (
    <ReduxProvider store={store}>
      <Router>
        <React.Suspense fallback={<></>}>
          <PortalAppThemeProvider>
            <ErrorBoundary>
              <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
                <AuthContainer>
                  <PortalNavFrame>
                    <PortalRoutes />
                  </PortalNavFrame>
                </AuthContainer>
              </SnackbarProvider>
            </ErrorBoundary>
          </PortalAppThemeProvider>
        </React.Suspense>
      </Router>
    </ReduxProvider>
  )
}

const PortalRoutes = (): JSX.Element => {
  const {dioDiscoveryOnboarding} = useFlags()
  const {user} = useCurrentUser()

  const userRouteConfigs: RouteProps[] = React.useMemo(() => {
    if (
      dioDiscoveryOnboarding &&
      user?.product === PortalProduct.DIO_ONBOARDING
    ) {
      return [
        {
          path: '/portal',
          exact: true,
          component: PartnerConnectScreen,
        },
      ]
    } else {
      return [
        {
          path: '/portal',
          exact: true,
          component: PortalHomeScreen,
        },
        {
          path: '/portal/documents',
          exact: true,
          component: PartnerDocumentsScreen,
        },
        {
          path: '/portal/document_uploads',
          exact: true,
          component: PartnerDocumentUploadsScreen,
        },
        {
          path: '/portal/plaid',
          exact: true,
          component: PartnerPlaidScreen,
        },
        {
          path: '/portal/plaid/redirect',
          exact: true,
          component: PartnerPlaidRedirectScreen,
        },
        {
          path: '/portal/rutter',
          exact: true,
          component: PartnerRutterScreen,
        },
        {
          path: '/portal/point_of_sale',
          exact: true,
          component: PortalPointOfSaleScreen,
        },
      ]
    }
  }, [dioDiscoveryOnboarding, user?.product])

  const routeConfigs = React.useMemo(() => {
    const defaultConfigs = [
      {
        path: '/login',
        exact: true,
        render: () => <Redirect to="/portal" />,
      },
      {
        path: '*',
        component: NotFoundScreen,
      },
    ]

    return [...userRouteConfigs, ...defaultConfigs]
  }, [userRouteConfigs])

  if (!user) {
    return <></>
  }

  return (
    <Switch>
      {routeConfigs.map((config, i) => (
        <Route
          key={i}
          path={config.path}
          exact={config.exact}
          component={config.component}
        />
      ))}
    </Switch>
  )
}
