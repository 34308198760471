import {createSlice} from '@reduxjs/toolkit'

import {Submarket} from 'src/types'

interface SubmarketsState {
  items: Submarket[]
}

const initialState: SubmarketsState = {
  items: [],
}

export const submarketsSlice = createSlice({
  name: 'submarkets',
  initialState,
  reducers: {
    updateSubmarkets: (state, action) => {
      const payload = action.payload
      state.items = payload
    },
  },
})

export const {updateSubmarkets} = submarketsSlice.actions
export default submarketsSlice.reducer
