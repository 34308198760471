import * as React from 'react'

import {Paper, Typography} from '@mui/material'
import {Stack, Box} from '@mui/system'

import {SANS_SERIF_FONT_FAMILY} from 'src/framework/theming/AppThemeProvider'
import {MaterialSymbolIcon} from 'src/framework/ui/MaterialSymbolIcon'

export type TileStatus =
  | 'notStarted'
  | 'inProgress'
  | 'eligible'
  | 'ineligible'
  | undefined

export type TileType =
  | 'purchasingPower'
  | 'creditWorthiness'
  | 'propertyValuation'
  | 'propertyType'

export type TileConfig = {
  tileType: TileType
  tileStatus: TileStatus
  content: JSX.Element
  label: string
  blur?: boolean
}

interface TileProps {
  config: TileConfig
  onClick: (tileType: TileType) => void
}

export const Tile = ({config, onClick}: TileProps): JSX.Element => {
  const {content, label, tileStatus, blur = false} = config
  const handleClick = React.useCallback(
    () => onClick(config.tileType),
    [config, onClick],
  )

  return (
    <Paper
      elevation={0}
      variant="elevation"
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
        p: 1.5,
        flex: 1,
        minHeight: '8.5rem',
        height: '100%',
        cursor: 'pointer',
        borderRadius: '8px',
        boxShadow: '0px 2px 5px 0px rgba(79, 86, 97, 0.25)',
        '&:hover': {
          boxShadow: '0px 2px 5px 0px rgba(79, 86, 97, 0.25)',
          backgroundColor: '#EBEDEF',
        },
      }}
      onClick={handleClick}
    >
      <Stack direction="column" justifyContent="space-between" height="100%">
        <Box sx={{...(blur && {filter: 'blur(10px)'})}}>{content}</Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >
          <Typography
            fontFamily={SANS_SERIF_FONT_FAMILY}
            fontWeight={700}
            fontSize="0.75rem"
          >
            {label}
          </Typography>
          <TileStatusIcon tileStatus={tileStatus} />
        </Stack>
      </Stack>
    </Paper>
  )
}

export const TileStatusIcon = ({
  tileStatus,
}: {
  tileStatus: TileStatus
}): JSX.Element | null => {
  switch (tileStatus) {
    case 'notStarted':
    case undefined:
      return (
        <MaterialSymbolIcon weight={300} fill={1} color="secondary">
          info
        </MaterialSymbolIcon>
      )
    case 'inProgress':
      return (
        <MaterialSymbolIcon weight={300} fill={1} color="secondary">
          schedule
        </MaterialSymbolIcon>
      )
    case 'eligible':
      return (
        <MaterialSymbolIcon weight={300} fill={1} color="success">
          check_circle
        </MaterialSymbolIcon>
      )
    case 'ineligible':
      return (
        <MaterialSymbolIcon weight={300} fill={1} color="warning">
          error
        </MaterialSymbolIcon>
      )
    default:
      return null
  }
}
