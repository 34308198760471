import * as React from 'react'

import {Box, Button, Stack, Typography} from '@mui/material'
import {useFlags} from 'launchdarkly-react-client-sdk'

import {Popup} from 'src/components/portal/RecommendationFlow/Popup'
import {MaterialSymbolIcon} from 'src/framework/ui/MaterialSymbolIcon'
import {apiPartnersCompanyPath} from 'src/generated/routes'
import {useCurrentUser} from 'src/hooks/request/useCurrentUser'
import {useRequest} from 'src/hooks/request/useRequest'
import {useToggle} from 'src/hooks/util/useToggle'
import {PartnerCompany} from 'src/types'

const ESG_OPTIONS = [
  {
    label: 'Women-owned business',
    value: 'woman_owned_business',
  },
  {
    label: 'BIPOC-owned business',
    value: 'bipoc_owned_business',
  },
  {
    label: 'Veteran-owned business',
    value: 'veteran_owned_business',
  },
  {
    label: 'Interested in clean energy improvements',
    value: 'interested_in_clean_energy_improvements',
  },
  {
    label: 'Operate in the agriculture industry',
    value: 'operate_in_the_agriculture_industry',
  },
  {
    label: 'None of the above',
    value: 'none',
  },
]

type CompanyUpdatePayload = {
  esgQualifiers?: string[]
}

interface Props {
  company: PartnerCompany | null
  refetchCompany: () => void
}

export const ESGPrompt = ({company, refetchCompany}: Props) => {
  const {esgPromptEnabled} = useFlags()
  const [isPopupOpen, openPopup, closePopup] = useToggle(false)
  const [selections, setSelections] = React.useState<string[]>([])
  const {user: currentUser} = useCurrentUser()

  React.useEffect(() => {
    if (currentUser && !currentUser.internal) {
      setTimeout(() => openPopup(), 500)
    }
  }, [currentUser, openPopup])

  const {request: updateCompany} = useRequest<unknown, CompanyUpdatePayload>(
    'PUT',
    apiPartnersCompanyPath(),
  )

  if (!(esgPromptEnabled && company?.showEsgPrompt)) {
    return null
  }

  const handleClose = async () => {
    closePopup()
    await updateCompany({data: {esgQualifiers: selections}})
    refetchCompany()
  }

  const handleSelect = (value: string) => {
    if (value === 'none') {
      setSelections(['none'])
    } else if (selections.includes(value)) {
      setSelections(selections.filter((s) => s !== value))
    } else {
      const selectionsWithoutNone = selections.filter((s) => s !== 'none')
      setSelections([...selectionsWithoutNone, value])
    }
  }

  return (
    <Popup open={isPopupOpen} onClose={handleClose}>
      <Typography variant="h2" pb={1}>
        Welcome back! We&apos;ve made a couple of changes since you were last
        here.
      </Typography>
      <Typography variant="body1" pb={{xs: 2, sm: 3}}>
        To get started, you may qualify for advantageous and/or additional
        government-subsidized financing products. Please select all that apply:
      </Typography>
      <Stack spacing={{xs: 1.5, sm: 2}}>
        {ESG_OPTIONS.map(({label, value}) => (
          <Box
            key={value}
            p={{xs: 1.5, sm: 2}}
            border={(theme) =>
              selections.includes(value)
                ? `1px solid ${theme.palette.primary.main}`
                : `1px solid ${theme.palette.grey[400]}`
            }
            boxShadow={(theme) =>
              selections.includes(value)
                ? `0 0 0 1px ${theme.palette.primary.main}`
                : 'none'
            }
            borderRadius={2}
            onClick={() => handleSelect(value)}
            sx={{
              cursor: 'pointer',
              '&:hover': selections.includes(value)
                ? {}
                : {
                    borderColor: 'grey.800',
                  },
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              minHeight="24px"
            >
              <Typography variant="body1">{label}</Typography>
              {selections.includes(value) && (
                <MaterialSymbolIcon weight={300} fill={1} color="primary">
                  check_circle
                </MaterialSymbolIcon>
              )}
            </Stack>
          </Box>
        ))}
        <Button
          variant="contained"
          size="large"
          sx={{width: '100%'}}
          onClick={handleClose}
        >
          I&apos;m done!
        </Button>
      </Stack>
    </Popup>
  )
}
