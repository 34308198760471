import {createSlice} from '@reduxjs/toolkit'

import {
  Deal,
  LeasingComp,
  LeasingCompMeta,
  LoiDocument,
  ScenarioResult,
  SalesComp,
  SalesCompMeta,
  TermSheetDocument,
  Company,
  RealEstateActivity,
} from 'src/types'

interface DealsState {
  items: {
    [id: string]: {
      deal: Deal
      company?: Company
      latestLoi?: LoiDocument
      latestTermSheet?: TermSheetDocument
      scenarioResult?: ScenarioResult
      salesComps?: Array<SalesComp>
      salesCompMeta?: SalesCompMeta
      leasingComps?: Array<LeasingComp>
      leasingCompMeta?: LeasingCompMeta
      realEstateActivity?: RealEstateActivity
    }
  }
}

const initialState: DealsState = {
  items: {},
}

export const dealsSlice = createSlice({
  name: 'deals',
  initialState,
  reducers: {
    updateDeal: (state, action) => {
      const {
        deal,
        company,
        scenarioResult,
        latestLoi,
        latestTermSheet,
        realEstateActivity,
      } = action.payload
      const {id} = deal
      state.items[id] = {
        ...state.items[id],
        deal,
        company,
        scenarioResult: scenarioResult ?? state.items[id]?.scenarioResult,
        latestLoi: latestLoi,
        latestTermSheet: latestTermSheet,
        realEstateActivity: realEstateActivity,
      }
    },
    updateSalesComps: (state, action) => {
      const {dealId, salesComps, salesCompMeta} = action.payload
      state.items[dealId] = {...state.items[dealId], salesComps, salesCompMeta}
    },
    updateLeasingComps: (state, action) => {
      const {dealId, leasingComps, leasingCompMeta} = action.payload
      state.items[dealId] = {
        ...state.items[dealId],
        leasingComps,
        leasingCompMeta,
      }
    },
  },
})

export const {updateDeal, updateSalesComps, updateLeasingComps} =
  dealsSlice.actions
export default dealsSlice.reducer
