import * as React from 'react'

import {Close} from '@mui/icons-material'
import {
  Box,
  IconButton,
  Modal,
  ModalProps,
  Paper,
  Typography,
} from '@mui/material'

type Size = 'sm' | 'md' | 'lg' | 'fullScreen'

const WIDTH: {[s in Size]: number} = {
  sm: 540,
  md: 720,
  lg: 900,
  fullScreen: 90,
}

type Props = Omit<ModalProps, 'children'> & {
  title?: string
  children: React.ReactNode
  size?: Size
  topOffset?: string
  maxHeight?: string
  withPadding?: boolean
  disableCloseButton?: boolean
}

export const CenterModal = ({
  title,
  children,
  size = 'sm',
  topOffset = '5vh',
  maxHeight = '90vh',
  withPadding = true,
  disableCloseButton = false,

  ...modalProps
}: Props): JSX.Element | null => {
  const [width, margin] = React.useMemo(() => {
    const widthValue = WIDTH[size]
    const leftMarginValue = -widthValue / 2

    const units = size === 'fullScreen' ? 'vw' : 'px'

    return [`${widthValue}${units}`, `${leftMarginValue}${units}`]
  }, [size])

  const onClose = React.useCallback(() => {
    modalProps.onClose && modalProps.onClose({}, 'backdropClick')
  }, [modalProps])

  return (
    <Modal {...modalProps}>
      <Paper
        sx={{
          position: 'absolute',
          left: {xs: 0, sm: '50%'},
          top: {xs: '5vh', sm: topOffset},
          marginLeft: {xs: 0, sm: margin},
        }}
        elevation={10}
      >
        {!disableCloseButton && (
          <IconButton
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              display: {xs: 'block', sm: 'none'},
              zIndex: 2,
            }}
            onClick={onClose}
          >
            <Close color="secondary" />
          </IconButton>
        )}
        <Box
          sx={{
            position: 'relative',
            maxHeight: {xs: '95vh', sm: maxHeight},
            maxWidth: {xs: '100vw'},
            width: {xs: '100vw', sm: width},
            px: withPadding ? {xs: 4, sm: 8} : 0,
            py: withPadding ? {xs: 3, sm: 6} : 0,
            height: {xs: '95vh', sm: '100%'},
            overflow: 'scroll',
          }}
        >
          <Box height="100%" maxHeight="inherit">
            {title && (
              <Typography variant="h4" sx={{mb: 4, textAlign: 'center'}}>
                {title}
              </Typography>
            )}
            {children}
          </Box>
        </Box>
      </Paper>
    </Modal>
  )
}
