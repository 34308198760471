import React from 'react'

import {
  DescriptionOutlined,
  FileDownloadOutlined,
  PaymentsOutlined,
  SentimentSatisfiedAltRounded,
} from '@mui/icons-material'
import {
  Typography,
  Stepper,
  StepConnector,
  Step,
  StepLabel,
  SvgIcon,
} from '@mui/material'
import {Box} from '@mui/system'

import {WizardStepProps} from 'src/components/portal/SBAMicroflow/SBAApplicationWizard'

type Props = WizardStepProps

export const Start = ({onReadyToAdvance}: Props) => {
  const stepConfigs = [
    {
      icon: DescriptionOutlined,
      stepName: 'Application',
    },
    {
      icon: SentimentSatisfiedAltRounded,
      stepName: 'Introduction',
    },
    {
      icon: FileDownloadOutlined,
      stepName: 'Submit Documents',
    },
    {
      icon: PaymentsOutlined,
      stepName: 'Loan Closing',
    },
  ]

  React.useEffect(() => {
    onReadyToAdvance()
  }, [onReadyToAdvance])

  return (
    <Box textAlign="center">
      <Box
        display={{xs: 'none', sm: 'block'}}
        py={8}
        px={4}
        sx={{background: (theme) => theme.palette.background.blue}}
      >
        <Stepper
          activeStep={-1}
          alternativeLabel
          connector={
            <StepConnector
              sx={{
                '.MuiStepConnector-line': {
                  borderTopStyle: 'dashed',
                },
              }}
            />
          }
        >
          {stepConfigs.map((stepConfig, index) => (
            <Step key={`SBAApplicationStep${index}`}>
              <StepLabel
                icon={
                  <SvgIcon
                    component={stepConfig.icon}
                    color="primary"
                    sx={{fontSize: '1rem'}}
                  />
                }
                sx={{
                  '.MuiStepLabel-labelContainer': {color: 'inherit'},
                  '.MuiStepLabel-iconContainer': {
                    height: 30,
                    width: 30,
                    borderRadius: '50%',
                    backgroundColor: '#F2F4F8',
                    border: '1.25px solid #CECECE',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                }}
              >
                <Typography variant="caption" color="secondary">
                  Step {index + 1}
                </Typography>
                <Typography variant="h4">{stepConfig.stepName}</Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box
        display={{xs: 'block', sm: 'none'}}
        py={4}
        px={4}
        sx={{background: (theme) => theme.palette.background.blue}}
      >
        <Stepper
          orientation={'vertical'}
          activeStep={-1}
          connector={
            <StepConnector
              sx={{
                '.MuiStepConnector-line': {
                  borderLeftStyle: 'dashed',
                },
              }}
            />
          }
        >
          {stepConfigs.map((stepConfig, index) => (
            <Step key={`SBAApplicationStep${index}`}>
              <StepLabel
                icon={
                  <SvgIcon
                    component={stepConfig.icon}
                    color="primary"
                    sx={{fontSize: '1rem'}}
                  />
                }
                sx={{
                  '.MuiStepLabel-labelContainer': {color: 'inherit', pl: 2},
                  '.MuiStepLabel-iconContainer': {
                    height: 30,
                    width: 30,
                    borderRadius: '50%',
                    backgroundColor: '#F2F4F8',
                    border: '1.25px solid #CECECE',
                    justifyContent: 'center',
                    alignItems: 'center',
                    pr: 0,
                  },
                }}
              >
                <Typography variant="caption" color="secondary">
                  Step {index + 1}
                </Typography>
                <Typography variant="h4">{stepConfig.stepName}</Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Typography sx={{opacity: 0.8}} mt={2.5}>
        We&apos;ve established a partnership with some of the top SBA lenders in
        the US. We can introduce you to a representative at one of these
        lenders, so you can secure financing to purchase a property on your own
        timeline. Here&apos;s how the process works.
      </Typography>
    </Box>
  )
}
