import * as Hotjar from 'src/util/hotjar'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    analytics: any
  }
}

// Docs: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
export const identify = (id: string, email: string, name: string): void => {
  if (!window.analytics) return

  const userAgent = navigator.userAgent
  const hotjarUserId = Hotjar.getUserId()
  window.analytics.identify(id, {email, name, userAgent, hotjarUserId})
}

export const identifyAnonymousUser = (anonymousId: string): void => {
  if (!window.analytics) return

  window.analytics.setAnonymousId(anonymousId)
}

export const page = (
  name: string,
  properties: {[key: string]: unknown} = {},
): void => {
  if (!window.analytics) return

  window.analytics.page(name, properties)
}

export const track = (eventName: string, properties: object = {}): void => {
  if (!window.analytics) return

  window.analytics.track(eventName, properties)
}

export default {
  identify,
  identifyAnonymousUser,
  track,
  page,
}
