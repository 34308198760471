import * as React from 'react'

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material'
import {Box, Stack, useTheme} from '@mui/system'

import {RutterButton} from 'src/components/partners/RutterButton'
import {ScreenLayout} from 'src/framework/ScreenLayout'
import {PortalAppThemeProvider} from 'src/framework/theming/PortalAppThemeProvider'
import {
  apiPartnersRutterConnectionsPath,
  apiPartnersUserCompanyPath,
} from 'src/generated/routes'
import {useTrackPage} from 'src/hooks/analytics/useTrackPage'
import {useCurrentUser} from 'src/hooks/request/useCurrentUser'
import {useIndex} from 'src/hooks/request/useIndex'
import {useShow} from 'src/hooks/request/useShow'
import {PartnerCompany, RutterConnection} from 'src/types'
import {formatDatetime} from 'src/util/format/formatDatetime'
import {ReactComponent as RutterLogo} from 'svg/rutter_logo.svg'

export const PartnerRutterScreen = (): JSX.Element | null => {
  useTrackPage({pageName: 'connectRutter'})
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const {entity: company, fetch: fetchCompany} = useShow<PartnerCompany>()

  const {user} = useCurrentUser()

  const refetchCompany = React.useCallback(async () => {
    if (user) {
      return fetchCompany({url: apiPartnersUserCompanyPath(user.id)})
    }
  }, [fetchCompany, user])

  const {
    entities: rutterConnections,
    fetch: refetchRutterConnections,
    loading: loadingRutterConnections,
  } = useIndex<RutterConnection>(apiPartnersRutterConnectionsPath(), {
    autoRequest: true,
  })

  React.useEffect(() => {
    refetchCompany()
  }, [refetchCompany])

  const onRutterSuccess = React.useCallback(() => {
    refetchCompany()
    refetchRutterConnections()
  }, [refetchCompany, refetchRutterConnections])

  return (
    <PortalAppThemeProvider>
      <ScreenLayout fullHeight justifyContent="center">
        <Stack spacing={4} width="100%" pb={6}>
          {!!company && company.rutterConnected ? (
            <PostConnectionHeader />
          ) : (
            <PreConnectionHeader />
          )}
          <Paper sx={{py: {xs: 3, sm: 4}, px: {xs: 2, sm: 4}}}>
            <Stack spacing={4} alignItems="center">
              <RutterLogo />
              <RutterButton
                onSuccess={onRutterSuccess}
                defaultButtonText={
                  isMobile
                    ? 'Connect accounting software'
                    : 'Connect business accounting software'
                }
                displayIcon={false}
                buttonVariant="contained"
                buttonProps={{
                  sx: {
                    width: {xs: '100%', sm: 'fit-content'},
                    minWidth: '240px',
                  },
                }}
                sx={{width: {xs: '100%', sm: 'fit-content'}}}
                pageLocation="portal"
              />
              {!loadingRutterConnections && rutterConnections.length > 0 ? (
                <RutterConnectionsTable rutterConnections={rutterConnections} />
              ) : null}
            </Stack>
          </Paper>
        </Stack>
      </ScreenLayout>
    </PortalAppThemeProvider>
  )
}

const PreConnectionHeader = () => (
  <Box>
    <Typography variant="h2" mb={2}>
      {
        'We use Rutter to get instant, read-only access to business accounting financials.'
      }
    </Typography>
    <Typography variant="body1">
      {'Requires accounting software credentials.'}
    </Typography>
  </Box>
)

const PostConnectionHeader = () => (
  <Box>
    <Typography variant="h2" mb={2}>
      {"You're connected!"}
    </Typography>
    <Stack spacing={2} alignItems="center">
      <Typography variant="body1" component="span">
        {
          'If you have other accounting ledgers not listed below, please connect them too.'
        }
      </Typography>
    </Stack>
  </Box>
)

type PartnerRutterConnectionsProps = {
  rutterConnections: RutterConnection[]
}
const RutterConnectionsTable = ({
  rutterConnections,
}: PartnerRutterConnectionsProps) => {
  const platformDisplayName = (platform: string) => {
    switch (platform) {
      case 'QUICKBOOKS':
        return 'QuickBooks'
      case 'QUICKBOOKS_DESKTOP':
        return 'QuickBooks Desktop'
      case 'WAVE':
        return 'Wave'
      case 'Xero':
        return 'Xero'
      default:
        return platform
    }
  }
  return (
    <Table data-testid="accounts-table" size="small" sx={{mt: 5}}>
      <colgroup>
        <col width="20%" />
        <col width="30%" />
        <col width="50%" />
      </colgroup>
      <TableHead>
        <TableRow>
          <TableCell style={{fontWeight: 'bold'}}>Platform</TableCell>
          <TableCell style={{fontWeight: 'bold'}}>Name</TableCell>
          <TableCell style={{fontWeight: 'bold'}}>Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rutterConnections.map((rutterConnection) => (
          <TableRow key={rutterConnection.id}>
            <TableCell>
              {platformDisplayName(rutterConnection.platform)}
            </TableCell>
            <TableCell>
              {rutterConnection.entityName || '(Loading...)'}
            </TableCell>
            <TableCell>{formatDatetime(rutterConnection.createdAt)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
